/* Dans questions :
categorys = au menu sélectionné, affiche les questions en fonction de la catégorie choisie;
pages     = différencie les différentes pages de questions;
groups    = les différents groupes de questions (un group = une card)
lots      = toutes les questions dans chaque groupe
*/

const initialState = {
  path: '',
  languages: [],
  allQuestions: [],
  languageSelected: 'FR',
  languageDefault: 'EN',
  menu: {},
  menuSelect: 0,
  step: 0,
  questions : [],
  idUser: null,
  idQuestionnaire: null,
  idMenu: null,
  idPage: null,
  idChapitre: null,
  indexLot: null, 
  idQuestion: null,
  needRapport: true,
  firstname: '',
  lastname: '',
  dirigeantlastname: '',
  dirigeantfirstname: '',
  dirigeantmail: '',
  fonction: false,
  modif: false,
  token: false,
  homepage: {},
  themes: {},
  selectedThemes: [],
  idCorrespond: [],
  displayUnderMenu: true,
  needUnderMenu: false,
  answers : {},
  rapport: {},
  displayReportMenu: false,
  themeSelectionActive: false,
  summaryScore: {},
  tableDisplay: []
}

function reducer(state = initialState, action){
  switch (action.type){
    case 'SET_MENU': return{...state, menu : action.payload};
    case 'SET_MENU_SELECT': return{...state, menuSelect : action.payload};
    case 'SET_PATH': return{...state, path: action.payload};
    case 'SET_STEP': return{...state, step: action.payload};
    case 'SET_LANGUAGES': return{...state, languages: action.payload};
    case 'SET_LANGUAGE_SELECTED': return{...state, languageSelected: action.payload};
    case 'SET_ALL_QUESTIONS': return{...state, allQuestions: action.payload};
    case 'SET_ANSWERS': return{...state, answers: action.payload};
    case 'SET_ID_USER': return{...state, idUser: action.payload};
    case 'SET_ID_MENU': return{...state, idMenu: action.payload};
    case 'SET_ID_PAGE': return{...state, idPage: action.payload};
    case 'SET_INDEX_LOT': return{...state, indexLot: action.payload};
    case 'SET_ID_QUESTION': return{...state, idQuestion: action.payload};
    case 'SET_NEED_RAPPORT': return{...state, needRapport: action.payload}
    case 'SET_RAPPORT': return{...state, rapport: action.payload}
    case 'SET_ID_QUESTIONNAIRE': return{...state, idQuestionnaire: action.payload}
    case 'SET_FIRSTNAME': return{...state, firstname: action.payload}
    case 'SET_DIRIGEANT_FIRSTNAME': return{...state, dirigeantfirstname: action.payload}
    case 'SET_LASTNAME': return{...state, lastname: action.payload}
    case 'SET_DIRIGEANT_LASTNAME': return{...state, dirigeantlastname: action.payload}
    case 'SET_DIRIGEANT_MAIL': return{...state, dirigeantmail: action.payload}
    case 'SET_FONCTION': return{...state, fonction: action.payload}
    case 'SET_MODIF': return{...state, modif: action.payload}
    case 'SET_TOKEN': return{...state, token: action.payload}
    case 'SET_HOMEPAGE': return{...state,homepage: action.payload}
    case 'SET_THEMES': return{...state, themes: action.payload}
    case 'SET_SELECTED_THEMES': return{...state, selectedThemes: action.payload}
    case 'SET_DISPLAY_UNDERMENU' : return{...state, displayUnderMenu: action.payload}
    case 'SET_ID_CHAPITRE': return{...state, idChapitre: action.payload}
    case 'SET_ID_CORRESPOND': return{...state, idCorrespond: action.payload}
    case 'SET_NEED_UNDERMENU': return{...state, needUnderMenu: action.payload}
    case 'SET_DISPLAY_REPORT_MENU': return{...state, displayReportMenu: action.payload}
    case 'SET_THEME_SELECTION_ACTIVE': return{...state, themeSelectionActive: action.payload}
    case 'SET_SUMMARY_SCORE': return{...state, summaryScore: action.payload}
    case 'SET_TABLE_DISPLAY': return{...state, tableDisplay: action.payload}

    default: return state;
}
}

export default reducer;