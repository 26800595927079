import { useState, useEffect } from "react"
import { Link, useLocation,useParams } from "react-router-dom"
import './menu.scss'
import { connect, useDispatch } from "react-redux"
import { setDisplayUnderMenu, setStep } from "../../actions/action"
const MobileMenu = ( {menu, allQuestions, menuBurger, setMenuBurger, displayUnderMenu, idCorrespond, themeSelectionActive, needUnderMenu, displayReportMenu, themes}) => {
    const { idQuestionnaire } = useParams();
    const { idUser } = useParams()
    const [underMenu, setUnderMenu] = useState(allQuestions.questions[0])
    const dispatch = useDispatch()
    const {menuId, pageId} = useParams()
    const location = useLocation()
    const isStringParam = isNaN(idQuestionnaire);
    let menuIndex = null
    let menuObj = null
    let findIdText = null;
    if (idCorrespond){
      if(isStringParam){
        findIdText = idCorrespond.find(obj => obj.text == idQuestionnaire)
      } else {
        findIdText = idCorrespond.find(obj => obj.id == idQuestionnaire) 
      }
    }

    const findUnderMenu = (id) => {
        if(needUnderMenu){
          setUnderMenu(allQuestions.questions.find(obj => obj.category.id == id))
          if (menu && menu[0]){
            menuIndex = allQuestions.questions.findIndex(obj => obj.category.id == id)
          menuObj = allQuestions.questions.find(obj => obj.category.id == menu[menuIndex].id)
        } 
        }
      }
    
      useEffect(() => {
        findUnderMenu(menuId)
      }, [menuId]) 

    return (
   
        <div className={menuBurger ? 'mobile__menu' : 'mobile__menu mobile__menu__close'}>
            <div className='mobile__menu__buttonSelect' onClick={() => {dispatch(setDisplayUnderMenu(false))}}></div>
            {!displayUnderMenu ? 
            menu.map((m, mIndex) => {
                return(
                    <Link to={`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${m.id}/${allQuestions.questions[mIndex].pages[0].text.id}/0/${allQuestions.questions[mIndex].pages[0].lots[0].sousquestions[0].id}`} 
                    className='mobile__menu__title' onClick={needUnderMenu ? () => {dispatch(setStep(0)); dispatch(setDisplayUnderMenu(true))} : () => {dispatch(setStep(0)); setMenuBurger(false)}}>
                        {m.title}
                    </Link>
                    
                )
            })
            : null }
            {needUnderMenu && displayUnderMenu ? 
            underMenu.pages.map((page, pageIndex) => {
                return (
                    <Link to={`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${menuId}/${page.text.id}/0/${page.lots[0].sousquestions[0].id}`} className='mobile__menu__undertitle' onClick={() => {setMenuBurger(!menuBurger); dispatch(setStep(0))}}>
                        {pageIndex+1} - {page.text.title}
                    </Link>
                )
            })
            
            : null}
            {displayReportMenu ? <Link to={findIdText ? process.env.REACT_APP_FRONT_LINK+'/rapport/'+findIdText.text+'/'+idUser : process.env.REACT_APP_FRONT_LINK+'/rapport/'+idQuestionnaire+'/'+idUser} className="mobile__menu__undertitle">Rapport</Link> : null}
            {themeSelectionActive ? <Link to={findIdText ? process.env.REACT_APP_FRONT_LINK+'/menu/'+findIdText.text +'/'+idUser : process.env.REACT_APP_FRONT_LINK+'/menu/'+idQuestionnaire+'/'+idUser} className="mobile__menu__undertitle"> Modifier vos choix de thèmes </Link> : null}
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    allQuestions: state.allQuestions,
    menu: state.menu,
    displayUnderMenu: state.displayUnderMenu,
    needUnderMenu: state.needUnderMenu,
    displayReportMenu: state.displayReportMenu,
    themeSelectionActive: state.themeSelectionActive,
    idCorrespond: state.idCorrespond,
    themes: state.themes,
  });
  const mapDispatchToProps = {
    };
    
  export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);