import { connect, useDispatch } from "react-redux";
import { setFonction, setModif } from '../../../actions/action'
import { useEffect } from "react";
import { useParams } from "react-router-dom"
import Select from "react-select";


const ReponseSelect = ( { options, optionsKeys, setSaveNewAnswers, sousquestion, answers,question, updateAnswers, setAnswerRequired, answersForBack,recursiveUpdate, saveNewAnswers }) => {
    /*const dispatch = useDispatch()
   
   
    const selectValue = (event) => {
        recursiveUpdate(sousquestion)
        dispatch(setModif(true))
        setAnswerRequired(true)
        // const selectedIndex = optionsValues.findIndex((option) => option.text === event.target.value); // recherche l'index de l'option sélectionnée
            setSaveNewAnswers(prevState => {
                const updatedQuestion = {...prevState[question.category.id][sousquestion.id],
                selectedAnswer: optionsKeys[event.index],
                answerValue: event.value,
                textAnswer: event.label,
                textRapport: sousquestion.options[optionsKeys[event.index]].rapportResponse
            };
            const updatedCategory = {...prevState[question.category.id], [sousquestion.id]: updatedQuestion}
            const updatedState = {...prevState, [question.category.id]: updatedCategory}
              return updatedState;
        })
        updateAnswers(optionsKeys[event.index], sousquestion.options[optionsKeys[event.index]].value, event.label)
    }

      FONCTION AVANT SELECT-REACT, A GARDER EN CAS DE NON REUSSITE OU DE BUG
const selectValue = (event) => {
        recursiveUpdate(sousquestion)
        dispatch(setModif(true))
        setAnswerRequired(true)
        const selectedIndex = optionsValues.findIndex((option) => option.text === event.target.value); // recherche l'index de l'option sélectionnée
        if(event.target.selectedIndex != 0){
            setSaveNewAnswers(prevState => {
                const updatedQuestion = {...prevState[question.category.id][sousquestion.id],
                selectedAnswer: optionsKeys[selectedIndex],
                answerValue: sousquestion.options[optionsKeys[selectedIndex]].value,
                textAnswer: event.target.value,
                textRapport: sousquestion.options[optionsKeys[selectedIndex]].rapportResponse
            };
            const updatedCategory = {...prevState[question.category.id], [sousquestion.id]: updatedQuestion}
            const updatedState = {...prevState, [question.category.id]: updatedCategory}
              return updatedState;
        })} else {
            setSaveNewAnswers(prevState => {
                const updatedQuestion = {...prevState[question.category.id][sousquestion.id],
                    textAnswer: event.target.value,
                };
                const updatedCategory = {...prevState[question.category.id], [sousquestion.id]: updatedQuestion}
                const updatedState = {...prevState, [question.category.id]: updatedCategory}
                  return updatedState;
                })
        }
        updateAnswers(optionsKeys[selectedIndex], sousquestion.options[optionsKeys[selectedIndex]].value, event.target.value)
    }
    */

    const dispatch = useDispatch()
    const {menuId, idQuestion} = useParams()
    const optionsTable = Object.entries(sousquestion.options)

    const selectValue = (event) => {
        recursiveUpdate(sousquestion)
        dispatch(setModif(true))
        setAnswerRequired(true)
        // const selectedIndex = optionsValues.findIndex((option) => option.text === event.target.value); // recherche l'index de l'option sélectionnée
            setSaveNewAnswers(prevState => {
                const updatedQuestion = {...prevState[question.category.id][sousquestion.id],
                selectedAnswer: optionsKeys[event.index],
                answerValue: event.value,
                textAnswer: event.label,
                textRapport: sousquestion.options[optionsKeys[event.index]].rapportResponse
            };
            const updatedCategory = {...prevState[question.category.id], [sousquestion.id]: updatedQuestion}
            const updatedState = {...prevState, [question.category.id]: updatedCategory}
              return updatedState;
        })
        updateAnswers(optionsKeys[event.index], sousquestion.options[optionsKeys[event.index]].value, event.label)
    }


    useEffect(() => {
        if(answers && answers[menuId] && answersForBack && answersForBack[menuId] && !answersForBack[menuId][idQuestion]){
            updateAnswers(optionsTable[0][0], optionsTable[0][1].value, optionsTable[0][1].text, sousquestion.id)
        }
    }, [])

    const saveInfo = (event) => {
        if(sousquestion.identityType === 'role' && event.label == 'Dirigeant'){
            dispatch(setFonction(true))
        } else {
            dispatch(setFonction(false))
        }
    }

    

      
    return (
      <Select options={options} className='questions__card__select' classNamePrefix='questions__card__select' unstyled onChange={(event) => {saveInfo(event); selectValue(event)}}
        defaultValue={
            saveNewAnswers 
            && saveNewAnswers[question.category.id] 
            && saveNewAnswers[question.category.id][sousquestion.id] 
            ? {
                label: saveNewAnswers[question.category.id][sousquestion.id].textAnswer, 
                value:saveNewAnswers[question.category.id][sousquestion.id].answerValue
              }
            : 'Choisissez une option'}
        />
         /* 
        <select className='questions__card__select' onChange={(event) => {selectValue(event); saveInfo(event)}} >
            <option className='questions__card__option'> Choisissez une option</option>
            {Object.entries(sousquestion.options).map(([idOption, option], optionIndex) => {
                if(answersForBack && answersForBack[menuId] && answersForBack[menuId][sousquestion.id] && answersForBack[menuId][sousquestion.id].response == idOption){
                   return(
                        <option key={optionIndex} value={idOption} selected>{option.text}</option>
                        ) 
                } else {
                    return(
                        <option key={optionIndex} value={idOption}>{option.text}</option>
                        ) 
                }
                
            }
                    
            )}
        </select>*/
        )
    }
    
    
    const mapStateToProps = (state) => ({
        answers: state.answers
         });
      const mapDispatchToProps = dispatch => ({
      });
      
    
    
    export default connect(mapStateToProps, mapDispatchToProps)(ReponseSelect);