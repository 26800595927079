export const setMenu = (payload) =>                     ({type: 'SET_MENU', payload});
export const setMenuSelect = (payload) =>               ({type: 'SET_MENU_SELECT', payload});
export const setPath = (payload) =>                     ({type: 'SET_PATH', payload});
export const setStep = (payload) =>                     ({type: 'SET_STEP', payload});
export const setLanguages = (payload) =>                ({type: 'SET_LANGUAGES', payload});
export const setLanguageSelected = (payload) =>         ({type: 'SET_LANGUAGE_SELECTED', payload});
export const setAllQuestions = (payload) =>             ({type: 'SET_ALL_QUESTIONS', payload});
export const setAnswers = (payload) =>                  ({type: 'SET_ANSWERS', payload});
export const setIdUser = (payload) =>                   ({type: 'SET_ID_USER', payload});
export const setIdQuestionnaire = (payload) =>          ({type: 'SET_ID_QUESTIONNAIRE', payload});
export const setIdMenu = (payload) =>                   ({type: 'SET_ID_MENU', payload});
export const setIdPage = (payload) =>                   ({type: 'SET_ID_PAGE', payload});
export const setIndexLot = (payload) =>                 ({type: 'SET_INDEX_LOT', payload});
export const setIdQuestion = (payload) =>               ({type: 'SET_ID_QUESTION', payload});
export const setNeedRapport = (payload) =>              ({type: 'SET_NEED_RAPPORT', payload});
export const setRapport = (payload) =>                  ({type: 'SET_RAPPORT', payload});
export const setProfilForEnd = (payload) =>             ({type: 'SET_¨PROFIL_FOR_END', payload});
export const setFonction = (payload) =>                 ({type: 'SET_FONCTION', payload});
export const setFirstname = (payload) =>                ({type: 'SET_FIRSTNAME', payload});
export const setLastname = (payload) =>                 ({type: 'SET_LASTNAME', payload});
export const setDirigeantLastname = (payload) =>        ({type: 'SET_DIRIGEANT_LASTNAME', payload});
export const setDirigeantFirstname = (payload) =>       ({type: 'SET_DIRIGEANT_FIRSTNAME', payload});
export const setDirigeantMail = (payload) =>            ({type: 'SET_DIRIGEANT_MAIL', payload});
export const setModif = (payload) =>                    ({type: 'SET_MODIF', payload});
export const setToken = (payload) =>                    ({type: 'SET_TOKEN', payload});
export const setHomepage = (payload) =>                 ({type: 'SET_HOMEPAGE', payload});
export const setThemes = (payload) =>                   ({type: 'SET_THEMES', payload});
export const setSelectedThemes = (payload) =>           ({type: 'SET_SELECTED_THEMES', payload})
export const setDisplayUnderMenu = (payload) =>         ({type: 'SET_DISPLAY_UNDERMENU', payload})
export const setIdChapitre = (payload) =>               ({type: 'SET_ID_CHAPITRE', payload})
export const setIdCorrespond = (payload) =>             ({type: 'SET_ID_CORRESPOND', payload})
export const setNeedUnderMenu = (payload) =>            ({type: 'SET_NEED_UNDERMENU', payload})
export const setDisplayReportMenu = (payload) =>        ({type: 'SET_DISPLAY_REPORT_MENU', payload})
export const setThemeSelectionActive = (payload) =>     ({type: 'SET_THEME_SELECTION_ACTIVE', payload})
export const setSummaryScore = (payload) =>             ({type: 'SET_SUMMARY_SCORE', payload})
export const setTableDisplay = (payload) =>             ({type: 'SET_TABLE_DISPLAY', payload})