import { useNavigate } from "react-router-dom"
import { connect } from "react-redux";
import { useState } from "react";

const AlertModal = ( {alertContainer, setDisplayChangeAnswers, idQuestionnaire, idUser, action}) => {

    const navigate = useNavigate();
    const [displayModalConfirm, setDisplayModalConfirm] = useState();

    return (
    <div className='pin__modal'>
        <div className='pin__container'>
            {alertContainer && alertContainer.titleIntro ? alertContainer.titleIntro : 'Mise en garde'}
            <div className='pin__text'>
                {alertContainer && alertContainer.textIntro ? alertContainer.textIntro : 'Êtes vous sûr de vouloir modifier vos réponses ? Si vous modifier des réponses, vous écraserez les précédentes '}
            </div>
            <div className='home__button__container'>
                <div className='home__button__change' onClick={() => {setDisplayModalConfirm(true)}}>{alertContainer && alertContainer.buttonOk ? alertContainer.buttonOk : 'Oui'}</div>
                <div className='home__button__change' onClick={() => {setDisplayChangeAnswers(false)}}>{alertContainer && alertContainer.buttonKo ? alertContainer.buttonKo : 'Non'}</div>
                { alertContainer ? <div className='home__button__change' onClick={() => {navigate(`${process.env.REACT_APP_FRONT_LINK}/rapport/${idQuestionnaire}/${idUser}`); setDisplayChangeAnswers(false)}}>{alertContainer.buttonAccessReport}</div> : null}
            </div>
        </div>
        {displayModalConfirm ? 
        <div className="modal__background">
            <div className='modal__container'>
                <div className='modal__text'>
                    {alertContainer.modal.text}
                </div>

            <div className='home__button__container'>
                <div className='home__button__change' onClick={() => {action()}}>{alertContainer.modal.buttonOk}</div>
                <div className='home__button__change' onClick={() => {setDisplayModalConfirm(false)}}>{alertContainer.modal.buttonKo}</div>
            </div>
            </div>
        </div> 
        : null}
    </div>
    )}

const mapStateToProps = (state) => ({
    idQuestionnaire: state.idQuestionnaire,
    idUser: state.idUser,
});
  const mapDispatchToProps = {
    };
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(AlertModal);
