import './responses.scss'
import { setModif } from "../../actions/action";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from 'react';

const MobileResponseMultiple = ( { sousquestion, updateAnswers, setSaveNewAnswers, idMenu, saveNewAnswers, answers, question, answersForBack, setAnswersForBack}) => {
    const dispatch = useDispatch()
    const [idSelected, setIdSelected] = useState([])
    const updateSaveNewAnswers = (event) => {
        dispatch(setModif(true))
        setSaveNewAnswers(prevState => {
        const updatedQuestion = {...prevState[idMenu][sousquestion.id],
            selectedAnswer: event.target.value,
            answerValue: sousquestion.options[event.target.value].value,
            textAnswer: sousquestion.options[event.target.value].text,
            textRapport: sousquestion.options[event.target.value].rapportResponse
        };
        const updatedCategory = {...prevState[idMenu], [sousquestion.id]: updatedQuestion}
        const updatedState = {...prevState, [idMenu]: updatedCategory}
          return updatedState;
    })
    createSaveNewAnswersSousquestion()
    }

    useEffect(() => {
        if (answers && answers[question.category.id] && answers[question.category.id][sousquestion.id] && answers[question.category.id][sousquestion.id].answers){
            answers[question.category.id][sousquestion.id].answers.forEach(el => {
                setIdSelected(prevSelected => [...prevSelected, el.response]);              
            });
        }
    }, [])

    const handleAddResponse = (id, value, text) => {
        dispatch(setModif(true));
        const idInSelected = idSelected.findIndex(obj => obj === parseInt(id));
    
        if (idInSelected !== -1) {
            const updatedSelected = idSelected.slice();
            updatedSelected.splice(idInSelected, 1);
            setIdSelected(updatedSelected);
        } else {
            setIdSelected(prevSelected => [...prevSelected, parseInt(id)]);
        }
    const newAnswer = { response: parseInt(id), textResponse: text, value: value };
    let totalValue = 0;
    // Copie de l'état actuel
    const updatedAnswersForBack = { ...answersForBack };
    
    // Vérifier si la clé question.category.id existe et la créer si nécessaire
    if (!updatedAnswersForBack[question.category.id]) {
        updatedAnswersForBack[question.category.id] = {};
    }
    
    // Vérifier si la clé sousquestion.id existe et la créer si nécessaire
    if (!updatedAnswersForBack[question.category.id][sousquestion.id]) {
        updatedAnswersForBack[question.category.id][sousquestion.id] = {
            answers: [],
            totalValueAnswer: 0,
        };
    }
    let updatedAnswer = null;

if (answersForBack && answersForBack[question.category.id] && answersForBack[question.category.id][sousquestion.id] && answersForBack[question.category.id][sousquestion.id].answers && answersForBack[question.category.id][sousquestion.id].answers[0]) {
    setAnswersForBack(prevAnswers => {
        const updatedAnswers = { ...prevAnswers }; // Créez une copie de l'état précédent
        
        if (
            updatedAnswers &&
            updatedAnswers[question.category.id] &&
            updatedAnswers[question.category.id][sousquestion.id] &&
            updatedAnswers[question.category.id][sousquestion.id].answers &&
            updatedAnswers[question.category.id][sousquestion.id].answers[0]
        ) {
            const objIndex = updatedAnswers[question.category.id][sousquestion.id].answers.findIndex(obj => obj.response == id);
    
            if (objIndex !== -1) {
                // Créez une nouvelle copie du tableau 'answers' sans l'objet à supprimer
                const updatedAnswersArray = updatedAnswers[question.category.id][sousquestion.id].answers.filter((_, index) => index !== objIndex);
                // Mettez à jour 'answers' avec la nouvelle copie
                updatedAnswers[question.category.id][sousquestion.id].answers = updatedAnswersArray;
                
                // Mettez à jour la valeur totale
                updatedAnswers[question.category.id][sousquestion.id].totalValueAnswer -= value;
            } else {
                // Créez une nouvelle copie du tableau 'answers' avec la nouvelle réponse
                const updatedAnswersArray = [...updatedAnswers[question.category.id][sousquestion.id].answers, newAnswer];
                // Mettez à jour 'answers' avec la nouvelle copie
                updatedAnswers[question.category.id][sousquestion.id].answers = updatedAnswersArray;
                
                // Mettez à jour la valeur totale
                updatedAnswers[question.category.id][sousquestion.id].totalValueAnswer += value;
            }
        }
    
        return updatedAnswers; // Retournez la nouvelle copie mise à jour
    });
} else {
    updatedAnswer = newAnswer
}
        if(updatedAnswer){
            const updatedSousquestion = {
                ...updatedAnswersForBack[question.category.id][sousquestion.id],
                answers: updatedAnswersForBack[question.category.id][sousquestion.id].answers ? [...updatedAnswersForBack[question.category.id][sousquestion.id].answers, updatedAnswer] : [updatedAnswer],
                totalValueAnswer: totalValue,
            };
            updatedAnswersForBack[question.category.id][sousquestion.id] = updatedSousquestion;
            setAnswersForBack(updatedAnswersForBack);
        }
    };

    const createSaveNewAnswersSousquestion = (event) => {
        if(sousquestion.othersQuestionsId && sousquestion.othersQuestionsId[0]){
            sousquestion.othersQuestionsId.forEach(id => {
                if(!saveNewAnswers[idMenu][id]){
                    if(answers && answers[idMenu] && answers[idMenu][id]){
                        setSaveNewAnswers(prevState => {
                            const updatedQuestion = {...prevState[idMenu][id],
                                selectedAnswer: answers[idMenu][id].response,
                                answerValue: answers[idMenu][id].value,
                                textAnswer: answers[idMenu][id].textResponse,
                                textRapport: null
                            };
                            const updatedCategory = {...prevState[idMenu], [id]: updatedQuestion}
                            const updatedState = {...prevState, [idMenu]: updatedCategory}
                              return updatedState;
                        })
                    }
                }
            });
        }
    }

    useEffect(() => {
        createSaveNewAnswersSousquestion()
    }, [])
    
    return (
        Object.entries(sousquestion.options).map(([optionId, option], optionIndex) => {
            let isSelected = ''
            if(idSelected && idSelected.length+1 > 0){
                isSelected = idSelected.find(obj => obj == optionId)
            }
            return(
            
            <button key={optionIndex} 
            value={optionId}
            onClick={(event) => {handleAddResponse(optionId, option.value, option.text); updateSaveNewAnswers(event)}}
            className={isSelected ? 'responses__button responses__button__selected' : 'responses__button'} >{option.text}</button>
        )})
    )
}

const mapStateToProps = (state) => ({
    idMenu: state.idMenu,
    answers: state.answers,
     });
  const mapDispatchToProps = dispatch => ({
  });
  


export default connect(mapStateToProps, mapDispatchToProps)(MobileResponseMultiple);