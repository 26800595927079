import './modal.scss'
import { Checkbox } from '@mui/material'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'

const MobileModal = ( {params, setDisplayModal, displayModal, modal, idQuestionnaire} ) => {

    const [checkBox, setCheckBox] = useState(true)
    const token = Cookies.get('token')
    const navigate = useNavigate()

    const handleChange = (event) => {
        setCheckBox(event.target.checked)
    }

    const sendCheckbox = () => {
       axios.put(`${process.env.REACT_APP_BACK_URL}/questionnaire/recontact/${params.idQuestionnaire}/${params.idUser}?recontact=${checkBox}`, {}, {headers: {
        Authorization: `Bearer ${token}`,
        UserSession: Cookies.get('localToken')
      }})
       .then((response) => {})
       .catch((error) => {console.log(error); 
        if(error.status === 401 && error.redirectToLogin){
            navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}`)
          }})
       .finally(() => {setDisplayModal(false)})
    }

    return (
        <div className={displayModal ? 'mobile__modal__background' : 'mobile__modal__background mobile__modal__background__hidden'}>
            <div className={displayModal ? 'mobile__modal' : 'mobile__modal modal__hidden'}>
                <h1 className='mobile__modal__title'>{modal ? modal.title : null}</h1> 
                <p className='mobile__modal__text'>{modal ? modal.text : null}</p>
                <div className='mobile__modal__checkbox'>
                    <h2 className='mobile__modal__label'>{modal ? modal.accept : null}</h2><Checkbox checked={checkBox} onChange={handleChange} />
                </div>
                <button className='mobile__modal__button' onClick={() => {sendCheckbox()}}>Valider</button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    idQuestionnaire: state.idQuestionnaire,
  });
  const mapDispatchToProps = dispatch =>({
    });
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(MobileModal);