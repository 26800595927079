import { useEffect, useState } from "react"

const Recap = ( {header, data, title}) => {
    const [totalUser, setTotalUser] = useState()
    const [totalAdmin, setTotalAdmin] = useState()
    const [displayTable, setDisplayTable] = useState(false)

    const changeTotal = () => {
        let tempTotalUser = 0
        let tempTotalAdmin = 0
        data.forEach(d => {
            //if(d.scoreUser > 0){
                tempTotalUser += d.scoreUser
            //}
            //if(d.scoreAdmin > 0){
                tempTotalAdmin += d.scoreAdmin
            //}
        });
        setTotalUser(tempTotalUser)
        setTotalAdmin(tempTotalAdmin)
    }

    useEffect(() => {
        changeTotal()
    }, [])

    return (
        <div className='recap__table'>
            {title ? <div className='rapportWeb__indicator__title'>{title}</div> : null}
            <div className='recap__table__row recap__table__row__title'>
                {header.map((sum, sumIndex) => (
                <div key={sumIndex} className='recap__table__column__title'>
                    {sum.title}
                </div>
                ))}
                <div className='recap__table__arrow__container' onClick={() => {setDisplayTable(!displayTable)}}> 
                    {displayTable ? <div className='recap__table__arrow__reverse' /> : <div className='recap__table__arrow' />}
                </div>
            </div>

            {displayTable ? 
            data.map((d, dIndex) => (
                <div key={dIndex} className={dIndex%2 == 0 ? 'recap__table__row recap__table__row__pair' : 'recap__table__row recap__table__row__impair'}>
                    <div className='recap__table__column__text' >
                        {dIndex+1} -{d.title}
                    </div>
                    <div className={d.scoreUser <= d.scoreAdmin ? 'recap__table__column__note recap__table__column__note__red' : 'recap__table__column__note recap__table__column__note__green'}>
                        {d.scoreUser}
                    </div>
                    <div className='recap__table__column__note'>
                        {d.scoreAdmin}
                    </div>
                </div>
                ))
                
            :
            <div className='recap__table__row recap__table__row__impair'>
                <div className='recap__table__column__total'>
                    Total
                </div>
                <div className={totalUser <= totalAdmin ? 'recap__table__column__note recap__table__column__note__red' : 'recap__table__column__note recap__table__column__note__green'}>
                    {totalUser}
                </div>
                <div className='recap__table__column__note'>
                    {totalAdmin}
                </div>

            </div> }
        </div>
    )
}

export default Recap