import { useState, useEffect } from "react"
import Connexion from "../Connexion"
import './app.scss'
import { connect } from "react-redux"
import icoLanguage from "../../assets/img/valliance/languageIco.png"


const MobileApp = ( {setDisplayChangeAnswers, displayChangeAnswers, alertContainer, languageSelected, login, languages, verifAt, goToAsks, displayBeginButton, changeLangue, menu, modalPin, setAnswersForBack, token, findIdText, setModalPin, goodMail, setGoodMail, errorMessageForBadMail, createNotification}) => {
    const [displayLangues, setDisplayLangues] = useState(false)
    return (
        <div> 
            {languages.length>2 ? 
            <div className={!displayLangues ? 'mobileApp__language' : 'mobileApp__language mobileApp__language__open'} onClick={() => {setDisplayLangues(!displayLangues)}}>
                <img src={icoLanguage} className='mobileApp__language__ico'/>
                <div className={!displayLangues ? 'mobileApp__language__langues' : 'mobileApp__language__langues mobileApp__language__langues__open'}>
                {!displayLangues      
                ? <p className='mobileApp__language__text'>{languageSelected ? languageSelected : null}</p>
                : languages.slice(1).map((lang, langIndex) => {
                    return (
                        <p key={langIndex} className='mobileApp__language__text' onClick={() => {changeLangue(lang.code)}}>{lang.code}</p>
                        )})}
                    
            </div>
            </div>
            : null }
            <Connexion createNotification={createNotification} setDisplayChangeAnswers={setDisplayChangeAnswers} displayChangeAnswers={displayChangeAnswers} alertContainer={alertContainer} createNotification={createNotification} errorMessageForBadMail={errorMessageForBadMail} goodMail={goodMail} setGoodMail={setGoodMail} findIdText={findIdText} setModalPin={setModalPin} modalPin={modalPin} setAnswersForBack={setAnswersForBack} token={token} verifAt={verifAt} login={login} goToAsks={goToAsks} displayBeginButton={displayBeginButton} menu={menu} />
            
    </div>
    )
}
const mapStateToProps = (state) => ({
  languageSelected: state.languageSelected,
  languages: state.languages,
});

const mapDispatchToProps = {
};



export default connect(mapStateToProps, mapDispatchToProps)(MobileApp);