import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { setRapport } from "../../actions/action";
import './profil.scss'
import TextComponent from "../../components/Page/TextComponent";
import Cookies from "js-cookie";
import { Dna } from "react-loader-spinner";

const MobileProfil = ({ languageDefault, languageSelected, rapport, idMenu, allQuestions, sendResponses, idQuestionnaire }) => {

    const dispatch = useDispatch()
    const token = Cookies.get('token')
    const params = useParams()
    const navigate = useNavigate()
    let profil = {}
    if(rapport && rapport.series && rapport.series[0] && rapport.series[0].profils){
        profil = rapport.series[0].profils.find(obj => obj && obj.idChapitre ? obj.idChapitre == idMenu : null)
    }

    const recupRadar = () => {
        if(params && params.idQuestionnaire && params.idUser){
           axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get_report/${params.idQuestionnaire}/${params.idUser}?languageDefault=${languageDefault}&languageSelected=${languageSelected}`,
            {   headers: {
                Authorization: `Bearer ${token}`,
                UserSession: Cookies.get('localToken')
          }}) 
          .then ((response) => {
           dispatch(setRapport(response.data.content))
           
        })
          .catch((error) => {console.log(error); 
            if(error.status === 401 && error.redirectToLogin){
                navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}`)
              }})
            .finally(() => {})
        }
      };

    useEffect(() => {
        recupRadar()
    }, [])

    
    
    const nextChapter = () => {
        const indexMenu = (allQuestions.questions.findIndex(obj => obj.category.id == idMenu))
        const nextChapterObjet = allQuestions.questions[parseInt(indexMenu+1)]
        navigate(`${process.env.REACT_APP_FRONT_LINK}/${params.idQuestionnaire}/${params.idUser}/${nextChapterObjet.category.id}/${nextChapterObjet.pages[0].text.id}/0/${nextChapterObjet.pages[0].lots[0].sousquestions[0].id}`)
    }


    return(
    profil && profil.title && profil.text ? (
        <div className='profil__container'>
               <div className='profil'>
                    <div className='profil__title'>{profil && profil.title ? profil.title : null}</div>
                    <div className='profil__card'>{profil && profil.text ? <TextComponent text={profil.text} /> : null }</div>
                </div> 
            
            {rapport && rapport.recommandation && rapport.recommandation[idMenu] ? 
            <div className='reco'>
                <h1 className='reco__title'>Recommandations</h1>
                {rapport.recommandation[idMenu].map((reco, recoIndex) => (
                    <div key={recoIndex} className='reco__container'>
                        <div className='reco__question'>{reco.question}</div>
                        <div className='reco__text'>{reco.text}</div>
                    </div>
                ))}
            </div>
            : null }
            <div className='profil__button__container'>
                <div className='question__next question__next__reverse' onClick={() => navigate(-1)} />
                <div className='question__next' onClick={() => {nextChapter()}}></div>
            </div>
        </div>
        ) 
        
        : <div className='mobileConnexion__loader__container'>
        <Dna 
        visible={true}
        height="95"
        width="95"
        ariaLabel="dna-loading"
        wrapperClass='mobileConnexion__loader'
        />
    </div>
            
    )
}


const mapStateToProps = (state) => ({
    rapport: state.rapport,
    languageSelected: state.languageSelected,
    languageDefault: state.languageDefault,
    idMenu: state.idMenu,
    allQuestions: state.allQuestions,
    idQuestionnaire: state.idQuestionnaire,
  });
  const mapDispatchToProps = dispatch =>({
    });
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(MobileProfil);