import './menu.scss';
import { Link, useParams, useLocation} from 'react-router-dom'
import { useState, useEffect } from 'react'
import { connect, useDispatch } from "react-redux";
import { setMenuSelect, setStep, setDisplayUnderMenu } from '../../actions/action.js'

const UnderMenu = ( {idUser, idQuestionnaire, allQuestions, idCorrespond, menuContent, menu} ) => {
  const dispatch = useDispatch();
  const location = useLocation()
  const {menuId, idPage} = useParams()
  let findIdText = null;
  if (idCorrespond){
    findIdText = idCorrespond.find(obj => obj.id == idQuestionnaire) 
  }

   // const [menuContent, setMenuContent] = useState()

  let menuIndex = null
  let menuObj = null


if (menu && menu[0]){
  menuIndex = allQuestions.questions.findIndex(obj => obj.category.id == menuId)
  if(menu[menuIndex+1]){
    menuObj = allQuestions.questions.find(obj => obj.category.id == menu[menuIndex+1].id)
  }
}



    return (
      <div className="menu">
        <div className="menu__container">
        <div className="menu__ul">
            <div className='menu__ul__undermenu'>
            {menuContent ? menuContent.pages.map((underM, underMIndex) => {
              return (
              <Link key={underMIndex} 
              title={underM.text.title}
              className={idPage == underM.text.id ? "menu__ul__li__undermenu selected" : "menu__ul__li__undermenu"} 
              to={findIdText ? "/"+ findIdText.text + '/' + idUser + '/' + menuId + '/' + underM.text.id + '/0/' + underM.lots[0].sousquestions[0].id : "/"+ idQuestionnaire + '/' + idUser + '/' + menuId + '/' + underM.text.id + '/0/' + underM.lots[0].sousquestions[0].id }
              >
                    <div className='menu__ul__li__undermenu__text' onClick={() => {dispatch(setStep(underMIndex))}}>{underMIndex+1} - {underM.text.title}</div>
                </Link>
            )}): null}
            <div className='return__cursor' onClick={() => {dispatch(setDisplayUnderMenu(false))}}><div className='return__arrow'></div></div>
            </div>
        </div>
        </div>
      </div>
    );
  };
  
  const mapStateToProps = (state) => ({
    menu: state.menu, 
    menuSelect: state.menuSelect,
    allQuestions: state.allQuestions,
    languageSelected: state.languageSelected,
    needRapport: state.needRapport, 
    idUser: state.idUser,
    idQuestionnaire: state.idQuestionnaire,
    idCorrespond: state.idCorrespond,
    themes: state.themes,
  });
  const mapDispatchToProps = dispatch => ({
    setMenuSelect: (payload) => dispatch(setMenuSelect(payload)),
    setStep: (payload) => dispatch(setStep(payload)),
  });
  


export default connect(mapStateToProps, mapDispatchToProps)(UnderMenu);
