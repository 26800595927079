import { Card, Button } from 'react-bootstrap'
import './home.scss'
import { connect, useDispatch} from 'react-redux'
import {useEffect, useState } from 'react'
import axios from 'axios'
import Modal from '../Modal'
import { PinField } from 'react-pin-field'
import { useNavigate, useParams } from 'react-router-dom'
import { Puff } from 'react-loader-spinner'
import { setAllQuestions, setMenu, setAnswers,} from '../../actions/action'
import Cookies from 'js-cookie';
import AlertModal from '../Modal/alert'

const Home = ( {verifAt, goToAsks,displayChangeAnswers, setDisplayChangeAnswers, displayBeginButton, createNotification, idQuestionnaire, idUser, modalPin, setModalPin, setGoodMail, goodMail, textAlert,homepage, languageSelected, languageDefault, setAnswersForBack, idCorrespond, alertContainer} ) => {
    
    const [reject, setReject] = useState(false)
    const [codePin, setCodePin] = useState('')
    const [personalCodePin, setPersonnalCodePin] = useState('')
    const [forgotPin, setForgotPin] = useState(false)
    const [displayAskNewPinButton, setDisplayAskNewPinButton] = useState(false)
    const [isGenerate, setIsGenerate] = useState(false)
    const navigate = useNavigate()
    const token = Cookies.get('token')
    const [pinError, setPinError] = useState(false)
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const findIdText = idCorrespond.find(obj => obj.id == idQuestionnaire)
    const [displayModal, setDisplayModal] = useState(false)
    const localToken = Cookies.get('localToken')

    
    // A RECEVOIR DU BACK !!!!!!!!!!!!!!!!!!!
    const textForEnterPin = "Merci d'indiquer le code PIN que vous avez reçu par mail ou celui que vous avez paramétré lors de votre première visite"
    const textForForgetPin = "Vous avez oublié votre code PIN ? Pas de soucis, demandez en un nouveau juste "
    const textForGenerateCode = "Vous avez actuellement un code pin généré automatiquement par nos services. Merci de le personnaliser pour plus de sécurité"
    const secondTextForGenerateCode = "Merci de le confirmer"
    const textForAskNewPin = "Afin de demander un nouveau code PIN, merci d'indiquer votre adresse mail ci-dessous"
    const textErrorPin = "Le code PIN que vous avez indiqué n'est pas le bon, merci de réessayer"

    // A RECEVOIR DU BACK POUR LA REACTIVATION DU QUESTIONNAIRE
    

    const needPin = () => {
        goToAsks(codePin)
    }

    const verifPinInBack = () => {
        axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/validate_pin/${idQuestionnaire}/${idUser}`, {
                code: codePin
        }, {
            params: {
              languageSelected: languageSelected,
              languageDefault: languageDefault,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },})
        .then((response) => {
            Cookies.set('localToken', response.data.userSession)
            if(response.data.needCodeReset){
                setIsGenerate(true)

            } else {
                dispatch(setAllQuestions(response.data.content))
                dispatch(setMenu(response.data.content.menu))
                dispatch(setAnswers(response.data.answers))
                setAnswersForBack((prevAnswers) => ({
                    ...prevAnswers,
                    ...response.data.answers
                }));
                if(findIdText){
                    navigate(`${process.env.REACT_APP_FRONT_LINK}/${findIdText.text}/${idUser}/${response.data.content.menu[0].id}/${response.data.content.questions[0].pages[0].text.id}/0/${response.data.content.questions[0].pages[0].lots[0].sousquestions[0].id}`)
                } else {
                    navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${response.data.content.menu[0].id}/${response.data.content.questions[0].pages[0].text.id}/0/${response.data.content.questions[0].pages[0].lots[0].sousquestions[0].id}`)
                }
                setModalPin(false)
            }
        })
    .catch((error) => {setReject(true)})
    .finally(() => {setLoader(false)})
    
}

    useEffect(() => {
        if(codePin.length === 4){
            setLoader(true)
            verifPinInBack()
        }
    }, [codePin])

    const changePersonnalCodePin = (event) => {
        if(event.length === 4){
            if(event === personalCodePin){
                setLoader(true)
                // A FAIRE ICI : Envoyer au back le nouveau code PIN puis lancer la fonction goToAsk avec le nouveau code PIN
                createNotification('success', '', 'Vous allez être redirigé vers le questionnaire, merci de patienter', "En cours de traitement")
                axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/validate_pin/${idQuestionnaire}/${idUser}`, 
                {
                    code: personalCodePin,
                    codeReset: true,
                    mail: null,
                },
                {
                    params: {
                      languageSelected: languageSelected,
                      languageDefault: languageDefault,
                    },
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },}
                )
                  .then((response) => {
                    Cookies.set('localToken', response.data.userSession)
                    if(response.data.selectTheme){
                        if(findIdText){
                            navigate(`${process.env.REACT_APP_FRONT_LINK}/menu/${findIdText.text}/${idUser}`)
                        } else {
                            navigate(`${process.env.REACT_APP_FRONT_LINK}/menu/${idQuestionnaire}/${idUser}`)
                        }
                    } else {
                        dispatch(setAllQuestions(response.data.content))
                dispatch(setMenu(response.data.content.menu))
                dispatch(setAnswers(response.data.answers))
                setAnswersForBack((prevAnswers) => ({
                    ...prevAnswers,
                    ...response.data.answers
                }));
                if(findIdText){
                    navigate(`${process.env.REACT_APP_FRONT_LINK}/${findIdText.text}/${idUser}/${response.data.content.menu[0].id}/${response.data.content.questions[0].pages[0].text.id}`)
                } else {
                    navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${response.data.content.menu[0].id}/${response.data.content.questions[0].pages[0].text.id}`)
                }
                setModalPin(false)
                    }})
                  .catch((error) => {console.log(error)})
                  .finally(() => {setLoader(false)})

            } else {
                createNotification('warning', '', 'Les deux codes PIN ne correspondent pas, merci de réessayer', "Erreur : Codes PIN différents")
            }
        }
    }

    const askNewPin = (bool) => {
        const mail = document.getElementById('mailNewInput')
        if(bool){
            // A FAIRE : Envoyer le mail au back pour récupérer le nouveau code pin
            axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/validate_pin/${idQuestionnaire}/${idUser}`, {
                mail: mail.value,
                code: null,
                codeReset: bool ? bool : false,
        }, {headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
                languageSelected: languageSelected,
                languageDefault: languageDefault,
              }})
        .then((response) => {
            Cookies.set('localToken', response.data.userSession)
            if(response.data.continue){
                goToAsks(codePin)
                if(!response.data.selectTheme){
                    if(findIdText){
                        navigate(`${process.env.REACT_APP_FRONT_LINK}/menu/${findIdText.text}/${idUser}`)
                    } else {
                        navigate(`${process.env.REACT_APP_FRONT_LINK}/menu/${idQuestionnaire}/${idUser}`)
                    }
                    setModalPin(false)
                }
                /* if(menu && menu[0]){
                    navigate(`/${idQuestionnaire}/${idUser}/${menu[0].id}`)} */
                } else {
                createNotification('success', '', 'Un nouveau code PIN vient de vous être envoyé par mail, merci de la saisir', "Nouveau code PIN")
                setForgotPin(false)
                setIsGenerate(false)

            }
        })
        .catch((error) => {
            setPinError(true)
          })
        .finally(() => {})    
           setModalPin(true)
            setForgotPin(false)
            setReject(false)
            setDisplayAskNewPinButton(false)
        } else {
            setForgotPin(true)
        }
    }

    const verifAtNewAsk = (mail) => {
        const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (regexEmail.test(mail)) {
            setDisplayAskNewPinButton(true)
            return (true)
        } else {
            setDisplayAskNewPinButton(false)
        }
    }

    const validChange = () => {
        axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/unlock_survey/${idQuestionnaire}/${idUser}`, {forceUnlock: true}, {
            headers: {
                Authorization: `Bearer ${token}`,
              },
        })
        .then((response) => {
            setDisplayModal(false)
            setDisplayChangeAnswers(false)
            createNotification('success', '', 'Vous pouvez à nouveau modifier vos réponses', 'Veuillez vous reconnecter')
        })
    }
    
    return (
        <div className='home'>
            {homepage && homepage.text && !loader ? 
            <Card className='home'> 
                <Card.Title className="home__title">{homepage.title}</Card.Title>
                <Card.Text className="home__text">{homepage.text}</Card.Text>
                <Card.Text className="home__loginText">{homepage.loginText}</Card.Text>
                <div className='home__mail__container'>
                    <Card.Text className='home__mail__title'>{homepage.accept}</Card.Text><input className='home__mail__input' id='mailInput' onChange={(event) => {verifAt(event.target.value); setGoodMail(false)}}></input>
                    {goodMail ? <Card.Text className='home__mail__alert'>{textAlert}</Card.Text> : null}
                    {displayModal ? <div className='home__modal'><Modal displayModal={displayModal} setDisplayModal={setDisplayModal} text={alertContainer.modal.text} boutonOK={alertContainer.modal.buttonOk} boutonStop={alertContainer.modal.buttonKo} action={validChange}/> </div> : null}
                    {displayChangeAnswers ? <AlertModal alertContainer={alertContainer} setDisplayModal={setDisplayModal} setDisplayChangeAnswers={setDisplayChangeAnswers} action={validChange}/> : null} 
                    {modalPin ? <div className='pin__modal'> 

                    {forgotPin ? 
                                <div className='pin__container'>
                                    <div>{textForAskNewPin}</div>
                                    <input id='mailNewInput' className='home__mail__input' onChange={(event) => {verifAtNewAsk(event.target.value)}}></input>
                                    <div>
                                        {displayAskNewPinButton ? <Button className="home__button" onClick={() => {askNewPin(true)}}>Demander un nouveau code PIN</Button> : null}
                                    </div>
                                </div>
                            : null}
                    
                    {!isGenerate && !forgotPin ? 
                        <div className='pin__container'>
                            {textForEnterPin}
                            <div className='pin__container__onlypin'>
                                <PinField 
                                    length={4}
                                    validate={/^[0-9]$/} 
                                    onRejectKey={() => {setReject(true)}}
                                    onChange={(event) => {setReject(false); setCodePin(event); setPinError(false)}}
                                    type= {'password'}
                                    className={reject || pinError ? "pin pin__reject" : "pin"} />
                            </div>
                            {pinError ? <div className='pin__error__text'>{textErrorPin}</div> : null}
                            <span className='pin__forgot__text'>{textForForgetPin}</span> <span className='pin__forgot__text pin__forgot__link' onClick={() => {askNewPin()}}>ici</span>
                        </div> 
                        
                        : null} 
                        <div className='pin__closeButton' onClick={() => {setModalPin(false); setIsGenerate(false); setForgotPin(false)}}></div>
                        {isGenerate ? 
                            <div className='pin__personnalisation'>
                                {textForGenerateCode} 
                                <div className='pin__container pin__container__second'>
                                <PinField
                                    length={4}
                                    validate={/^[0-9]$/} 
                                    onRejectKey={() => {setReject(true)}}
                                    onChange={(event) => {setReject(false); setPersonnalCodePin(event)}}
                                    type= {'password'}
                                    className={reject ? "pin pin__reject" : "pin"} />
                                </div>
                                {secondTextForGenerateCode}
                                <div className='pin__container pin__container__second'>
                                <PinField 
                                    length={4}
                                    validate={/^[0-9]$/} 
                                    onRejectKey={() => {setReject(true)}}
                                    onChange={(event) => {changePersonnalCodePin(event)}}
                                    type= {'password'}
                                    className={reject ? "pin pin__reject" : "pin"} />
                                    </div>
                                
                            </div>
                        : null} 
                    </div> 
                    : null}
                    {displayBeginButton ?  
                        <Button className="home__button" onClick={needPin}>Commencez le test</Button> 
                        : <Button className="home__button__close" ></Button> 
                    }
                </div>
            </Card>
            : <div className="loader">
                    <Puff 
                        visible={true}
                        color="rgb(218,91,21)"
                        height=""
                        width=""
                        />
                </div>}
                
        </div>
    )
}

const mapStateToProps = (state) => ({
    allQuestions: state.allQuestions,
    idQuestionnaire: state.idQuestionnaire,
    idUser: state.idUser,
    menu: state.menu,
    homepage: state.homepage,
    languageSelected: state.languageSelected,
    languageDefault: state.languageDefault,
    idCorrespond: state.idCorrespond,
});
  const mapDispatchToProps = {
    };
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(Home);

