import './responses.scss'
import { setModif } from "../../actions/action";
import { connect, useDispatch } from "react-redux";
import { useEffect } from 'react';

const MobileResponseButton = ( { sousquestion, updateAnswers, setSaveNewAnswers, idMenu, saveNewAnswers, answers}) => {
    const dispatch = useDispatch()
    const updateSaveNewAnswers = (event) => {
        dispatch(setModif(true))
        setSaveNewAnswers(prevState => {
        const updatedQuestion = {...prevState[idMenu][sousquestion.id],
            selectedAnswer: event.target.value,
            answerValue: sousquestion.options[event.target.value].value,
            textAnswer: sousquestion.options[event.target.value].text,
            textRapport: sousquestion.options[event.target.value].rapportResponse
        };
        const updatedCategory = {...prevState[idMenu], [sousquestion.id]: updatedQuestion}
        const updatedState = {...prevState, [idMenu]: updatedCategory}
          return updatedState;
    })
    createSaveNewAnswersSousquestion()
    }

    const createSaveNewAnswersSousquestion = () => {
        if(sousquestion.othersQuestionsId && sousquestion.othersQuestionsId[0]){
            sousquestion.othersQuestionsId.forEach(id => {
                if(saveNewAnswers && saveNewAnswers[idMenu] && !saveNewAnswers[idMenu][id]){
                    if(answers && answers[idMenu] && answers[idMenu][id]){
                        setSaveNewAnswers(prevState => {
                            const updatedQuestion = {...prevState[idMenu][id],
                                selectedAnswer: answers[idMenu][id].response,
                                answerValue: answers[idMenu][id].value,
                                textAnswer: answers[idMenu][id].textResponse,
                                textRapport: null
                            };
                            const updatedCategory = {...prevState[idMenu], [id]: updatedQuestion}
                            const updatedState = {...prevState, [idMenu]: updatedCategory}
                              return updatedState;
                        })
                    }
                }
            });
        }
    }

    useEffect(() => {
        createSaveNewAnswersSousquestion()
    }, [])
    
    return (
        Object.entries(sousquestion.options).map(([optionId, option], optionIndex) => (
            saveNewAnswers && saveNewAnswers[idMenu] && saveNewAnswers[idMenu][sousquestion.id] && optionId == saveNewAnswers[idMenu][sousquestion.id].selectedAnswer ?
            <button key={optionIndex} 
            value={optionId}
            onClick={(event) => {updateAnswers(optionId, option.value, option.text, sousquestion.id); updateSaveNewAnswers(event)}}
            className='responses__button responses__button__selected'>{option.text}</button>

            : <button key={optionIndex} 
            value={optionId}
            onClick={(event) => {updateAnswers(optionId, option.value, option.text, sousquestion.id); updateSaveNewAnswers(event)}}
            className='responses__button'>{option.text}</button>
    ))
    )
}

const mapStateToProps = (state) => ({
    idMenu: state.idMenu,
    answers: state.answers,
     });
  const mapDispatchToProps = dispatch => ({
  });
  


export default connect(mapStateToProps, mapDispatchToProps)(MobileResponseButton);