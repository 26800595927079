import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { setRapport } from "../../actions/action";
import { useEffect, useState } from "react";
import Modal from './Modal'
import './rapport.scss'
import { Dna } from "react-loader-spinner";
import { PDFDownloadLink, Document, Page, PDFViewer } from '@react-pdf/renderer';
import PDFRapportMobile from "./pdf/index.js";
import RadarMobile from "../Page/Radar";
import Cookies from "js-cookie";
import PDFResumMobile from "./pdf/pdfResumMobile";

const MobileRapport = ( {languageDefault, languageSelected, rapport, setProfilForEnd, profilForEnd, idQuestionnaire} ) => {
    const params = useParams()
    const token = Cookies.get('token')
    const [displayModal, setDisplayModal] = useState(false)
    const dispatch = useDispatch()
    const [canvasDataUrl, setCanvasDataUrl] = useState(null)
    const navigate = useNavigate()
    const [resumData, setResumData] = useState()

    
    const handleCanvasDataChange = (dataURL) => {
        setCanvasDataUrl(dataURL)
    }

    const recupRadar = () => {
        if(params && params.idQuestionnaire && params.idUser){
           axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get_report/${params.idQuestionnaire}/${params.idUser}?languageDefault=${languageDefault}&languageSelected=${languageSelected}`,
            {   headers: {
                Authorization: `Bearer ${token}`,
                UserSession: Cookies.get('localToken')
          }}) 
           
          .then ((response) => {
           setProfilForEnd(response.data.content.series[0].profils)
           dispatch(setRapport(response.data.content))
           
           if(response.data.content.recontact == null){
            setDisplayModal(true)
        }
        
        })
          .catch((error) => {console.log(error);  
            if(error.status === 401 && error.redirectToLogin){
            navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}`)
          }})
            .finally()
        }
          
      };

      const getPDFAnswers = () => {
        axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get_pdf_answers_questions/${params.idQuestionnaire}/${params.idUser}?languageSelected=${languageSelected}&languageDefault=${languageDefault}`, {
            headers: {
                Authorization: `Bearer ${token}`,
              },
        },)
        .then((response) => {console.log(response); setResumData(response.data)})
    }

      useEffect(() => {
        recupRadar()
        getPDFAnswers()
      }, [])

      if(rapport && rapport.message && rapport.user && rapport.group && resumData){
        const recoArray = Object.entries(rapport.recommandation);
        return (
        <div className='rapport'>
            <div className='rapport__pdf__button__container'>
                {canvasDataUrl ? <PDFDownloadLink document={<PDFRapportMobile canvasDataUrl={canvasDataUrl} rapport={rapport} />} fileName='autoeval_rapport' className='rapport__pdf__button'>
                    Téléchargez le rapport au format PDF
                </PDFDownloadLink> : null }
                <PDFDownloadLink document={<PDFResumMobile rapport={rapport} resumData={resumData} />} fileName='autoeval_rapport' className='rapport__pdf__button' >
                    Téléchargez le résumé Questions/Réponses
                </PDFDownloadLink>
            </div>
                
            <div className='rapport__contact'>
                <div className='rapport__contact__infos'>
                    {rapport.message.diag} <br /> {rapport.date}
                </div>
                <div className='rapport__contact__infos'>
                    {rapport.message.by} <br /> {rapport.user.lastname} {rapport.user.firstname}
                </div>
                <div className='rapport__contact__infos'>
                    {rapport.message.entreprise} <br /> {rapport.group.name}
                </div>
                <div className='rapport__contact__text'>
                    {rapport.message.introText}
                </div>
                <div id='graphRadar' className='rapport__contact__graph'>
                    <RadarMobile rapport={rapport} onCanvasDataChange={handleCanvasDataChange} />
                </div>
                {rapport.indicator.map((indic) => {
                    const reco = recoArray.find((obj) => obj[1].idChapitre == indic.id)
                    if (reco && reco[1]) {
                    return (
                        <div className='rapport__contact__text' key={indic.id}>
                            <div className='rapport__contact__infos'>
                                {indic.nom}
                            </div>
                        {reco[1].question.map((r) => (
                            <div key={r.id}>
                                <div className='rapport__contact__question'>Question - {r.text}</div>
                                <div>Recommandation - {r.reco}</div>
                            </div>
                        ))}
                        </div>
                    );
                    } 
                    return null
                })}
            </div>
            <Modal params={params} setDisplayModal={setDisplayModal} displayModal={displayModal} modal={rapport.endingMessage} />
        </div>
    )
      } else {
        return (
            <div className='rapport__loader__container'>
                <Dna 
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperClass='rapport__loader'
                />
            </div>
        )
      }
    
}

const mapStateToProps = (state) => ({
    rapport: state.rapport,
    languageSelected: state.languageSelected,
    languageDefault: state.languageDefault,
    idQuestionnaire: state.idQuestionnaire
  });
  const mapDispatchToProps = dispatch =>({
    });
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(MobileRapport);