import { Card } from 'react-bootstrap'
import './questions.scss'
import flowersLeft from '../../assets/img/valliance/Feuille_gauche.bmp'
import { connect, useDispatch } from 'react-redux'
import React, {useEffect, useState } from 'react'
import {setPath, setStep, setTableDisplay } from '../../actions/action'
import CardGlobal from './Card'
import Rapport from '../Rapport'
import { useNavigate, useParams } from 'react-router-dom'


const Page = ( {answers, createNotification, idQuestionnaire, idCorrespond, saveNewAnswers, docForBack, setDocForBack, setSaveNewAnswers, sum, setSum,menu, currentPath, step, allQuestions, answersForBack, setAnswersForBack, profilForEnd, setProfilForEnd,sendResponses, homepage, menuContent, tableDisplay, indexLot} ) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { menuId, idQuestion, idPage } = useParams()
    const { idUser } = useParams()
    const currentIndex = menuContent.findIndex(m => m.id == menuId)
    const nextIndex = currentIndex + 1
    const [answerRequired, setAnswerRequired] = useState(true)
    const findIdText = idCorrespond.find(obj => obj.id == idQuestionnaire)
    let findQuestion = null
    let findQuestionIndex = null
    let findPage = null
    let findPageIndex = null
    let findLot = null
    let ObjetQuestion = null
    let ObjetIndex = null
    if (menuId && idPage && idQuestion && indexLot){
        findQuestion = allQuestions.questions.find(obj => obj.category.id == menuId)
        findQuestionIndex = allQuestions.questions.findIndex(obj => obj.category.id == menuId)
        findPage = allQuestions.questions[findQuestionIndex].pages.find(obj => obj.text.id == idPage)
        findPageIndex = allQuestions.questions[findQuestionIndex].pages.findIndex(obj => obj.text.id == idPage)
        findLot = findPage.lots[indexLot]
        if(findLot){ObjetQuestion = findPage.lots[indexLot].sousquestions.find(obj => obj.id == idQuestion)}
        if(findLot){ObjetIndex = findPage.lots[indexLot].sousquestions.findIndex(obj => obj.id == idQuestion)}
    }

    useEffect(() => {
        dispatch(setTableDisplay([]))
    }, [findLot])
    
    const cleanString = (str) => {
        return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '_').replace(/[^\w\s]/gi, '');
    }
    const swipeMoins = () => {
      navigate(-1)
      if(step != 0){
        dispatch(setStep(step-1))
      }
    }

    useEffect(() => {
        dispatch(setPath(currentPath));
      }, [dispatch, currentPath, saveNewAnswers]);
      
      const swipePlus = (idPage, currentLotIndex) => {
        const pages = findQuestion.pages;
        const currentPageIndex = pages.findIndex(page => page.text.id == idPage);
        const currentMenu = allQuestions.questions.find(question => question.category.id == menuId)
        const currentMenuIndex = allQuestions.questions.findIndex(question => question.category.id == menuId)
        const nextMenu = allQuestions.questions[parseInt(currentMenuIndex+1)] ? allQuestions.questions.find(question => question.category.id == allQuestions.questions[parseInt(currentMenuIndex+1)].category.id) : null
        const nextMenuId = allQuestions.questions[currentMenuIndex+1] ? allQuestions.questions[currentMenuIndex+1].category.id : null
        const nextPage = currentMenu.pages[currentPageIndex+1]
        const nextPageId = currentMenu.pages[currentPageIndex+1] ? currentMenu.pages[currentPageIndex+1].text.id : null
        const nextLot = findPage.lots[parseInt(currentLotIndex+1)] ? findPage.lots[parseInt(currentLotIndex+1)] : null
        const nextLotIndex = findPage.lots[parseInt(currentLotIndex+1)] ? parseInt(currentLotIndex+1) : 0

        let link = ''
        let canNavigue = true;
            tableDisplay.forEach((underQuestion) => {
                const sousquestion = findLot.sousquestions.find(obj => obj.id == underQuestion)
            if(sousquestion && sousquestion.required){
                    if(saveNewAnswers
                        && saveNewAnswers[findQuestion.category.id]
                        && saveNewAnswers[findQuestion.category.id][sousquestion.id]
                        && (saveNewAnswers[findQuestion.category.id][sousquestion.id].textAnswer || saveNewAnswers[findQuestion.category.id][sousquestion.id].selectedAnswer)){
                            if(findPage.lots[parseInt(currentLotIndex+1)]){
                               link = `${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${menuId}/${findPage.text.id}/${nextLotIndex}/${nextLot.sousquestions[0].id}`
                            } else if (currentMenu.pages[parseInt(currentPageIndex+1)]){
                                link = `${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${menuId}/${nextPageId}/${nextLotIndex}/${nextPage.lots[0].sousquestions[0].id}`
                            } else if (allQuestions.questions[currentMenuIndex+1]){
                                if(findQuestion.needRapport){
                                    link = `${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${menuId}/${idPage}/0/${idQuestion}/profil`
                                } else {
                                   link = `${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${nextMenuId}/${nextMenu.pages[0].text.id}/0/${nextMenu.pages[0].lots[0].sousquestions[0].id}`
                                }
                            } else {
                                link = `${process.env.REACT_APP_FRONT_LINK}/rapport/${idQuestionnaire}/${idUser}`
                            }
                        } else {
                                canNavigue= false
                                createNotification('warning', '', `La réponse à la question "${sousquestion.asks}" est obligatoire`, 'Question Obligatoire')
                            } 
                } else {
                    if(findPage.lots[parseInt(currentLotIndex+1)]){
                        link = `${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${menuId}/${findPage.text.id}/${nextLotIndex}/${nextLot.sousquestions[0].id}`
                     } else if (currentMenu.pages[parseInt(currentPageIndex+1)]){
                         link = `${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${menuId}/${nextPageId}/${nextLotIndex}/${nextPage.lots[0].sousquestions[0].id}`
                     } else {
                         if(findQuestion.needRapport){
                             link = `${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${menuId}/${idPage}/0/${idQuestion}/profil`
                         } else {
                            link = `${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${nextMenuId}/${nextMenu.pages[0].text.id}/0/${nextMenu.pages[0].lots[0].sousquestions[0].id}`
                         }
                     }
                }
            })
      if(canNavigue){
        sendResponses(false)
         navigate(link)
      }
    }

    const isLast = () => {
        const currentMenu = allQuestions.questions.find(question => question.category.id == menuId)
        const currentMenuIndex = allQuestions.questions.findIndex(question => question.category.id == menuId)
        const currentPageIndex = currentMenu.pages.findIndex(page => page.text.id == idPage)
        const currentPage = currentMenu.pages.find(page => page.text.id == idPage)
        if(allQuestions.questions.length-1 == currentMenuIndex){
            if(currentMenu.pages.length-1 == currentPageIndex){
                if(indexLot == currentPage.lots.length-1)
                    return true
            }

        }
    }
     
    return (
        <div className="questions">
            <div className="questions__card__global">
            {findQuestion ? <div>
                        <Card.Title className='questions__title__page' >{findQuestion.title}</Card.Title>
                        <div>
                            {findPage ? 
                                <div className='question__active'>
                                    <Card className="questions__card z">
                                        <Card.Img className="questions__img" src={flowersLeft} />
                                        <Card.Title className="questions__title">{findPageIndex +1} - {findQuestion.pages[findPageIndex].text.title}</Card.Title>
                                    </Card>
                                    {findLot ?
                                        <div>
                                            <CardGlobal sendResponses={sendResponses} indexPage={findPageIndex} swipePlus={swipePlus} docForBack={docForBack} setDocForBack={setDocForBack} setSum={setSum} answersForBack={answersForBack} setAnswersForBack={setAnswersForBack} sum={sum} saveNewAnswers={saveNewAnswers} setSaveNewAnswers={setSaveNewAnswers} lot={findLot} indexLot={indexLot} question={findQuestion} setAnswerRequired={setAnswerRequired} answerRequired={answerRequired} ObjetIndex={ObjetIndex} />
                                        </div>
                                    : null } 
                                    {isLast(findQuestionIndex, findQuestion) ? <div className='questions__card questions__endingMessage'>{homepage.endingMessage}</div> : null}
                                </div> : null }
                            
                            {step == findQuestion.pages.length && findQuestion.needRapport && findQuestion.category.id == menuId ?
                            <Rapport createNotification={createNotification} saveNewAnswers={saveNewAnswers} sum={sum} question={findQuestion} indexQuestion={findQuestionIndex} allQuestions={allQuestions} profilForEnd={profilForEnd} setProfilForEnd={setProfilForEnd} cleanString={cleanString} /> : null}
                        </div>
                    </div> : null }
            </div>
            {!isLast(findQuestionIndex, findQuestion) ? <div className="questions__swipes" onClick={() => {swipePlus(idPage, indexLot, idQuestion)}}></div> : null}
            <div className="questions__swipes reverse" onClick={swipeMoins}></div>
            <div id='response__post'></div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    menuSelect: state.menuSelect,
    questions: state.questions,
    path: state.path,
    rapport: state.rapport,
    step: state.step,
    allQuestions: state.allQuestions,
    languageSelected: state.languageSelected,
    answers: state.answers,
    idQuestionnaire: state.idQuestionnaire,
    idUser: state.idUser,
    homepage: state.homepage,
    idCorrespond: state.idCorrespond,
    menu: state.menu,
    tableDisplay: state.tableDisplay,
    indexLot: state.indexLot,
    });
  const mapDispatchToProps = dispatch =>({
    setPath: (payload) => dispatch(setPath(payload)) 
    });
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(Page);
