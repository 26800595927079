import { useNavigate } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
import { useState } from "react"
import { useEffect } from "react"
import './menuchoice.scss'
import axios from "axios"
import { setAllQuestions, setAnswers, setMenu, setSelectedThemes, setThemes } from "../../actions/action"
import { useParams } from "react-router-dom"
import { Puff  } from "react-loader-spinner"
import Modal from "../Modal"
import Cookies from "js-cookie"
import Checkbox from '@mui/material/Checkbox';
import TextComponent from "../Page/TextComponent"

const MenuChoice = ( {themes, languageDefault, languageSelected, idCorrespond, idQuestionnaire, idMenu}) => {

    const navigate = useNavigate()
    const [selectedIds, setSelectedIds] = useState([]);
    const token = Cookies.get('token')
    const dispatch = useDispatch()
    const [firstRender, setFirstRender] = useState(true)
    const {idUser} = useParams()
    const [textModal, setTextModal] = useState('')
    const [boutonOK, setBoutonOk] = useState('Valider')
    const [boutonStop, setBoutonStop] = useState('Annuler')
    const [displayModal, setDisplayModal] = useState(false)
    const [loader, setLoader] = useState(false)
    const findIdText = idCorrespond.find(obj => obj.id == idQuestionnaire)
    const [displayButton, setDisplayButton] = useState(false)
    const [introText, setIntroText] = useState('')

    const handleOptionClick = (id, bool) => {
        setSelectedIds((prevSelectedIds) => {
          // Vérifier si l'ID est déjà dans le tableau
          const isSelected = prevSelectedIds.some((item) => item.id === id);
    
          if (isSelected) {
            // Si l'ID est déjà sélectionné, le retirer du tableau
            return prevSelectedIds.filter((item) => item.id !== id);
          } else {
            // Sinon, l'ajouter au tableau
            return [...prevSelectedIds, {id, check: true}];
          }
        });

        if(bool){
          setDisplayButton(true)
        }
      };

      const getThemes = () => {
        if(firstRender && idQuestionnaire && idUser){
            axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get_themes/${idQuestionnaire}`,
              {
                params: {
                  languageSelected: languageSelected,
                  languageDefault: languageDefault,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                  UserSession: Cookies.get('localToken')
                },
              }
            )
    .then((response) => {dispatch(setMenu(response.data.themes)); dispatch(setThemes(response.data.themes)); setIntroText(response.data.intro)})
    .catch((error) => { if(error.status === 401 && error.redirectToLogin){
      navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}`)
    }})
     

      axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get_themes/${idQuestionnaire}/${idUser}`, {headers: {
    Authorization: `Bearer ${token}`,
    UserSession: Cookies.get('localToken')
  }})
.then((response) => {dispatch(setSelectedThemes(response.data.themes)); response.data.themes.forEach((theme) => { handleOptionClick(theme.id, false)})    
})
.catch((error) => { if(error.status === 401 && error.redirectToLogin){
  navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}`)
}})

}
setFirstRender(false)
        }
        

      useEffect(() =>  {
        getThemes()
      }, [])

      useEffect(() => {
        if(themes && themes[0]){
          const updatedSelectedIds = themes
          .filter(theme => theme.necessaryChapter && !selectedIds.find(item => item.id === theme.id))
          .map(theme => ({ id: theme.id, check: true }));
      
          if (updatedSelectedIds.length > 0) {
            setSelectedIds(prevSelectedIds => {
              // Filter out existing ids from updatedSelectedIds
              const filteredIds = updatedSelectedIds.filter(
                newId => !prevSelectedIds.some(existingId => existingId.id === newId.id)
              );
      
              // Concatenate the filtered ids with the existing selectedIds
              return [...prevSelectedIds, ...filteredIds];
            });
          }
        }
      }, [themes, selectedIds]);

      
      

    const sendChoicesToBack = (bool) => {
        axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/validate_selection/${idQuestionnaire}/${idUser}`, { selectedIds: selectedIds, confirmationSelect: bool ? bool : false },{ headers: { Authorization: `Bearer ${token}`, UserSession: Cookies.get('localToken')}, params: {
            languageSelected: languageSelected,
            languageDefault: languageDefault,
          } })
        .then((response) => {
            if(response.status === 202 && response.data.warning){
                setTextModal(response.data.warning)
                setDisplayModal(true)
        }
            if (response.status === 200){
                setDisplayModal(false)
                dispatch(setMenu(response.data.content.menu))
                dispatch(setAllQuestions(response.data.content))
                dispatch(setAnswers(response.data.answers))
                if(findIdText){
                  navigate(`${process.env.REACT_APP_FRONT_LINK}/${findIdText.text}/${idUser}/${response.data.content.menu[0].id}/${response.data.content.questions[0].pages[0].text.id}/0/${response.data.content.questions[0].pages[0].lots[0].sousquestions[0].id}`)
                } else {
                  navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${response.data.content.menu[0].id}/${response.data.content.questions[0].pages[0].text.id}/0/${response.data.content.questions[0].pages[0].lots[0].sousquestions[0].id}`)
                }


            }})
          .catch((error) => {console.log(error); 
            if(error.status === 401 && error.redirectToLogin){
              navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}`)
            }})
          .finally(() => {})
    }

          
    return (
        themes && themes[0] ?
        <div className='menuChoice'>
            <div className='menuChoice__intro'> {introText} </div>
            <div className="menuChoice__themes__container">
            {themes.map((theme, themeIndex) => {
              
               const findSelectedActual = selectedIds.some(obj => obj.id === theme.id)
               return(
               <div>
                 <div className={themeIndex === themes.length-1 ? 'menuChoice__themes menuChoice__themes__last' : 'menuChoice__themes'}>
                   <div  className={findSelectedActual ? "menuChoice__themes__text menuChoice__themes__text__animated" : "menuChoice__themes__text"}>
                       {theme.title}
                   </div>
                   <div className="menuChoice__themes__text">
                     <TextComponent text={theme.description} />
                   </div>
                   <div className="menuChoice__themes__text" onClick={!theme.necessaryChapter ? () => {handleOptionClick(theme.id, true)} : null} > 
                     {theme.necessaryChapter ? <Checkbox checked={true} disabled /> : <Checkbox checked={findSelectedActual ? findSelectedActual : false} />}
                     </div>
                 </div>
                 {themeIndex !== themes.length-1 ? <div className="separator" /> : null}
                 </div>
               )
           })}  
           </div>          
           <div 
           className='menuChoice__button menuChoice__button__validate'
           onClick={() => {sendChoicesToBack(false)}}>
               Valider {selectedIds.length > 1 ? 'vos choix' : 'votre choix'}
           </div> 
           
           


   {displayModal ? <Modal setDisplayModal={setDisplayModal} action={sendChoicesToBack} boutonOK={boutonOK} boutonStop={boutonStop} text={textModal} /> : null}

        </div>
        :   <div className="loader">
            <Puff 
                visible={true}
                color="rgb(218,91,21)"
                height=""
                width=""
                />
              </div>
    )
}


const mapStateToProps = (state) => ({
    menu: state.menu,
    themes: state.themes,
    selectedThemes: state.selectedThemes,
    languageDefault: state.languageDefault,
    languageSelected: state.languageSelected,
    idCorrespond: state.idCorrespond,
    idQuestionnaire: state.idQuestionnaire,
    idMenu: state.idMenu
});
  const mapDispatchToProps = {
    };
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(MenuChoice);