import { Slider } from "@mui/material"
import { connect, useDispatch } from "react-redux";
import { setModif } from "../../actions/action";

const MobileResponseSlide = ( { sousquestion, setSaveNewAnswers, idMenu, updateAnswers, answers, saveNewAnswers }) => {
    const dispatch = useDispatch()
    let valueSlide = 0;
    if (answers[idMenu] && answers[idMenu][sousquestion.id]) {
        valueSlide = parseInt(answers[idMenu][sousquestion.id].textResponse)
    } else if ( saveNewAnswers && saveNewAnswers[idMenu] && saveNewAnswers[idMenu][sousquestion.id] && saveNewAnswers[idMenu][sousquestion.id].textAnswer){
      valueSlide = parseInt(saveNewAnswers[idMenu][sousquestion.id].textAnswer)
    }

    const changeSelectValue = (event, newValue) => {
          const pointsAttribues = Math.ceil((newValue - parseInt(sousquestion.options[0]))/(parseInt(sousquestion.options[1]) - parseInt(sousquestion.options[0])) * sousquestion.pointsValue);
          setSaveNewAnswers(prevState => {
          const updatedQuestion = {
            ...prevState[idMenu][sousquestion.id], 
              selectedAnswer: null,
              answerValue: pointsAttribues,
              textAnswer: event.target.value,
              textRapport: null,
            }
              
            const updatedCategory = {...prevState[idMenu], [sousquestion.id]: updatedQuestion}
            const updatedState = {...prevState, [idMenu]: updatedCategory}
            return updatedState;
          });
          updateAnswers(null, pointsAttribues, event.target.value, sousquestion.id)
          dispatch(setModif(true))
            }
    

    return (
        <div className='slider__container'>
        <p className='slider__text'>{sousquestion.options[0]}</p>
        <Slider
            onChange={changeSelectValue}
            valueLabelDisplay="on"
            defaultValue={valueSlide}
            min={parseInt(sousquestion.options[0])}
            className='questions__card__slide'
            max={parseInt(sousquestion.options[1])} />
        <p className='slider__text'>{sousquestion.options[1]}</p>
        </div>
    )
}

const mapStateToProps = (state) => ({
    languageSelected: state.languageSelected,
    answers: state.answers,
    fonction: state.fonction,
    dirigeantlastname: state.dirigeantlastname,
    dirigeantfirstname: state.dirigeantfirstname,
    dirigeantmail: state.dirigeantmail,
    idMenu: state.idMenu,
 });
  const mapDispatchToProps = {
    };
  
  export default connect(mapStateToProps, mapDispatchToProps)(MobileResponseSlide);