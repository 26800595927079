import './page.scss'
import { connect, useDispatch } from 'react-redux'
import MobileCard from './Card'
import MobileMenu from '../Menu'
import { useState, useEffect } from 'react'
import logo from '../../assets/img/valliance/Valliance vec.jpg'
import { setTableDisplay } from '../../actions/action'
const MobilePage = ( {setAnswersForBack, modalPin, allQuestions, saveNewAnswers, subQuestionIds, setSum, setSaveNewAnswers, answersForBack, sendResponses, answers, idMenu, idPage, indexLot, idQuestion, createNotification, finishQuestionnaire, setDocForBack, docForBack}) => {

    const [menuBurger, setMenuBurger] = useState(false)
    const [answerRequired, setAnswerRequired] = useState(true)
    let findQuestion = null
    let findQuestionIndex = null
    let findPage = null
    let findPageIndex = null
    let findLot = null
    let ObjetQuestion = null
    let ObjetIndex = null
    const dispatch = useDispatch()

    
    if (idMenu && idPage && idQuestion && indexLot){
        findQuestion = allQuestions.questions.find(obj => obj.category.id == idMenu)
        findQuestionIndex = allQuestions.questions.findIndex(obj => obj.category.id == idMenu)
        findPage = allQuestions.questions[findQuestionIndex].pages.find(obj => obj.text.id == idPage)
        findPageIndex = allQuestions.questions[findQuestionIndex].pages.findIndex(obj => obj.text.id == idPage)
        findLot = findPage.lots[indexLot]
        ObjetQuestion = findPage.lots[indexLot].sousquestions.find(obj => obj.id == idQuestion)
        ObjetIndex = findPage.lots[indexLot].sousquestions.findIndex(obj => obj.id == idQuestion)
    }
    
    useEffect(() => {
        dispatch(setTableDisplay([]))
    }, [findLot])
    
    
    if (!saveNewAnswers[idMenu]) {
               setSaveNewAnswers(prevState => ({
                 ...prevState,[idMenu]: {}}));
             }
    const createSaveNewAnswers = (sousquestion) => {
                setSaveNewAnswers(prevState => {
                    const updatedQuestion = {
                        childId: sousquestion.othersQuestionsId,
                        waitingAnswer: sousquestion.indexAnswerForQuestion, 
                        selectedAnswer: answers && answers[idMenu] && answers[idMenu][sousquestion.id] ? answers[idMenu][sousquestion.id].response : '',
                        parentId: sousquestion.idForOtherQuestion,
                        answerValue: answers && answers[idMenu] && answers[idMenu][sousquestion.id] ? answers[idMenu][sousquestion.id].value : 0,
                        textQuestion: sousquestion.asks,
                        textAnswer: answers && answers[idMenu] && answers[idMenu][sousquestion.id] ? answers[idMenu][sousquestion.id].textResponse : null, 
                        textRapport: answers && answers[idMenu] && answers[idMenu][sousquestion.id] && answers[idMenu][sousquestion.id].response ? sousquestion.options[answers[idMenu][sousquestion.id].response].rapportResponse : null,
                        identityType: sousquestion.identityType ? sousquestion.identityType : null,
                        required: sousquestion.required,
                    };

                    const updatedState = {...prevState, [idMenu]: {...prevState[idMenu], [sousquestion.id]: updatedQuestion}};
                    return updatedState;
                })
            }
    
    return ( 
        <div className={menuBurger ? 'mobile__page mobile__page__overflow' : 'mobile__page'}>
            <div className='mobile__header'>
            <img src={logo} className='mobile__logo' />
            <div className='mobile__menu__burger__container' onClick={() => {setMenuBurger(!menuBurger)}}>
                <div className={!menuBurger ? 'mobile__menu__burger__stick' : 'mobile__menu__burger__stick mobile__menu__burger__stick__right'}></div>
                <div className={!menuBurger ? 'mobile__menu__burger__stick' : 'mobile__menu__burger__stick mobile__menu__burger__stick__none'} ></div>
                <div className={!menuBurger ? 'mobile__menu__burger__stick' : 'mobile__menu__burger__stick mobile__menu__burger__stick__left'}></div>
            </div>
            </div>
            {menuBurger ? <MobileMenu menuBurger={menuBurger} setMenuBurger={setMenuBurger} /> : null}
            {findQuestion ? 
                    <div className='mobile__page__question'>
                        <div></div>
                    <div  className='mobile__page__title'>{findQuestion.category.title}</div>
                        {findPage ?
                                <div className='mobile__page__question__container' >
                                    <div className='mobile__page__question__flex'>
                                        <h3 className='mobile__page__question__title'>{findPageIndex} - {findPage.text.title}</h3>
                                    {findLot ?
                                            <div className='mobile__page__question__sousquestion__container'>
                                                <MobileCard createSaveNewAnswers={createSaveNewAnswers} setAnswersForBack={setAnswersForBack} modalPin={modalPin} setSum={setSum} setDocForBack={setDocForBack} docForBack={docForBack} subQuestionIds={subQuestionIds} createNotification={createNotification} answersForBack={answersForBack} setAnswerRequired={setAnswerRequired} saveNewAnswers={saveNewAnswers} setSaveNewAnswers={setSaveNewAnswers} lot={findLot} lotIndex={indexLot} question={findQuestion} page={findPage} pageIndex={findPageIndex} allQuestions={allQuestions} indexQuestion={findQuestionIndex} sendResponses={sendResponses} ObjetQuestion={ObjetQuestion} ObjetIndex={ObjetIndex} idMenu={idMenu} />
                                            </div>
                                    : null}
                        <div className='mobile__send'>
                            <div className='mobile__send__button' onClick={() => {sendResponses(false)}}>Enregistrer les réponses</div>
                            <div className='mobile__send__button' onClick={() => {finishQuestionnaire(true)}}>Valider le questionnaire</div>
                        </div>
                                    </div>   
                                </div>
                        : null}
                    </div>
            : null}
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    allQuestions: state.allQuestions,
    step: state.step,
    answers: state.answers,

  });
  const mapDispatchToProps = {
    };
    
  export default connect(mapStateToProps, mapDispatchToProps)(MobilePage);