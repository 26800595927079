import { useEffect, useState } from "react";
import { setMenu, setThemes, setSelectedThemes } from "../../actions/action";
import { connect, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Puff } from "react-loader-spinner";
import './changetheme.scss'
import { Checkbox } from "@mui/material";
import { setAllQuestions, setAnswers } from "../../actions/action";
import ConfirmationModal from "../Modal/modalConfirmation";


const ChangeTheme = ( {themes, idQuestionnaire, idUser, languageDefault, languageSelected,idCorrespond}) => {
    const [ introText, setIntroText] = useState('')
    const [selectedIds, setSelectedIds] = useState([]);
    const token = Cookies.get('token')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [displayModal, setDisplayModal] = useState(false)
    const [textModal, setTextModal] = useState('')
    const findIdText = idCorrespond.find(obj => obj.id == idQuestionnaire)
    const [displayButton, setDisplayButton] = useState(false)


    const getThemes = () => {
        if(idQuestionnaire && idUser){
            axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get_themes/${idQuestionnaire}`,
              {
                params: {
                  languageSelected: languageSelected,
                  languageDefault: languageDefault,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                  UserSession: Cookies.get('localToken')
                },
              }
            )
          .then((response) => {dispatch(setMenu(response.data.themes)); dispatch(setThemes(response.data.themes)); setIntroText(response.data.intro)})
          .catch((error) => { if(error.status === 401 && error.redirectToLogin){
            navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}`)
          }})
     

      axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get_themes/${idQuestionnaire}/${idUser}`, {headers: {
        Authorization: `Bearer ${token}`,
        UserSession: Cookies.get('localToken')
      }})
        .then((response) => {dispatch(setSelectedThemes(response.data.themes)); response.data.themes.forEach((theme) => { handleOptionClick(theme.id, false)})    
        })
        .catch((error) => { if(error.status === 401 && error.redirectToLogin){
          navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}`)
        }})
}}

const sendChoicesToBack = (bool) => {
    // A FAIRE : Envoyer les thèmes selectionnés au back, puis rediriger vers le menu
    axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/validate_selection/${idQuestionnaire}/${idUser}`, { selectedIds: selectedIds, confirmationSelect: bool ? bool : false },{ headers: { Authorization: `Bearer ${token}`, UserSession: Cookies.get('localToken')}, params: {
        languageSelected: languageSelected,
        languageDefault: languageDefault,
      } })
    .then((response) => {
        if(response.status === 202 && response.data.warning){
            setTextModal(response.data.warning)
            setDisplayModal(true)
    }
        if (response.status === 200){
            setDisplayModal(false)
            dispatch(setMenu(response.data.content.menu))
            dispatch(setAllQuestions(response.data.content))
            dispatch(setAnswers(response.data.answers))
            if(findIdText){
              navigate(`${process.env.REACT_APP_FRONT_LINK}/${findIdText.text}/${idUser}/${response.data.content.menu[0].id}/${response.data.content.questions[0].pages[0].text.id}/0/${response.data.content.questions[0].pages[0].lots[0].sousquestions[0].id}`)
            } else {
              navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${response.data.content.menu[0].id}/${response.data.content.questions[0].pages[0].text.id}/0/${response.data.content.questions[0].pages[0].lots[0].sousquestions[0].id}`)
            }


        }})
      .catch((error) => {console.log(error); 
        if(error.status === 401 && error.redirectToLogin){
          navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}`)
        }})
      .finally(() => {})
}

useEffect(() => {
  if(themes && themes[0]){
    const updatedSelectedIds = themes
    .filter(theme => theme.necessaryChapter && !selectedIds.find(item => item.id === theme.id))
    .map(theme => ({ id: theme.id, check: true }));

if (updatedSelectedIds.length > 0) {
setSelectedIds(prevSelectedIds => [...prevSelectedIds, ...updatedSelectedIds]);
}
  }
}, [themes])

const handleOptionClick = (id, bool) => {
    setSelectedIds((prevSelectedIds) => {
      // Vérifier si l'ID est déjà dans le tableau
      const isSelected = prevSelectedIds.some((item) => item.id === id);

      if (isSelected) {
        // Si l'ID est déjà sélectionné, le retirer du tableau
        return prevSelectedIds.filter((item) => item.id !== id);
      } else {
        // Sinon, l'ajouter au tableau
        return [...prevSelectedIds, {id, check: true}];
      }
    });
  };

useEffect(() => {
    getThemes()
}, [idQuestionnaire, idUser])

    return (
        themes && themes[0] ?
        <div className='themes'>
            <div className='themes__intro'>{introText}</div>
            {themes.map((th, thIndex) => {
                const findSelectedActual = selectedIds.some(obj => obj.id === th.id)
                return( 
                    <div key={thIndex} className='themes__container'>
                        <div className="themes__choice">
                            <div className="themes__choice__title">
                                {th.title}
                            </div>
                            <div onClick={!th.necessaryChapter ? () => {handleOptionClick(th.id, true); setDisplayButton(true)} : null}>
                            {th.necessaryChapter ? <Checkbox checked={true} disabled /> : <Checkbox checked={findSelectedActual ? findSelectedActual : false} />}
                            </div>
                        </div>
                        <div className="themes__description">
                            {th.description}
                        </div>
                        
                    </div>
                )
            })}

            <div className='themes__button' onClick={() => {sendChoicesToBack(false)}}>
                Valider vos choix
            </div>

            {displayModal ? 
            <ConfirmationModal text={textModal} sendChoicesToBack={sendChoicesToBack} />
            : null}
        </div>
        : <div className="loader">
        <Puff 
            visible={true}
            color="rgb(218,91,21)"
            height=""
            width=""
            />
    </div>
    )
}

const mapStateToProps = (state) => ({
    menu: state.menu,
    themes: state.themes,
    selectedThemes: state.selectedThemes,
    languageDefault: state.languageDefault,
    languageSelected: state.languageSelected,
    idCorrespond: state.idCorrespond,
    idQuestionnaire: state.idQuestionnaire,
    idUser: state.idUser,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeTheme);