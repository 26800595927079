/* import { useEffect, useState } from "react"
import { connect, useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"
import { setFonction, setModif } from '../../actions/action'

const MobileResponseSelect = ( { sousquestion, answers, updateAnswers, setAnswerRequired, setSaveNewAnswers, answersForBack}) => {
    const dispatch = useDispatch()
    const {menuId, idQuestion} = useParams()
    const optionsTable = Object.entries(sousquestion.options)

    const selectValue = (event) => {
        setAnswerRequired(true)
        
        setSaveNewAnswers(prevState => {
            const updatedQuestion = {...prevState[menuId][sousquestion.id],
                selectedAnswer: event.target.value,
                answerValue: sousquestion.options[event.target.value].value,
                textAnswer: sousquestion.options[event.target.value].text,
                textRapport: sousquestion.options[event.target.value].rapportResponse
            };
            const updatedCategory = {...prevState[menuId], [sousquestion.id]: updatedQuestion}
            const updatedState = {...prevState, [menuId]: updatedCategory}
              return updatedState;
        })
        
        updateAnswers(event.target.value, sousquestion.options[event.target.value].value, sousquestion.options[event.target.value].text, sousquestion.id)
    }


    useEffect(() => {
        if(answers && answers[menuId] && answersForBack && answersForBack[menuId] && !answersForBack[menuId][idQuestion]){
            updateAnswers(optionsTable[0][0], optionsTable[0][1].value, optionsTable[0][1].text, sousquestion.id)
        }
    }, [])

    const saveInfo = (event) => {
        if(sousquestion.identityType === 'role' && event.target.value == 3){
            console.log(event.target.value)
            dispatch(setFonction(true))
        } else {
            dispatch(setFonction(false))
        }
    }

    const updateData = (event) => {
        const selectedOptionId = event.target.id;
        updateAnswers(selectedOptionId, event.target.id, event.target.text, sousquestion.id)
        dispatch(setModif(true))
    }

    return (
        <div className='question__card__select__container'>
        <select className='question__card__select' onChange={(event) => {updateData(event); saveInfo(event); selectValue(event)}}>
            <option>Choisissez une option</option>
            {Object.entries(sousquestion.options).map(([idOption, option], optionIndex) => {
                if(answersForBack && answersForBack[menuId] && answersForBack[menuId][sousquestion.id] && answersForBack[menuId][sousquestion.id].response == idOption){
                   return(
                        <option key={optionIndex} value={idOption} selected>{option.text}</option>
                        ) 
                } else {
                    return(
                        <option key={optionIndex} value={idOption}>{option.text}</option>
                        ) 
                }
                
            }    
            )}
        </select>
        </div>
    )
}

const mapStateToProps = (state) => ({
    answers: state.answers

  });
  const mapDispatchToProps = {
    };
    
  export default connect(mapStateToProps, mapDispatchToProps)(MobileResponseSelect);
*/
import { useEffect, useState } from "react"
import { connect, useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"
import { setFonction, setModif } from '../../actions/action'
import Select from 'react-select'

const MobileResponseSelect = ( { recursiveUpdate, question, sousquestion, answers, updateAnswers, setAnswerRequired, setSaveNewAnswers, answersForBack, saveNewAnswers}) => {
    const dispatch = useDispatch()
    const {menuId, idQuestion} = useParams()
    const optionsTable = Object.entries(sousquestion.options)

    const optionsValues = sousquestion.options ? Object.values(sousquestion.options) : []
    const optionsKeys = sousquestion.options ? Object.keys(sousquestion.options) : []
    const options = [];
    useEffect(() => {
        if(optionsValues && sousquestion.askTypes === 'select'){
            const items = optionsValues.map((option, optionIndex) => ({
                label: option.text, value: option.value, index: optionIndex, id: parseInt(optionsKeys[optionIndex])
            }))
            options.push(...items)
        }
    }, [optionsValues])

    const selectValue = (event) => {
        recursiveUpdate(sousquestion)
        dispatch(setModif(true))
        setAnswerRequired(true)
        // const selectedIndex = optionsValues.findIndex((option) => option.text === event.target.value); // recherche l'index de l'option sélectionnée
            setSaveNewAnswers(prevState => {
                const updatedQuestion = {...prevState[question.category.id][sousquestion.id],
                selectedAnswer: optionsKeys[event.index],
                answerValue: event.value,
                textAnswer: event.label,
                textRapport: sousquestion.options[optionsKeys[event.index]].rapportResponse
            };
            const updatedCategory = {...prevState[question.category.id], [sousquestion.id]: updatedQuestion}
            const updatedState = {...prevState, [question.category.id]: updatedCategory}
              return updatedState;
        })
        updateAnswers(event.id, sousquestion.options[event.id].value, event.label, sousquestion.id)
    }


    useEffect(() => {
        if(answers && answers[menuId] && answersForBack && answersForBack[menuId] && !answersForBack[menuId][idQuestion]){
            updateAnswers(optionsTable[0][0], optionsTable[0][1].value, optionsTable[0][1].text, sousquestion.id)
        }
    }, [])

    const saveInfo = (event) => {
        if(sousquestion.identityType === 'role' && event.label === 'Dirigeant'){
           dispatch(setFonction(true))
        } else {
           dispatch(setFonction(false))
        }
    }

    return (
        <div className='question__card__select__container'>
        <Select options={options} className='questions__card__select' classNamePrefix='questions__card__select' unstyled onChange={(event) => {selectValue(event); saveInfo(event)}}
        defaultValue={
            saveNewAnswers 
            && saveNewAnswers[question.category.id] 
            && saveNewAnswers[question.category.id][sousquestion.id] 
            ? {
                label: saveNewAnswers[question.category.id][sousquestion.id].textAnswer, 
                value:saveNewAnswers[question.category.id][sousquestion.id].answerValue,
              }
            : 'Choisissez une option'}
        />
        </div>
    )
}

const mapStateToProps = (state) => ({
    answers: state.answers

  });
  const mapDispatchToProps = {
    };
    
  export default connect(mapStateToProps, mapDispatchToProps)(MobileResponseSelect);