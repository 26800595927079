import './responses.scss'
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { setDirigeantFirstname, setDirigeantLastname, setModif } from "../../actions/action";

const MobileResponseInput = ( { sousquestion, updateAnswers,fonction, dirigeantfirstname, setSaveNewAnswers, dirigeantlastname, saveNewAnswers, dirigeantmail, question, setAnswerRequired, idMenu, lot,answers}) => {
    let questionValue = null;
    const sousquestionLabel = sousquestion.label
    const dispatch = useDispatch()

    
    useEffect(() => {
        if(saveNewAnswers && saveNewAnswers[question.category.id]){
            const findFirstNameObj = lot.sousquestions.find(obj => obj.identityType === 'firstname')
            const findFirstNameValue = findFirstNameObj && saveNewAnswers[question.category.id][findFirstNameObj.id] ? saveNewAnswers[question.category.id][findFirstNameObj.id].textAnswer : null
            const findLastNameObj = lot.sousquestions.find(obj => obj.identityType === 'lastname')
            const findLastNameValue = findLastNameObj && saveNewAnswers[question.category.id][findLastNameObj.id] ? saveNewAnswers[question.category.id][findLastNameObj.id].textAnswer : null
            
            if(fonction && findFirstNameObj && findLastNameObj){
                dispatch(setDirigeantFirstname(findFirstNameValue))
                dispatch(setDirigeantLastname(findLastNameValue))
            } else if (!fonction) {
                dispatch(setDirigeantFirstname(null))
                dispatch(setDirigeantLastname(null))
            }
            
            if(sousquestion.identityType === 'dirigeantfirstname' && dirigeantfirstname){
                setSaveNewAnswers(prevState => {
                    const updatedQuestion  = {...prevState[question.category.id][sousquestion.id],
                        textAnswer: dirigeantfirstname,
                        answerValue: questionValue,
                    };
                    const updatedCategory = {...prevState[question.category.id], [sousquestion.id]: updatedQuestion}
                    const updatedState = {...prevState, [question.category.id]: updatedCategory}
                    return updatedState;
                });
                updateAnswers(null, questionValue, dirigeantfirstname, sousquestion.id)
            
            } else if (sousquestion.identityType === 'dirigeantlastname' && dirigeantlastname){
                setSaveNewAnswers(prevState => {
                    const updatedQuestion  = {...prevState[question.category.id][sousquestion.id],
                        textAnswer: dirigeantlastname,
                        answerValue: questionValue,
                    };
                    const updatedCategory = {...prevState[question.category.id], [sousquestion.id]: updatedQuestion}
                    const updatedState = {...prevState, [question.category.id]: updatedCategory}
                    return updatedState;
                });
                updateAnswers(null, questionValue, dirigeantlastname, sousquestion.id)
            }
        }
    }, [fonction])

    const takePlaceholder = () => {
    if (sousquestion.pointsValues) {
       questionValue = sousquestion.values[sousquestion.pointsValue]
    }
    }

    const updateSaveNewAnswers = (event) => {
        setSaveNewAnswers(prevState => {
            const updatedQuestion  = {...prevState[question.category.id][sousquestion.id],
                textAnswer: event.target.value,
                answerValue: questionValue,
            };
            const updatedCategory = {...prevState[question.category.id], [sousquestion.id]: updatedQuestion}
            const updatedState = {...prevState, [question.category.id]: updatedCategory}
            return updatedState;
        });

        updateAnswers(null, questionValue, event.target.value, sousquestion.id)
    };

    const updateData = (event) => {
        updateSaveNewAnswers(event)
        setAnswerRequired(true)
        dispatch(setModif(true))
    }

    useEffect(() => {
        takePlaceholder()
    }, [saveNewAnswers])

    return (
        saveNewAnswers && saveNewAnswers[question.category.id] && saveNewAnswers[question.category.id][sousquestion.id] ? 
        !sousquestion.isWide ?
        <input className='responses__input'
        placeholder={sousquestionLabel ? sousquestionLabel : null} 
        defaultValue={saveNewAnswers[question.category.id][sousquestion.id].textAnswer ? saveNewAnswers[question.category.id][sousquestion.id].textAnswer : sousquestion.identityType === 'dirigeantfirstname' && dirigeantfirstname ? dirigeantfirstname : sousquestion.identityType === 'dirigeantlastname' && dirigeantlastname ? dirigeantlastname : null} 
        onKeyUp={(event) => {updateData(event)}}></input> 
        :
        <textarea className='responses__textarea'
        placeholder={sousquestionLabel ? sousquestionLabel : null} 
        defaultValue={saveNewAnswers[question.category.id][sousquestion.id].textAnswer ? saveNewAnswers[question.category.id][sousquestion.id].textAnswer : sousquestion.identityType === 'dirigeantfirstname' && dirigeantfirstname ? dirigeantfirstname : sousquestion.identityType === 'dirigeantlastname' && dirigeantlastname ? dirigeantlastname : null} 
        onKeyUp={(event) => {updateData(event)}}></textarea> 
        
        : null
        
    )
}

const mapStateToProps = (state) => ({
    languageSelected: state.languageSelected,
    answers: state.answers,
    fonction: state.fonction,
    dirigeantlastname: state.dirigeantlastname,
    dirigeantfirstname: state.dirigeantfirstname,
    dirigeantmail: state.dirigeantmail,
    idMenu: state.idMenu,
    answers: state.answers
 });
  const mapDispatchToProps = {
    };
  
  export default connect(mapStateToProps, mapDispatchToProps)(MobileResponseInput);