import TextComponent from "../Page/TextComponent"
import { Checkbox } from "@mui/material"

const Recommandations = ( {data, addReco, showCheckboxColumn}) => {
    return (
        <div>
                   <div className='questions__card rapportChapt__conseils'>
                    <div className='rapportChapt__card__title'>Les recommandations :</div>
                    <div className='rapportChapt__conseils__container'>
                        <div className='rapportChapt__conseils__part__title'>#</div>
                        <div className='rapportChapt__conseils__part__title'>La question</div>
                        <div className='rapportChapt__conseils__part__title'>Votre réponse</div>
                        <div className='rapportChapt__conseils__part__title'>Les recommandations</div>
                        {showCheckboxColumn ? <div className='rapportChapt__conseils__part__title'>Je m'engage</div> : null }
                    </div>
                            {data.question ? data.question.map((reco, index) => {
                                    return (
                                        <div>
                                    <div className='rapportChapt__conseils__container'>
                                        <div className='rapportChapt__conseils__part__text'>{reco.idQuestion}</div>
                                        <div className='rapportChapt__conseils__part__text'>{reco.text}</div>
                                        <div className='rapportChapt__conseils__part__text'>{reco.response}</div>
                                        <div className='rapportChapt__conseils__part__text'><TextComponent text={reco.reco} /></div>
                                        {showCheckboxColumn ? <div className='rapportChapt__conseils__part__text'>{reco.checkbox ? <Checkbox onChange={(event) => addReco(reco, event)} defaultChecked /> : <Checkbox onChange={(event) => addReco(reco, event)} />} </div> : null}
                                    </div>
                                    {index+1 != data.question.length ? <div className='separator' /> : null}
                                    </div>
                                    )
                            }) : null}
                </div> 
                </div>
    )
}

export default Recommandations