import './connexion.scss'
import { connect } from 'react-redux'
import { Dna } from "react-loader-spinner";
import PINModal from '../Modal/modalPIN';
import AlertModalMobile from '../Modal/alertModal';

const Connexion = ( {setDisplayChangeAnswers, displayChangeAnswers, alertContainer, allQuestions, verifAt, goToAsks, displayBeginButton, homepage, modalPin, setAnswersForBack, findIdText, setModalPin, goodMail, setGoodMail, errorMessageForBadMail, createNotification }) => {

    

    return (
        <div className='mobileConnexion'>
            {allQuestions && homepage && homepage.title && homepage.text ? 
        <div className='mobileConnexion__container'>
            <div className='mobileConnexion__title'>
                {homepage.title}
            </div>
            <div className='mobileConnexion__text'>
                {homepage.text}
            </div>
            <div className='mobileConnexion__text'>
                {homepage.loginText} 
            </div>
            <input className='mobileConnexion__input' id='mailInput' onChange={(event) => {verifAt(event.target.value); setGoodMail(false)}}></input>
            {displayBeginButton ?  
                        <button className="mobileConnexion__button" onClick={() => {goToAsks()}}>Commencez le test</button> 
                        : <button className="mobileConnexion__button__close" ></button> 
                    }
            {modalPin ? <PINModal createNotification={createNotification} goToAsks={goToAsks} setModalPin={setModalPin} findIdText={findIdText} setAnswersForBack={setAnswersForBack} /> : null}
            {goodMail ? <div className='mobilePin__error'>{errorMessageForBadMail}</div> : null}
            {displayChangeAnswers ? <AlertModalMobile createNotification={createNotification} data={alertContainer} setDisplayChangeAnswers={setDisplayChangeAnswers} /> : null}
                   
            </div>
            : <div className='mobileConnexion__loader__container'>
            <Dna 
            visible={true}
            height="95"
            width="95"
            ariaLabel="dna-loading"
            wrapperClass='mobileConnexion__loader'
            />
        </div>}
    </div>)
}

const mapStateToProps = (state) => ({
    allQuestions: state.allQuestions,
    menu: state.menu,
    homepage: state.homepage,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Connexion);