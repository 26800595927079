import './modalPin.scss'
import { PinField } from 'react-pin-field'
import { useState, useEffect } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { setAllQuestions, setMenu, setAnswers,  } from '../../actions/action'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'



const PINModal = ( {idQuestionnaire, idUser, languageDefault, languageSelected, setAnswersForBack, findIdText, setModalPin, goToAsks, createNotification}) => {

    const [reject, setReject] = useState(false)
    const [codePin, setCodePin] = useState('')
    const [pinError, setPinError] = useState(false)
    const [isGenerate, setIsGenerate] = useState(false)
    const token = Cookies.get('token')
    const [forgotPin, setForgotPin] = useState(false)
    const dispatch = useDispatch()
    const [displayAskNewPinButton, setDisplayAskNewPinButton] = useState(false)
    const navigate = useNavigate()
    const [personalCodePin, setPersonnalCodePin] = useState('')
    

    const verifAtNewAsk = (mail) => {
        const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (regexEmail.test(mail)) {
            setDisplayAskNewPinButton(true)
            return (true)
        } else {
            setDisplayAskNewPinButton(false)
        }
    }


    
    const verifPinInBack = () => {
        axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/validate_pin/${idQuestionnaire}/${idUser}`, {
                code: codePin
        }, {
            params: {
              languageSelected: languageSelected,
              languageDefault: languageDefault,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },})
        .then((response) => {
            Cookies.set('localToken', response.data.userSession)
            if(response.data.needCodeReset){
                setIsGenerate(true)

            } else {
                dispatch(setAllQuestions(response.data.content))
                dispatch(setMenu(response.data.content.menu))
                dispatch(setAnswers(response.data.answers))
                setAnswersForBack((prevAnswers) => ({
                    ...prevAnswers,
                    ...response.data.answers
                }));
                if(findIdText){
                    navigate(`${process.env.REACT_APP_FRONT_LINK}/${findIdText.text}/${idUser}/${response.data.content.menu[0].id}/${response.data.content.questions[0].pages[0].text.id}/0/${response.data.content.questions[0].pages[0].lots[0].sousquestions[0].id}`)
                } else {
                    navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${response.data.content.menu[0].id}/${response.data.content.questions[0].pages[0].text.id}/0/${response.data.content.questions[0].pages[0].lots[0].sousquestions[0].id}`)
                }
                setModalPin(false)
            }
        })
    .catch((error) => {setPinError(true)})
    .finally(() => {})
    
}

useEffect(() => {
    if(codePin.length === 4){
        verifPinInBack()
    }
}, [codePin])

const askNewPin = (bool) => {
    const mail = document.getElementById('mailNewInput')
    if(bool){
        // A FAIRE : Envoyer le mail au back pour récupérer le nouveau code pin
        axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/validate_pin/${idQuestionnaire}/${idUser}`, {
            mail: mail.value,
            code: null,
            codeReset: bool ? bool : false,
    }, {headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
            languageSelected: languageSelected,
            languageDefault: languageDefault,
          }})
    .then((response) => {
        Cookies.set('localToken', response.data.userSession)
        if(response.data.continue){
            goToAsks(codePin)
            if(!response.data.selectTheme){
                if(findIdText){
                    navigate(`${process.env.REACT_APP_FRONT_LINK}/menu/${findIdText.text}/${idUser}`)
                } else {
                    navigate(`${process.env.REACT_APP_FRONT_LINK}/menu/${idQuestionnaire}/${idUser}`)
                }
                setModalPin(false)
            }
            /* if(menu && menu[0]){
                navigate(`/${idQuestionnaire}/${idUser}/${menu[0].id}`)} */
            } else {
            createNotification('success', '', 'Un nouveau code PIN vient de vous être envoyé par mail, merci de la saisir', "Nouveau code PIN")
            setForgotPin(false)
            setIsGenerate(false)

        }
    })
    .catch((error) => {
        setPinError(true)
      })
    .finally(() => {})    
       setModalPin(true)
        setForgotPin(false)
        setReject(false)
        setDisplayAskNewPinButton(false)
    } else {
        setForgotPin(true)
    }
}

const changePersonnalCodePin = (event) => {
    if(event.length === 4){
        if(event === personalCodePin){
            // A FAIRE ICI : Envoyer au back le nouveau code PIN puis lancer la fonction goToAsk avec le nouveau code PIN
            createNotification('success', '', 'Vous allez être redirigé vers le questionnaire, merci de patienter', "En cours de traitement")
            axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/validate_pin/${idQuestionnaire}/${idUser}`, 
            {
                code: personalCodePin,
                codeReset: true,
                mail: null,
            },
            {
                params: {
                  languageSelected: languageSelected,
                  languageDefault: languageDefault,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                },}
            )
              .then((response) => {
                Cookies.set('localToken', response.data.userSession)
                if(response.data.selectTheme){
                    if(findIdText){
                        navigate(`${process.env.REACT_APP_FRONT_LINK}/menu/${findIdText.text}/${idUser}`)
                    } else {
                        navigate(`${process.env.REACT_APP_FRONT_LINK}/menu/${idQuestionnaire}/${idUser}`)
                    }
                } else {
                    dispatch(setAllQuestions(response.data.content))
            dispatch(setMenu(response.data.content.menu))
            dispatch(setAnswers(response.data.answers))
            setAnswersForBack((prevAnswers) => ({
                ...prevAnswers,
                ...response.data.answers
            }));
            if(findIdText){
                navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${response.data.content.menu[0].id}/${response.data.content.questions[0].pages[0].text.id}/0/${response.data.content.questions[0].pages[0].lots[0].sousquestions[0].id}`)
            } else {
                navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${response.data.content.menu[0].id}/${response.data.content.questions[0].pages[0].text.id}/0/${response.data.content.questions[0].pages[0].lots[0].sousquestions[0].id}`)
            }
            setModalPin(false)
                }})
              .catch((error) => {console.log(error)})
              .finally(() => {})

        } else {
            createNotification('warning', '', 'Les deux codes PIN ne correspondent pas, merci de réessayer', "Erreur : Codes PIN différents")
        }
    }
}

    return (
        <div className='modalPin__background'>
            <div className='modalPin__container'>
            {!forgotPin && !isGenerate ? 
            <div>
                <div>
                Merci d'indiquer votre code PIN
                </div>
                <div className='mobilePin__container'>
                <PinField 
                    length={4}
                    validate={/^[0-9]$/} 
                    onRejectKey={() => {setReject(true)}}
                    onChange={(event) => {setReject(false); setCodePin(event); setPinError(false)}}
                    type= {'password'}
                    className={reject || pinError ? "mobilePin mobilePin__reject" : "mobilePin"} />
                    </div>
                
                {reject ? <div className='mobilePin__error'>Seuls les chiffres sont autorisés</div> : null}
                {pinError ? 
                <div>
                    <div className='mobilePin__error'>Votre code PIN est erroné, merci de réessayer.</div>
                    <div className='mobilePin__error'>Vous avez oublié votre mot de passe ? <span className='mobilePin__error__link' onClick={() => {setForgotPin(true); setPinError(false); setReject(false)}}>Cliquez ici</span></div> 
                </div>
                : null}
                </div>
                : null }
            {forgotPin ? 
            <div className='mobilePin__newPin'>  
                <div>
                    Merci d'indiquer votre adresse mail :
                </div>

                <input id='mailNewInput' className='mobilePin__input' onChange={(event) => {verifAtNewAsk(event.target.value)}}></input>
                {displayAskNewPinButton ? <button className="mobilePin__button" onClick={() => {askNewPin(true); setPinError(false); setReject(false)}}>
                    Générer un nouveau code PIN
                </button> : null }
                
            
            </div> : null}

            {isGenerate ? 
            <div className='mobilePin__newPin'>  
                Merci de modifier votre code Pin
                <div className='mobilePin__container'>
                <PinField
                    length={4}
                    validate={/^[0-9]$/} 
                    onRejectKey={() => {setReject(true)}}
                    onChange={(event) => {setReject(false); setPersonnalCodePin(event)}}
                    type= {'password'}
                    className={reject || pinError ? "mobilePin mobilePin__reject" : "mobilePin"}  />
                </div>Merci de le confirmer
                <div className='mobilePin__container'>
                <PinField 
                    length={4}
                    validate={/^[0-9]$/} 
                    onRejectKey={() => {setReject(true)}}
                    onChange={(event) => {changePersonnalCodePin(event)}}
                    type= {'password'}
                    className={reject || pinError ? "mobilePin mobilePin__reject" : "mobilePin"}  />
                    </div>
            </div> : null}
                
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    allQuestions: state.allQuestions,
    menu: state.menu,
    homepage: state.homepage,
    idQuestionnaire: state.idQuestionnaire,
    idUser: state.idUser,
    languageDefault: state.languageDefault,
    languageSelected: state.languageSelected,

});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PINModal);