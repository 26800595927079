import './card.scss'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import MobileResponseButton from '../../Responses/button'
import MobileResponseSelect from '../../Responses/select'
import MobileResponseInput from '../../Responses/input'
import MobileResponseSlide from '../../Responses/slide'
import MobileResponseMultiple from '../../Responses/multiple'
import Download from '../../../assets/img/uIco.png'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { setTableDisplay, setModif } from '../../../actions/action'



const MobileCard = ({lot, lotIndex, subQuestionIds, page, question, pageIndex, answers, setAnswersForBack, saveNewAnswers, setSaveNewAnswers, setAnswerRequired, answersForBack, allQuestions, indexQuestion, sendResponses, ObjetIndex, idMenu, idQuestion, idUser, idQuestionnaire, idPage,createNotification, docForBack, setDocForBack, setSum, idCorrespond, createSaveNewAnswers, fonction, tableDisplay}) => {
    const navigate = useNavigate()
    const indexQuestionAll = subQuestionIds.findIndex(obj => obj == idQuestion)
    const dispatch = useDispatch()
    const findIdText = idCorrespond.find(obj => obj.id == idQuestionnaire)
    let sousquestionForNext = ''  
    const updateAnswers = (responseId, responseValue, responseText, sousquestionId) => {
        setAnswersForBack(prevState => {
            const updateAnswers =  {
                textResponse: responseText,
                response: responseId,
                value: responseValue,
            };
            const updateCategory = {...prevState[question.category.id], [sousquestionId]: updateAnswers}
            const updateState = {...prevState, [question.category.id]: updateCategory, modif: true}
            return updateState;
            })
        }

    const findNextNonOtherQuestionId =(pageId, currentLotIndex) => {
        const pages = question.pages;
        const currentPageIndex = pages.findIndex(page => page.text.id == pageId);
        const currentMenu = allQuestions.questions.find(question => question.category.id == idMenu)
        const currentMenuIndex = allQuestions.questions.findIndex(question => question.category.id == idMenu)
        const nextMenu = allQuestions.questions[parseInt(currentMenuIndex+1)] ? allQuestions.questions.find(question => question.category.id == allQuestions.questions[parseInt(currentMenuIndex+1)].category.id) : null
        const nextMenuId = allQuestions.questions[currentMenuIndex+1] ? allQuestions.questions[currentMenuIndex+1].category.id : null
        const nextPage = currentMenu.pages[currentPageIndex+1]
        const nextPageId = currentMenu.pages[currentPageIndex+1] ? currentMenu.pages[currentPageIndex+1].text.id : null
        const nextLot = page.lots[parseInt(currentLotIndex+1)] ? page.lots[parseInt(currentLotIndex+1)] : null
        const nextLotIndex = page.lots[parseInt(currentLotIndex+1)] ? parseInt(currentLotIndex+1) : 0

        let link = ''
        let canNavigue = true;
            tableDisplay.forEach((underQuestion) => {
                const sousquestion = lot.sousquestions.find(obj => obj.id == underQuestion)
            if(sousquestion.required){
                    if(saveNewAnswers
                        && saveNewAnswers[question.category.id]
                        && saveNewAnswers[question.category.id][sousquestion.id]
                        && (saveNewAnswers[question.category.id][sousquestion.id].textAnswer || saveNewAnswers[question.category.id][sousquestion.id].selectedAnswer)){
                            if(page.lots[parseInt(currentLotIndex+1)]){
                               link = `${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${idMenu}/${page.text.id}/${nextLotIndex}/${nextLot.sousquestions[0].id}`
                            } else if (currentMenu.pages[parseInt(currentPageIndex+1)]){
                                link = `${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${idMenu}/${nextPageId}/${nextLotIndex}/${nextPage.lots[0].sousquestions[0].id}`
                            } else if (allQuestions.questions[currentMenuIndex+1]){
                                if(question.needRapport){
                                    link = `${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${idMenu}/${idPage}/0/${idQuestion}/profil`
                                } else {
                                   link = `${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${nextMenuId}/${nextMenu.pages[0].text.id}/0/${nextMenu.pages[0].lots[0].sousquestions[0].id}`
                                }
                            } else {
                                link = `${process.env.REACT_APP_FRONT_LINK}/rapport/${idQuestionnaire}/${idUser}`
                            }
                        } else {
                                canNavigue= false
                                createNotification('warning', '', `La réponse à la question "${sousquestion.asks}" est obligatoire`, 'Question Obligatoire')
                            } 
                } else {
                    if(page.lots[parseInt(currentLotIndex+1)]){
                        link = `${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${idMenu}/${page.text.id}/${nextLotIndex}/${nextLot.sousquestions[0].id}`
                     } else if (currentMenu.pages[parseInt(currentPageIndex+1)]){
                         link = `${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${idMenu}/${nextPageId}/${nextLotIndex}/${nextPage.lots[0].sousquestions[0].id}`
                     } else {
                         if(question.needRapport){
                             link = `${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${idMenu}/${idPage}/0/${idQuestion}/profil`
                         } else {
                             link = `${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${nextMenuId}/${nextMenu.pages[0].text.id}/0/${nextMenu.pages[0].lots[0].sousquestions[0].id}`
                         }
                     }
                }
            })
      if(canNavigue){
         navigate(link)
         sendResponses(false)
      }
    }

    const lastQuestion = () => {
        navigate(-1)
    }

    const sendToAnswersForBack = (event) => {
        setDocForBack(prevState => {        
            const updatedState = {
                  doc: event.target.files[0], 
                }
                if (prevState === null || typeof prevState === 'undefined') {
                    // Créer un nouvel objet s'il n'existe pas encore
                    return {
                        [idQuestion]: updatedState,
                      }
                  } else {
                    // Remplacer l'objet existant par le nouvel objet
                    return {
                      ...prevState,
                        [idQuestion]: updatedState,
                      }
                    };
                  })
                };

    const changeTotal = () => {
        const total = Object.values(saveNewAnswers[idMenu] || {}).reduce((acc, curr) => acc + curr.answerValue, 0);
        setSum(prevState => {
            const updateState = {...prevState, [idMenu]: total}
        return updateState })

        setAnswersForBack(prevState => {
            const updateTotal = {...prevState[idMenu], totalPoints: total}
            const updateAnswers = {...prevState, [idMenu]: updateTotal }
            return updateAnswers
        })
    }

    useEffect(() => {
        changeTotal()
     },[saveNewAnswers])

     const recursiveUpdate = (sousquestion) => {
         if (Array.isArray(sousquestion.othersQuestionsId)) {
          sousquestion.othersQuestionsId.forEach((otherQuestionId, index) => {
          const childSousquestion = lot.sousquestions.find(obj => obj.id === otherQuestionId);
            if (
              saveNewAnswers &&
              saveNewAnswers[question.category.id] &&
              saveNewAnswers[question.category.id][sousquestion.id] &&
              saveNewAnswers[question.category.id][sousquestion.id].selectedAnswer &&
              childSousquestion &&
              childSousquestion.indexAnswerForQuestion && childSousquestion.indexAnswerForQuestion != saveNewAnswers[question.category.id][sousquestion.id].selectedAnswer
            ) {                
                if (Array.isArray(childSousquestion.othersQuestionsId)) {
                    setAnswersForBack(prevState => {
                        const updateAnswers = { ...prevState[question.category.id][childSousquestion.id],
                            response: null,
                            value: null,
                            textResponse: '',
                        }
                        const updateCategory = {...prevState[question.category.id], [childSousquestion.id]: updateAnswers}
                        const updatedState = {...prevState, [question.category.id]: updateCategory}
                        return updatedState
                    })
                    childSousquestion.othersQuestionsId.forEach((child, index) => {
                        setAnswersForBack(prevState => {
                            const updateAnswers = { ...prevState[question.category.id][child],
                                response: null,
                                value: null,
                                textResponse: '',
                            }
                            const updateCategory = {...prevState[question.category.id], [child]: updateAnswers}
                            const updatedState = {...prevState, [question.category.id]: updateCategory}
                            return updatedState
                        })
                       recursiveUpdate(child)
                    })
              } else {

            setAnswersForBack(prevState => {
                const updateAnswers = { ...prevState[question.category.id][childSousquestion.id],
                    response: null,
                    value: null,
                    textResponse: '',
                }
                const updateCategory = {...prevState[question.category.id], [childSousquestion.id]: updateAnswers}
                const updatedState = {...prevState, [question.category.id]: updateCategory}
                return updatedState
            })}
            }
            
        });
        } 
    }

    const recursiveDisplay = (sousquestion) => {
        function verifParent(data) {
            const parent = lot.sousquestions.find(obj => obj.id == data.idForOtherQuestion)
             if(!data.indexAnswerForQuestion ||
                (saveNewAnswers 
                && saveNewAnswers[question.category.id]
                && saveNewAnswers[question.category.id][parent.id]
                && data.indexAnswerForQuestion == saveNewAnswers[question.category.id][parent.id].selectedAnswer)){
                    if(parent.idForOtherQuestion){
                        verifParent(parent)
                    } else {
                        changeInTable(true, sousquestion)
                    }
                } else {
                    changeInTable(false, sousquestion)
                    if(answersForBack && answersForBack[question.category.id] && answersForBack[question.category.id][sousquestion.id]){
                        delete answersForBack[question.category.id][sousquestion.id]
                    }
                    if (saveNewAnswers && saveNewAnswers[question.category.id] && saveNewAnswers[question.category.id][sousquestion.id]) {
                        const updatedSaveNewAnswers = {...saveNewAnswers}
                        if (updatedSaveNewAnswers[question.category.id] && updatedSaveNewAnswers[question.category.id][sousquestion.id]) {
                            updatedSaveNewAnswers[question.category.id][sousquestion.id].answerValue = 0;
                            updatedSaveNewAnswers[question.category.id][sousquestion.id].selectedAnswer = ''
                            updatedSaveNewAnswers[question.category.id][sousquestion.id].textAnswer = null
                            updatedSaveNewAnswers[question.category.id][sousquestion.id].textRapport = ''
                        }
                    }
                }
        }
        
        if(!sousquestion.idForOtherQuestion){
            changeInTable(true, sousquestion)
        } else {
            verifParent(sousquestion)
        }
    }

        
        const changeInTable = (bool, sousquestion) => {
            const index = tableDisplay.findIndex(obj => obj == sousquestion.id)
    
            if(bool){
                if (index === -1){
                    dispatch(setTableDisplay([...tableDisplay, sousquestion.id]));
                }
            } else {
                if(index !== -1){
                    const updatedTableDisplay = tableDisplay.filter(id => id !== sousquestion.id);
                    dispatch(setTableDisplay(updatedTableDisplay));
                }
            }
        }

    useEffect(() => {
        lot.sousquestions.forEach((obj) => {
            if(saveNewAnswers && saveNewAnswers[question.category.id] && !saveNewAnswers[question.category.id][obj.id]){
                createSaveNewAnswers(obj)
            } 
        })
    }, [lot, saveNewAnswers])

    const isLast = () => {
        const currentMenu = allQuestions.questions.find(question => question.category.id == idMenu)
        const currentMenuIndex = allQuestions.questions.findIndex(question => question.category.id == idMenu)
        const currentPageIndex = currentMenu.pages.findIndex(page => page.text.id == idPage)
        const currentPage = currentMenu.pages.find(page => page.text.id == idPage)
        if(allQuestions.questions.length-1 == currentMenuIndex){
            if(currentMenu.pages.length-1 == currentPageIndex){
                if(lotIndex == currentPage.lots.length-1)
                    return true
            }

        }
    }
    
    return (
        <div className='mobile__page__question__sousquestion'>
            {lot.text.title}
            {lot.sousquestions.map((sousquestion,sousquestionIndex) => {
                recursiveDisplay(sousquestion, sousquestionIndex)
                const isQuestionInTable = tableDisplay.includes(parseInt(sousquestion.id))
                sousquestionForNext = sousquestion
            return (
                isQuestionInTable ? 
                <div className='question__card' key={sousquestionIndex}>
                    <div className='question__card__container'>
                    <div className='question__card__part'>
                        {sousquestion.asks}
                    </div>
                    {sousquestion.askTypes === 'textarea' ?
                    <div className='question__card__part'>
                        <MobileResponseInput lot={lot} answersForBack={answersForBack} setAnswerRequired={setAnswerRequired} updateAnswers={updateAnswers} saveNewAnswers={saveNewAnswers} setSaveNewAnswers={setSaveNewAnswers} sousquestion={sousquestion} page={page} lotIndex={lotIndex} sousquestionIndex={ObjetIndex} question={question} /> 
                    </div> : sousquestion.askTypes === 'select' ? 
                    <div className='question__card__part'>
                        <MobileResponseSelect recursiveUpdate={recursiveUpdate} question={question} saveNewAnswers={saveNewAnswers} setSaveNewAnswers={setSaveNewAnswers} sousquestion={sousquestion} page={page} sousquestionIndex={ObjetIndex} updateAnswers={updateAnswers} setAnswerRequired={setAnswerRequired} answersForBack={answersForBack} /> 
                    </div> 
                    : sousquestion.askTypes === 'button' ? 
                    <div className='responses__button__container'>
                        <MobileResponseButton setSaveNewAnswers={setSaveNewAnswers} sousquestion={sousquestion} page={page} lotIndex={lotIndex} sousquestionIndex={ObjetIndex} updateAnswers={updateAnswers} answersForBack={answersForBack} saveNewAnswers={saveNewAnswers} /> 
                    </div> 
                    : sousquestion.askTypes === 'slide' ?
                    <div className='question__card__part'>
                        <MobileResponseSlide answersForBack={answersForBack} sousquestion={sousquestion} page={page} lotIndex={lotIndex} sousquestionIndex={ObjetIndex} setSaveNewAnswers={setSaveNewAnswers} updateAnswers={updateAnswers} answers={answers} /> 
                    </div> 
                    : sousquestion.askTypes === 'multiple-choice' ?
                    <div className='question__card__part'>
                        <div className='responses__button__container'>
                            <MobileResponseMultiple setAnswersForBack={setAnswersForBack} question={question} saveNewAnswers={saveNewAnswers} answersForBack={answersForBack} sousquestion={sousquestion} page={page} lotIndex={lotIndex} sousquestionIndex={ObjetIndex} setSaveNewAnswers={setSaveNewAnswers} updateAnswers={updateAnswers} answers={answers} /> 
                        </div>
                    </div> 
                    : null}
                    
                    {sousquestion.acceptFiles ?
                    <div className='question__download'>
                        <form method="post" enctype="multipart/form-data" className='file'>
                            <label><img src={Download} className='icone'/>
                                <input type='file' id='file_upload' name='file_upload' style={{ display: 'none' }} onChange={(event) => {sendToAnswersForBack(event); dispatch(setModif(true))}} accept= '.jpg, .jpeg, .png, .pdf, .bmp'/> 
                            </label>
                        </form>
                            <div className='file__name'>{docForBack && docForBack[sousquestion.id] && docForBack[sousquestion.id].doc.name ? docForBack[sousquestion.id].doc.name : null} </div>
                    </div>
                    : null }
                    </div>
                </div>
            : null
            )
            })}
                    <div className='question__next__container'>
                        {page.lots[lotIndex].sousquestions[ObjetIndex-1] || question.pages[pageIndex-1] || page.lots[lotIndex-1] ? <div className='question__next question__next__reverse' onClick={() => lastQuestion(page)} /> : <div className='question__next question__next__hidden' />}
                        
                        {!isLast() ? <div className='question__next' onClick={() => {findNextNonOtherQuestionId(idPage, lotIndex, idQuestion, sousquestionForNext, ObjetIndex)}} /> : null }
                    </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    answers: state.answers,
    idQuestion: state.idQuestion,
    idQuestionnaire: state.idQuestionnaire,
    idUser: state.idUser,
    idPage: state.idPage,
    allQuestions: state.allQuestions,
    idCorrespond: state.idCorrespond,
    fonction: state.fonction,
    firstname: state.firstname,
    tableDisplay: state.tableDisplay,

  });
  const mapDispatchToProps = {
    };
    
  export default connect(mapStateToProps, mapDispatchToProps)(MobileCard);