import { Card } from 'react-bootstrap'
import './profil.scss'
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import TextComponent from '../Page/TextComponent'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {setStep, setDisplayUnderMenu, setSummaryScore } from '../../actions/action'
import Gaug from '../Graph/Radar/Gaug Ring'
import axios from 'axios'
import { Checkbox } from '@mui/material'
import Cookies from 'js-cookie'
import { Puff } from 'react-loader-spinner'
import Recap from './recap'
import Recommandations from './recommandations'

const Rapport = ({sum, saveNewAnswers, setProfilForEnd, profilForEnd, menu, idQuestionnaire, idCorrespond, indexQuestion, allQuestions, languageDefault, languageSelected, createNotification, summaryScore}) => {
    const [ profil, setProfil ] = useState();
    const [ displayReco, setDisplayReco ] = useState(false) 
    const { menuId } = useParams()
    const { idUser } = useParams()
    const currentIndex = menu.findIndex(m => m.id == menuId)
    const nextIndex = currentIndex + 1
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const findIdText = idCorrespond.find(obj => obj.id == idQuestionnaire)
    const localToken = Cookies.get('localToken')
    const token = Cookies.get('token')
    const [data, setData] = useState([])
    const [reco, setReco] = useState()
    const [showCheckboxColumn, setShowCheckboxColumn] = useState([])


    const question = allQuestions.questions.find(obj => obj.category.id == menuId)

    const getData = () => {
        axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/component/get_report/${menuId}/${idUser}?languageDefault=${languageDefault}&languageSelected=${languageSelected}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              UserSession: localToken,
            },
          })
          .then((response) => {
            response.data.content.showCheckboxReco ? setShowCheckboxColumn(response.data.content.showCheckboxReco) : setShowCheckboxColumn(response.data.content.profils.showCheckboxColumn)
            response.data.content.data ? setData(response.data.content.data) : setData(response.data.content)
            setReco(response.data.content.recommandations)
            dispatch(setSummaryScore(response.data.content.summaryScore))
        })
          .catch((error) => {
              console.log(error)
            if(error.response.data.goToNextChapter){
                goNextChapter()
            }
          })
            } 
    
    useEffect(() => {
        getData()
    }, [])
    
    const createProfilObject = () => {
        if(saveNewAnswers && saveNewAnswers[menuId]){
            Object.entries(saveNewAnswers[menuId]).forEach((ask) => {
            if(ask[1].textQuestion){
                setProfil(prevState => {
                    const updateObject = {
                        response:  ask[1].textAnswer,
                        question:  ask[1].textQuestion,
                        recommand: ask[1].textRapport,
                    }
                    const updateState = {...prevState, [ask[0]]: updateObject}
                    return updateState
                })
            }
        })
        }        
    }

    const goNextChapter = () => {
        const nextMenu = parseInt(allQuestions.questions.findIndex((obj) => obj.category.id == menuId)+1)
        
            if(menu && menu[nextIndex]) {
                if(findIdText){
                    navigate(`${process.env.REACT_APP_FRONT_LINK}/${findIdText.text}/${idUser}/${menu[nextIndex].id}/${allQuestions.questions[nextMenu].pages[0].text.id}/0/${allQuestions.questions[nextMenu].pages[0].lots[0].sousquestions[0].id}`)
                } else {
                    navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${idUser}/${menu[nextIndex].id}/${allQuestions.questions[nextMenu].pages[0].text.id}/0/${allQuestions.questions[nextMenu].pages[0].lots[0].sousquestions[0].id}`)
                }
            }
    }
    
    useEffect(() => {
        createProfilObject()
    }, [saveNewAnswers])

    const addReco = (reco, event) => {
        const url = `${process.env.REACT_APP_BACK_URL}/questionnaire/engage_recommendation/${reco.idQuestion}/${idUser}`;
        const data = {
            id: reco.idReco,
            check: event.target.checked,
        };
    
        const headers = {
            Authorization: `Bearer ${token}`,
            UserSession: localToken,
        };
    
        axios.post(url, data, { headers, params: {
            languageSelected: languageSelected,
            languageDefault: languageDefault,
          }  })
            .then(response => {
                if(response.status == 200){
                    createNotification('warning', '', response.data.text, response.data.title)
                } else if (response.status == 202){
                    createNotification('success', '', response.data.text, response.data.title)
                }
            })
            .catch(error => {
                // Gérez les erreurs si la requête échoue
                console.error('Erreur lors de l\'envoi des données au backend:', error);
            });
    };

    return (
        data ? 
        <div className="rapportChapt">
            {data[0] ? <Gaug dataBack={data} createNotification={createNotification} /> : null}
            {question.profil.map((profil, profilIndex) => {
                if (profil) {
                    if(!profilForEnd[question.category.id]){
                        setProfilForEnd(prevState => {
                        const updateProfil = {
                            title: profil.title,
                            text: profil.text,
                        }

                        const updateState = {...prevState, [question.category.id]: updateProfil}
                        return updateState
                    })
                    }
                    
                    return(
                        <div>
                            <div className="questions__swipes" onClick={() => {goNextChapter()}}></div>
                        {sum[question.category.id] >= profil.necessaryPoints[0] && sum[question.category.id] <= profil.necessaryPoints[1] ?
                            <div className='questions__card rapportChapt__conseils'>  
                                <Card.Title className='rapportChapt__card__title'>{profil.title}</Card.Title>
                                <Card.Text className='rapportChapt__card__text'><TextComponent text={profil.text} /></Card.Text>
                            </div>
                        : null }
                        </div>
                    )
                }
            })}
        {summaryScore && summaryScore.data ? 
        <Recap header={summaryScore.header} data={summaryScore.data} />
        : null}
        {reco ?
        reco.map((r) => {
            if(r.idChapitre == menuId){
                return(
                    <Recommandations showCheckboxColumn={showCheckboxColumn} data={r} addReco={addReco} /> 
                )
        }
    })
    : null }
    </div>
    : <div className="loader">
    <Puff 
        visible={true}
        color="rgb(218,91,21)"
        height=""
        width=""
        />
</div>
    )
}

const mapStateToProps = (state) => ({
    menu: state.menu,
    allQuestions: state.allQuestions,
    languageSelected: state.languageSelected,
    languageDefault: state.languageDefault,
    currentPath: state.currentPath,
    idQuestionnaire: state.idQuestionnaire,
    idCorrespond: state.idCorrespond,
    summaryScore: state.summaryScore,
    });
  const mapDispatchToProps = dispatch =>({
    });
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(Rapport);