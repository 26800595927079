import { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import Question from '../Question/question'
import { connect, useDispatch} from "react-redux";
import { setTableDisplay } from '../../../actions/action';

const CardGlobal = ( {indexPage, sum, indexLot, lot, saveNewAnswers, setSaveNewAnswers, question, answersForBack, setAnswersForBack, setSum, docForBack, setDocForBack, setAnswerRequired, answerRequired, idMenu, tableDisplay, swipePlus, idPage, idQuestion, sendResponses, menu} ) => {

    const dispatch = useDispatch()
    let sousquestionForNext = ''

    const changeTotal = () => {
        const total = Object.values(saveNewAnswers[question.category.id] || {}).reduce((acc, curr) => acc + curr.answerValue, 0);
        setSum(prevState => {
           const updateState = {...prevState, [question.category.id]: total}
        return updateState })

        setAnswersForBack(prevState => {
            const updateTotal = {...prevState[question.category.id], totalPoints: total}
            const updateAnswers = {...prevState, [question.category.id]: updateTotal }
            return updateAnswers
        })
    }

        useEffect(() => {
           changeTotal()
        },[saveNewAnswers])

        useEffect(() => {
            if (!saveNewAnswers[idMenu] && idMenu) {
                setSaveNewAnswers(prevState => ({
                    ...prevState,[idMenu]: {}}));
                }
        }, [idMenu])


    // parent direct => parent le plus éloigné
    const recursiveDisplay = (sousquestion) => {
        function verifParent(data) {
            const parent = lot.sousquestions.find(obj => obj.id == data.idForOtherQuestion)
             if(!data.indexAnswerForQuestion ||
                (saveNewAnswers 
                && saveNewAnswers[question.category.id]
                && saveNewAnswers[question.category.id][parent.id]
                && data.indexAnswerForQuestion == saveNewAnswers[question.category.id][parent.id].selectedAnswer)){
                    if(parent.idForOtherQuestion){
                        verifParent(parent)
                    } else {
                        changeInTable(true, sousquestion)
                    }
                } else {
                    changeInTable(false, sousquestion)
                    if(answersForBack && answersForBack[question.category.id] && answersForBack[question.category.id][sousquestion.id]){
                        delete answersForBack[question.category.id][sousquestion.id]
                    }
                    if (saveNewAnswers && saveNewAnswers[question.category.id] && saveNewAnswers[question.category.id][sousquestion.id]) {
                        const updatedSaveNewAnswers = {...saveNewAnswers}
                        if (updatedSaveNewAnswers[question.category.id] && updatedSaveNewAnswers[question.category.id][sousquestion.id]) {
                            updatedSaveNewAnswers[question.category.id][sousquestion.id].answerValue = 0;
                            updatedSaveNewAnswers[question.category.id][sousquestion.id].selectedAnswer = ''
                            updatedSaveNewAnswers[question.category.id][sousquestion.id].textAnswer = null
                            updatedSaveNewAnswers[question.category.id][sousquestion.id].textRapport = ''
                        }
                    }
                }
        }
        
        if(!sousquestion.idForOtherQuestion){
            changeInTable(true, sousquestion)
        } else {
            verifParent(sousquestion)
        }
    }

        
        const changeInTable = (bool, sousquestion) => {
            const index = tableDisplay.findIndex(obj => obj == sousquestion.id)
    
            if(bool){
                if (index === -1){
                    dispatch(setTableDisplay([...tableDisplay, sousquestion.id]));
                }
            } else {
                if(index !== -1){
                    const updatedTableDisplay = tableDisplay.filter(id => id !== sousquestion.id);
                    dispatch(setTableDisplay(updatedTableDisplay));
                }
            }
        }

    const recursiveUpdate = (sousquestion) => {
        if (Array.isArray(sousquestion.othersQuestionsId)) {
          sousquestion.othersQuestionsId.forEach((otherQuestionId, index) => {
          const childSousquestion = lot.sousquestions.find(obj => obj.id === otherQuestionId);
            if (
              saveNewAnswers &&
              saveNewAnswers[question.category.id] &&
              saveNewAnswers[question.category.id][sousquestion.id] &&
              saveNewAnswers[question.category.id][sousquestion.id].selectedAnswer &&
              childSousquestion &&
              childSousquestion.indexAnswerForQuestion && childSousquestion.indexAnswerForQuestion != saveNewAnswers[question.category.id][sousquestion.id].selectedAnswer
            ) {                
                if (Array.isArray(childSousquestion.othersQuestionsId)) {
                    setAnswersForBack(prevState => {
                        const updateAnswers = { ...prevState[question.category.id][childSousquestion.id],
                            response: null,
                            value: null,
                            textResponse: '',
                        }
                        const updateCategory = {...prevState[question.category.id], [childSousquestion.id]: updateAnswers}
                        const updatedState = {...prevState, [question.category.id]: updateCategory}
                        return updatedState
                    })
                    childSousquestion.othersQuestionsId.forEach((child, index) => {
                        setAnswersForBack(prevState => {
                            const updateAnswers = { ...prevState[question.category.id][child],
                                response: null,
                                value: null,
                                textResponse: '',
                            }
                            const updateCategory = {...prevState[question.category.id], [child]: updateAnswers}
                            const updatedState = {...prevState, [question.category.id]: updateCategory}
                            return updatedState
                        })
                        recursiveUpdate(child)
                    })
              } else {

            setAnswersForBack(prevState => {
                const updateAnswers = { ...prevState[question.category.id][childSousquestion.id],
                    response: null,
                    value: null,
                    textResponse: '',
                }
                const updateCategory = {...prevState[question.category.id], [childSousquestion.id]: updateAnswers}
                const updatedState = {...prevState, [question.category.id]: updateCategory}
                return updatedState
            })}
            }
            
        });
        }
    }

    const recursiveUpdateSaveNewAnswers = (sousquestion) => {
            if (Array.isArray(sousquestion.othersQuestionsId)) {
              sousquestion.othersQuestionsId.forEach((otherQuestionId, index) => {
              const childSousquestion = lot.sousquestions.find(obj => obj.id === otherQuestionId);
                
                if (
                  saveNewAnswers &&
                  saveNewAnswers[question.category.id] &&
                  saveNewAnswers[question.category.id][otherQuestionId] &&
                  saveNewAnswers[question.category.id][otherQuestionId].selectedAnswer &&
                  childSousquestion &&
                  childSousquestion.indexAnswerForQuestion !== saveNewAnswers[question.category.id][otherQuestionId].selectedAnswer
                ) {                
                    if (Array.isArray(childSousquestion.othersQuestionsId)) {
                        childSousquestion.othersQuestionsId.forEach((child, index) => {
                            recursiveUpdate(childSousquestion); // Appel récursif pour les sous-questions
                            setSaveNewAnswers(prevState => {
                                const updatedQuestion = { ...prevState[question.category.id][child],
                                selectedAnswer: null,
                                answerValue: null,
                                textAnswer: null,
                                textRapport: null,
                                }
                                const updateCategory = {...prevState[question.category.id], [child]: updatedQuestion}
                                const updatedState = {...prevState, [question.category.id]: updateCategory}
                                return updatedState;
                            });
                        })
                        setSaveNewAnswers(prevState => {
                            const updatedQuestion = { ...prevState[question.category.id][childSousquestion.id],
                            selectedAnswer: null,
                            answerValue: null,
                            textAnswer: null,
                            textRapport: null,
                            }
                            const updateCategory = {...prevState[question.category.id], [childSousquestion.id]: updatedQuestion}
                            const updatedState = {...prevState, [question.category.id]: updateCategory}
                            return updatedState;
                        });
    
                        setSaveNewAnswers(prevState => {
                            const updatedQuestion = { ...prevState[question.category.id][otherQuestionId],
                            selectedAnswer: null,
                            answerValue: null,
                            textAnswer: null,
                            textRapport: null,
                            }
                            const updateCategory = {...prevState[question.category.id], [otherQuestionId]: updatedQuestion}
                            const updatedState = {...prevState, [question.category.id]: updateCategory}
                            return updatedState;
                        });
                  }
                }
              });
            }
    }
    
    return (
        <div key={indexLot} className='questions__card'>
            <Card.Title className='questions__card__title'>{lot.text.title}</Card.Title>
            
            {lot.sousquestions && lot.sousquestions.length > 0 && lot.sousquestions.map((sousquestion, indexSousQuestion) => { 
                recursiveDisplay(sousquestion, indexSousQuestion)
                sousquestionForNext = sousquestion
                const isQuestionInTable = tableDisplay.includes(parseInt(sousquestion.id))
                return (
                    <div key={indexSousQuestion}>
                       <div className='grid'>
                    {isQuestionInTable ? <Question recursiveUpdateSaveNewAnswers={recursiveUpdateSaveNewAnswers} answersForBack={answersForBack} recursiveUpdate={recursiveUpdate} setAnswerRequired={setAnswerRequired} docForBack={docForBack} setDocForBack={setDocForBack} setAnswersForBack={setAnswersForBack} sum={sum} sousquestion={sousquestion} lot={lot} indexSousQuestion={indexSousQuestion} saveNewAnswers={saveNewAnswers} setSaveNewAnswers={setSaveNewAnswers} question={question} setSum={setSum} /> : null}                    
                </div>
            </div>)
            })}
        </div>
    )
        }

const mapStateToProps = (state) => ({
    languageSelected: state.languageSelected,
idMenu: state.idMenu,
tableDisplay: state.tableDisplay,
idPage: state.idPage,
idQuestion: state.idQuestion, 
menu: state.menu});
  const mapDispatchToProps = dispatch => ({
  });
  


export default connect(mapStateToProps, mapDispatchToProps)(CardGlobal);