import { Slider } from "@mui/material"
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setModif } from "../../../actions/action";

const ReponseSlide = ( { sousquestion, setSaveNewAnswers, answers, question, updateAnswers,setDisplayQuestion, setAnswerRequired, lot,recursiveUpdate }) => {
    const dispatch = useDispatch()
    let questionValue = 0;

    if (sousquestion.pointsValues) {
       questionValue = sousquestion.values[sousquestion.pointsValue]
    }  

    let valueSlide = 0;
    if (answers[question.category.id] && answers[question.category.id][sousquestion.id]) {
        valueSlide = parseInt(answers[question.category.id][sousquestion.id].textResponse)
    }

    const changeSelectValue = (event, newValue) => {
      recursiveUpdate(sousquestion)
      setAnswerRequired(true)
        const pointsAttribués = Math.ceil((newValue - parseInt(sousquestion.options[0]))/(parseInt(sousquestion.options[1]) - parseInt(sousquestion.options[0])) * sousquestion.pointsValue);
        setSaveNewAnswers(prevState => {
        const updatedQuestion = {
          ...prevState[question.category.id][sousquestion.id], 
            selectedAnswer: null,
            answerValue: pointsAttribués,
            textAnswer: event.target.value,
            textRapport: null,
          }
            
          const updatedCategory = {...prevState[question.category.id], [sousquestion.id]: updatedQuestion}
          const updatedState = {...prevState, [question.category.id]: updatedCategory}
          return updatedState;
        });
        updateAnswers(null, pointsAttribués, event.target.value)
        dispatch(setModif(true))
          }
        
    return (
        <div className='slider__container'>
        <p className='slider__text'>{sousquestion.options[0]}</p>
        <Slider
            onChange={changeSelectValue}
            valueLabelDisplay="auto"
            defaultValue={valueSlide}
            min={parseInt(sousquestion.options[0])}
            className='questions__card__slide'
            marks={sousquestion.isGraduated ? true : false}
            step={sousquestion.graduation ? sousquestion.graduation : 1}
            max={parseInt(sousquestion.options[1])} />
        <p className='slider__text'>{sousquestion.options[1]}</p>
        </div>
    )
}

export default ReponseSlide
