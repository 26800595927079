import './app.scss';
import Menu from '../Menu'
import Page from '../Page';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from '../Home';
import flowersFooter from '../../assets/img/valliance/bas_de_page.png'
import {useEffect, useState, useRef} from 'react'
import { connect, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom'
import { setPath, setLanguages, setLanguageSelected, setAllQuestions,setMenu, setNeedRapport, setAnswers, setIdUser, setStep, setHomepage, setModif, setNeedUnderMenu, setDisplayUnderMenu, setDisplayReportMenu, setThemeSelectionActive } from '../../actions/action';
import axios from "axios";
import EndRapport from '../EndRapport';
import AppWrapper from './appWrapper';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import MobileApp from '../../Mobile/App';
import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import MobilePage from '../../Mobile/Page';
import MobileProfil from '../../Mobile/Profil';
import MobileRapport from '../../Mobile/Rapport';
import FooterMobile from '../../Mobile/Footer';
import MenuChoice from '../MenuChoice';
import Rapport from '../Rapport';
import Cookies from 'js-cookie';
import ChangeTheme from '../../Mobile/ChangeTheme';


function App({menu,languages, languageSelected, allQuestions, languageDefault,  idQuestionnaire, idUser, modif, idQuestion, idMenu, idPage, indexLot, tokenStore, idCorrespond}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFirstRender = useRef(true);
  const [finalRapport, setFinalRapport] = useState({})
  const [languesDiv, setLanguesDiv] = useState(false)
  const [answersForBack, setAnswersForBack] = useState({})
  const [docForBack, setDocForBack] = useState()
  const [saveNewAnswers, setSaveNewAnswers ] = useState({})
  const location = useLocation();
  const currentPath = location.pathname.replace('/', '').toLowerCase().replace(/\s+/g, '_').replace('/', '');
  const langueNav = navigator.language;
  const [sum, setSum] = useState({});
  const [profilForEnd, setProfilForEnd] = useState({})
  const [needModal, setNeedModal] = useState(false)
  const token = Cookies.get('token')
  const [displayBeginButton, setDisplayBeginButton] = useState(false)
  const [subQuestionIds, setSubQuestionIds] = useState([])
  const rapportPresent = location.pathname.includes('rapport');
  const menuPresent = location.pathname.includes('menu')
  const footerState = rapportPresent || menuPresent ? false : true;
  const [modalPin, setModalPin] = useState(false)
  const [goodMail, setGoodMail] = useState(false)
  const [errorMessageForBadMail, setErrorMessageForBadMail] = useState('')
  const [displayChangeAnswers, setDisplayChangeAnswers] = useState(false)
  const [alertContainer, setAlertContainer] = useState()

  const findIdText = idCorrespond.find(obj => obj.id === idQuestionnaire)

  const verifAt = (mail) => {
    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (regexEmail.test(mail)) {
        setDisplayBeginButton(true)
        return (true)
    } else {
        setDisplayBeginButton(false)
    }
}

const login = (codePin) => {
    const mailInput = document.getElementById('mailInput')
    const mailValue = mailInput.value;
    setSaveNewAnswers({})

    axios.post(
      `${process.env.REACT_APP_BACK_URL}/questionnaire/submit_user`,
      {
        questionnaire: idQuestionnaire,
        email: mailValue,
        code: codePin,
      },
      {
        params: {
          languageSelected: languageSelected,
          languageDefault: languageDefault,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    
    .then((response) => {
      if(response.userSession){
        Cookies.set('localToken', response.userSession)
      }
      dispatch(setNeedUnderMenu(response.data.needUnderMenu))
      dispatch(setDisplayReportMenu(response.data.displayReportMenu))
      dispatch(setThemeSelectionActive(response.data.themeSelectionActive))
      if(!response.data.needUnderMenu){
        dispatch(setDisplayUnderMenu(false))
      }
      if(response.data.needPinCode){
        dispatch(setIdUser(response.data.idUser))
        setModalPin(true)
      } else {
        dispatch(setAnswers(JSON.parse(JSON.stringify(response.data.answers))))
        setAnswersForBack((prevAnswers) => ({
            ...prevAnswers,
            ...response.data.answers
          }));
          
        dispatch(setIdUser(response.data.idUser))
        if(isBrowser){
            if(response.data.needUnderMenu){
              if(findIdText){
              navigate(`${process.env.REACT_APP_FRONT_LINK}/menu/${findIdText.text}/${response.data.idUser}`)
            } else {
              navigate(`${process.env.REACT_APP_FRONT_LINK}/menu/${idQuestionnaire}/${response.data.idUser}`)
            }
            } else {
              navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${response.data.idUser}/${menu[0].id}/${allQuestions.questions[0].pages[0].text.id}/0/${allQuestions.questions[0].pages[0].lots[0].sousquestions[0].id}`)
            }
        } else {
          if(response.data.needUnderMenu){
            if(findIdText){
              navigate(`${process.env.REACT_APP_FRONT_LINK}/menu/${findIdText.text}/${response.data.idUser}/${menu[0].id}/${allQuestions.questions[0].pages[0].text.id}`)
            } else {
              navigate(`${process.env.REACT_APP_FRONT_LINK}/menu/${idQuestionnaire}/${response.data.idUser}/${menu[0].id}/${allQuestions.questions[0].pages[0].text.id}`) 
            }
          } else {
            if(findIdText){
              navigate(`${process.env.REACT_APP_FRONT_LINK}/${findIdText.text}/${response.data.idUser}/${menu[0].id}/${allQuestions.questions[0].pages[0].text.id}/0/${allQuestions.questions[0].pages[0].lots[0].sousquestions[0].id}`)
            } else {
              navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}/${response.data.idUser}/${menu[0].id}/${allQuestions.questions[0].pages[0].text.id}/0/${allQuestions.questions[0].pages[0].lots[0].sousquestions[0].id}`)
            }
          }       
        }
        
        if(response.status === 201){
          createNotification('success', '', 'Création d\'un nouvel utilisateur', 'Bienvenue sur AutoEval')
        } else if (response.status === 202) {
          createNotification('success', '', 'Récupération des précédentes réponses effectuées', 'Bon retour parmis nous')
        }
      }

      
    })
    .catch((error) => {
      if(error.response.status === 403) {
        if(error.response.data.emailNotAuthorized){
          setErrorMessageForBadMail(error.response.data.content)
          setGoodMail(true)
        } else {
          dispatch(setIdUser(error.response.data.idUser))
          
          
          axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/unlock_survey/${idQuestionnaire}/${error.response.data.idUser}?languageDefault=${languageDefault}&languageSelected=${languageSelected}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
              },
        })
        .then((responseAlert) => {
            setAlertContainer(responseAlert.data)
            setDisplayChangeAnswers(true)
        })
        .catch((error) => {
            console.log(error)
        })
          
        }
      }
      })
    .finally(() => {})    
}

const goToAsks = (codePin, bool) => {
    login(codePin, bool)
}

useEffect(() => {
  if(idQuestionnaire){
    getQuestions(idQuestionnaire, Cookies.get('localToken'))
  }
}, [idQuestionnaire])


  const getQuestions = (id, localToken) => {
       axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get_survey/${id}`, {
        headers: {
          UserSession: localToken,
          Authorization: `Bearer ${token}`,
        },
      
        params: {
        languageSelected: languageSelected,
        languageDefault: languageDefault,
      }
    })
    .then((response) => {
        if(response.data.length !== 0) {
            dispatch(setAllQuestions(response.data.content))
            dispatch(setMenu(response.data.content.menu))
            //dispatch(setNeedRapport(response.data.content.homepage.needRapport))
            createTableQuestion(response.data.content)
          }
        })
        .catch((error) => { 
          if (error.response.data.redirectToLogin){
         navigate(`${process.env.REACT_APP_FRONT_LINK}/${id}`)
        }
      })
        .finally(() => { return true;  });
    }

    
    
    const createTableQuestion = (data) => {
      const subQuestionIds = []
      if(data.questions && Array.isArray(data.questions)){
        data.questions.forEach(question => {
          if(question.pages && Array.isArray(question.pages)) {
            question.pages.forEach(page => {
              if (page.lots && Array.isArray(page.lots)) {
                page.lots.forEach((lot, lotIndex) => {
                  if(lot.sousquestions && Array.isArray(lot.sousquestions)) {
                      const lotQuestionsIDs = lot.sousquestions
                      .filter((sousquestion) => sousquestion.id && !sousquestion.isOtherQuestion)
                      .map((sousquestion) => sousquestion.id)
                      
                      if(lotQuestionsIDs.length > 0) {
                        subQuestionIds.push(lotQuestionsIDs)
                      }
                  }
                })
              }
            })
          }
        });
      }

      return subQuestionIds
    }

const detectBrowser = () => {
  // Opera 8.0+
  var isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0
  if (isOpera) return 'opera'
  // Firefox 1.0+
  var isFirefox = typeof InstallTrigger !== 'undefined';
  if (isFirefox) return 'firefox'
  // Safari 3.0+ "[object HTMLElementConstructor]" 
  var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
  if (isSafari) return 'safari'
  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/false || !!document.documentMode;
  if (isIE) return 'internetexplorer'
  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;
  if (isEdge) return 'edge'
  // Chrome 1 - 79
  var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  if (isChrome) return 'chrome'
  // Edge (based on chromium) detection
  var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") !== -1);
  if (isEdgeChromium) return 'edge'
  // Blink engine detection
  var isBlink = (isChrome || isOpera) && !!window.CSS;
  if (isBlink) return 'blink'

  return 'chrome'; // default
}

  const getAllLanguages = () => {
    axios.get(`${process.env.REACT_APP_BACK_URL}/preferences/get_languages` , {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        languageSelected: languageSelected,
        languageDefault: languageDefault
      }

    })
    .then((response) => {
        if(response.data.length !== 0) {
          dispatch(setLanguages(response.data.langue));
        }})
    .catch((error) => {console.log(error);})
    .finally(() => {});
  }

  // Dispatch l'action pour mettre à jour le chemin dans le store Redux
  useEffect(() => {
    dispatch(setPath(currentPath));
  }, [currentPath]);

  const getHomePage = (id) => {
    axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get_homepage/${id}` , {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        languageSelected: languageSelected,
        languageDefault: languageDefault
      },
      })
      .then((response) => {dispatch(setHomepage(response.data.homepage))})
      .catch((error) => {console.log(error)})
  }

  useEffect(() => {
    if(languageSelected && idQuestionnaire && token){
      getAllLanguages();
      getHomePage(idQuestionnaire)
    }
  }, [languageSelected, idQuestionnaire, token]);
  
  useEffect(() => {
    const myBrowser = detectBrowser()
    let myLanguage = 'fr-FR' // default
    switch(myBrowser) {
      case 'firefox':
        myLanguage = langueNav.toUpperCase()
        break
      default:
        myLanguage = langueNav.slice(0, 2).toUpperCase()
        break
    }
    dispatch(setLanguageSelected(myLanguage))
  }, [idQuestionnaire]);


  const changeLangue = (language) => {
    dispatch(setLanguageSelected(language));
  }

  const backgroundApp = {
    backgroundImage: `url(${flowersFooter})`,
    backgroundSize: 'cover',
    backgroundPosition: 'right bottom calc(10% - 25vh)',
    backgroundRepeat: 'no-repeat',
  }
  
  const displayLangues = () => {
    setLanguesDiv(!languesDiv)
  }

  const verifParent = (sousquestion, question, lot) => {
    let next = true
    const parentId = sousquestion.idForOtherQuestion
    const parentObj = lot.sousquestions.find(obj => obj.id == parentId)
    
    if(parentId){
      if(parentObj.idForOtherQuestion){
        verifParent(parentObj, question, lot)
      } else {
        if(sousquestion.waitingAnswer){
          if(saveNewAnswers && 
            saveNewAnswers[question[1].category.id] && 
            saveNewAnswers[question[1].category.id][parentId] && 
            saveNewAnswers[question[1].category.id][parentId].selectedAnswer == sousquestion.indexAnswerForQuestion){
            if(saveNewAnswers[question[1].category.id][sousquestion.id] && 
              !saveNewAnswers[question[1].category.id][sousquestion.id].textAnswer){
              next = false
            }}}
            else if (saveNewAnswers && 
              saveNewAnswers[question[1].category.id] &&
              saveNewAnswers[question[1].category.id][sousquestion.id] &&
              !saveNewAnswers[question[1].category.id][sousquestion.id].textAnswer) {
                next = false
              }
            }
          }

    if(sousquestion.waitingAnswer){
      if(saveNewAnswers && 
        saveNewAnswers[question[1].category.id] && 
        saveNewAnswers[question[1].category.id][parentId] &&
        saveNewAnswers[question[1].category.id][parentId].selectedAnswer == sousquestion.indexAnswerForQuestion){
        if(saveNewAnswers[question[1].category.id][sousquestion.id] && 
          !saveNewAnswers[question[1].category.id][sousquestion.id].textAnswer){
          next = false
        }
      }
    } else if (saveNewAnswers && 
      saveNewAnswers[question[1].category.id] &&
      saveNewAnswers[question[1].category.id][sousquestion.id] &&
      !saveNewAnswers[question[1].category.id][sousquestion.id].textAnswer) {
        next = false
      }

    if(next){
      return true
    } else {
      return false
    }
  }

  const finishQuestionnaire = (bool) => {
    let isValidate = true;
      axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/submit?languageSelected=${languageSelected}&languageDefault=${languageDefault}`, {
        idUser: idUser,
        questionnaire: idQuestionnaire,
        answers: answersForBack,
        isLast: bool,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        UserSession: Cookies.get('localToken'),
      },
    },)
    .then((response) => {
      if(response.status === 200){
        createNotification('success', '', 'Vos réponses ont bien été sauvegardées', 'Enregistrement effectué')
      }
      if(response.status === 202){
        createNotification('success', '', `Vos réponses ont bien été sauvegardées, cependant, ${response.data.errors.length} questions obligatoires attendent une réponse`, 'Enregistrement effectué')
        response.data.errors.forEach((error, errorIndex) => {
          createNotification('warning', '', `La question "${error.askText}" dans le chapitre "${error.title}" est une question obligatoire. Merci d'y répondre.`, `Question obligatoire`)
        })
        isValidate = false
      }
      
        })
        .catch((error) => {
          createNotification('warning', error, 'Une erreur est survenue, erreur :', 'Nous avons rencontré un problème')
          console.log(error)
          isValidate = false
          if(error.status === 401 && error.redirectToLogin){
            navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}`)
          }
        })
        .finally(() => { 
          if(docForBack){
            sendDocs()
          }
          if (isValidate){
            if(findIdText){
              navigate(`${process.env.REACT_APP_FRONT_LINK}/rapport/${findIdText.text}/${idUser}`)
            } else {
              navigate(`${process.env.REACT_APP_FRONT_LINK}/rapport/${idQuestionnaire}/${idUser}`)
            }
        }
        })
    }


  const sendResponses = (bool, mobile) => {
    if(modif){
      axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/submit?languageSelected=${languageSelected}&languageDefault=${languageDefault}`, {
        idUser: idUser,
        questionnaire: idQuestionnaire,
        answers: answersForBack,
        isLast: bool,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        UserSession: Cookies.get('localToken'),
      },
    },)
    .then((response) => {
      createNotification('success', '', 'Vos réponses ont bien été sauvegardées', 'Enregistrement effectué')
      dispatch(setModif(false))
      setAnswersForBack(response.data.answers)
        })
        .catch((error) => {
          createNotification('alert', error, 'Une erreur est survenue, erreur :', 'Nous avons rencontré un problème')
          if (error.status === 401 && error.redirectToLogin) {
            navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}`)
          }
        })
        .finally(() => { 
          if(docForBack){
            sendDocs()
          }
        })
    }
    };

    const createNotification = (type, error, text, title, time) => {
      switch (type) {
        case 'info':
          NotificationManager.info('Info message');
          break;
        case 'success':
          NotificationManager.success(text, title, 5000);
          break;
        case 'warning':
          NotificationManager.warning(text, title, time);
          break;
        case 'error':
          NotificationManager.error('Error message', 'Click me!', 5000, () => {
            alert('callback');
          });
          break;
        default: break;
      }
    };
    
  const sendDocs = () => {
    const formData = new FormData();

    for (const key in docForBack) {
      formData.append(key, docForBack[key].doc)
    }
    axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/submit_files_answers/${idQuestionnaire}/${idUser}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        UserSession: Cookies.get('localToken'),
      },
    })
    .then((response) => {
      createNotification('success', '', 'Vos documents ont bien été reçus', 'Enregistrement des documents')
    })
    .catch((error) => {
      createNotification('warning', error)
      if(error.status === 401 && error.redirectToLogin){
        navigate(`${process.env.REACT_APP_FRONT_LINK}/${idQuestionnaire}`)
      }
    })
  }

  useEffect(() => {
    if (modif) { 
    const handleBeforeUnload = (event) => { 
      event.preventDefault(); 
      event.returnValue = 'Attention, vous n\'avez pas enregistré vos données.'; 
    }; 
    window.addEventListener('beforeunload', handleBeforeUnload);
     return () => { 
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }}
  }, [modif])

  return (      
      <div>
        <BrowserView>
        <div className="App" style={backgroundApp}>        
          <div className="page__principale">
          <div className='language__div'>
            <div className='language__div__moitie'> 
              <div className='language__div__part'>
                  <div className={languesDiv ? 'language__container' : 'language__container language__close'}>
                    {languages.slice(1).map((language, indexLanguage) => {
                      return (
                        <div key={indexLanguage} className='language' onClick={() => {changeLangue(language.code)}}>
                          {language.code} - {language.nom}
                        </div>
                      )
                    })}
                  </div>
                  
                </div>
                <div className='language__div__part'></div> 
                <div className='language__div__part'></div>
              </div>
            </div>
          <div className='language__div'>
            <div className='language__div__moitie'>
              <div className='language__div__part'>
                <p className='language__text' onClick={displayLangues}>{languages[0] && languages.length > 2 ? languages[0].nom : null}</p>
                </div>
              
                <div className='language__div__part'>
                  {footerState && idUser ? modif 
                    ? <button className='language__button' onClick={() => {sendResponses(false)}}>Enregistrer vos réponses</button> 
                    : <button className='language__button language__button__inactive'>Enregistrer vos réponses</button> : null}
                </div>
                <div className='language__div__part'>
                  {footerState && idUser ? <button className='language__button' onClick={() => {finishQuestionnaire(true)}}>Valider le questionnaire</button> : null}
                  
                </div>
              </div>
              <a className='footer' href="http://www.uranie-conseil.fr" target="_blank"><b className='footer__company'  >Uranie</b> <br /> par Exco Valliance</a>
            </div>
            <Routes>
              <Route path={`/${process.env.REACT_APP_FRONT_LINK}/:idQuestionnaire/*`} element={<AppWrapper getHomePage={getHomePage} getQuestions={getQuestions} setSubQuestionIds={setSubQuestionIds} createTableQuestion={createTableQuestion} />} />
              <Route exact path={`/${process.env.REACT_APP_FRONT_LINK}/:idQuestionnaire`} element={<><AppWrapper getHomePage={getHomePage} getQuestions={getQuestions} setSubQuestionIds={setSubQuestionIds} createTableQuestion={createTableQuestion} /><Home alertContainer={alertContainer} setDisplayChangeAnswers={setDisplayChangeAnswers} displayChangeAnswers={displayChangeAnswers} setAnswersForBack={setAnswersForBack} createTableQuestion={createTableQuestion} getQuestions={getQuestions} textAlert={errorMessageForBadMail} goodMail={goodMail} setGoodMail={setGoodMail} modalPin={modalPin} setModalPin={setModalPin} createNotification={createNotification} saveNewAnswers={saveNewAnswers} setSaveNewAnswers={setSaveNewAnswers} verifAt={verifAt} login={login} goToAsks={goToAsks} displayBeginButton={displayBeginButton} /></>} />
              {allQuestions && allQuestions.menu && allQuestions.menu.map((m, indexPage) => (
                <Route
                  key={indexPage}
                  path={`/${process.env.REACT_APP_FRONT_LINK}/:idQuestionnaire/:idUser/:menuId/:idPage/:indexLot/:idQuestion`}
                  element={<><AppWrapper getHomePage={getHomePage} getQuestions={getQuestions} setSubQuestionIds={setSubQuestionIds} createTableQuestion={createTableQuestion} /><Menu menuContent={allQuestions.questions} sousmenu={false} currentPath={currentPath} /><Page createNotification={createNotification} sendResponses={sendResponses} docForBack={docForBack} setDocForBack={setDocForBack} finalRapport={finalRapport} setFinalRapport={setFinalRapport} sum={sum} setSum={setSum} saveNewAnswers={saveNewAnswers} setSaveNewAnswers={setSaveNewAnswers} currentPath={currentPath} indexPage={indexPage} answersForBack={answersForBack} setAnswersForBack={setAnswersForBack} finishQuestionnaire={finishQuestionnaire}
                  profilForEnd={profilForEnd} setProfilForEnd={setProfilForEnd} menuContent={allQuestions.menu} /></>}
                />
              ))}
              <Route path={`${process.env.REACT_APP_FRONT_LINK}/rapport/:idQuestionnaire/:idUser`} element={<><AppWrapper getHomePage={getHomePage} getQuestions={getQuestions} setSubQuestionIds={setSubQuestionIds} createTableQuestion={createTableQuestion} /> <EndRapport setDisplayChangeAnswers={setDisplayChangeAnswers} alertContainer={alertContainer} displayChangeAnswers={displayChangeAnswers} createNotification={createNotification} needModal={needModal} setNeedModal={setNeedModal} finalRapport={finalRapport} sum={sum} profilForEnd={profilForEnd} setProfilForEnd={setProfilForEnd} idUser={idUser} /></>} />
              <Route path={`${process.env.REACT_APP_FRONT_LINK}/menu/:idQuestionnaire/:idUser`} element={<><AppWrapper getHomePage={getHomePage} getQuestions={getQuestions} setSubQuestionIds={setSubQuestionIds} createTableQuestion={createTableQuestion} /> <MenuChoice idUser={idUser} idQuestionnaire={idQuestionnaire} /></>} />
              <Route
                  path={`/${process.env.REACT_APP_FRONT_LINK}/:idQuestionnaire/:idUser/:menuId/:idPage/:indexLot/:idQuestion/profil`}
                  element={<><AppWrapper getHomePage={getHomePage} getQuestions={getQuestions} setSubQuestionIds={setSubQuestionIds} createTableQuestion={createTableQuestion} /><Rapport createNotification={createNotification} saveNewAnswers={saveNewAnswers} sum={sum} allQuestions={allQuestions} profilForEnd={profilForEnd} setProfilForEnd={setProfilForEnd} /></>}
                />
            </Routes>
          </div> 
        
      </div>
        </BrowserView>
        <MobileView className='background'>
          <Routes>
            <Route path={`/${process.env.REACT_APP_FRONT_LINK}/:idQuestionnaire`}
            element={<div className='mobileApp'><AppWrapper getHomePage={getHomePage} getQuestions={getQuestions} setSubQuestionIds={setSubQuestionIds} createTableQuestion={createTableQuestion} />  <MobileApp createNotification={createNotification} setDisplayChangeAnswers={setDisplayChangeAnswers} displayChangeAnswers={displayChangeAnswers} alertContainer={alertContainer} createNotification={createNotification} errorMessageForBadMail={errorMessageForBadMail} goodMail={goodMail} setGoodMail={setGoodMail} findIdText={findIdText} setModalPin={setModalPin} setAnswersForBack={setAnswersForBack} token={token} modalPin={modalPin} verifAt={verifAt} login={login} goToAsks={goToAsks} displayBeginButton={displayBeginButton} changeLangue={changeLangue} /></div> }
              />
                <Route
                  path={`/${process.env.REACT_APP_FRONT_LINK}/:idQuestionnaire/:idUser/:menuId/:idPage/:indexLot/:idQuestion`}
                  element={<div className='mobileApp'> 
                  {idQuestion && idMenu && idPage && indexLot ? <MobilePage setSum={setSum} docForBack={docForBack} setDocForBack={setDocForBack} subQuestionIds={subQuestionIds} createNotification={createNotification} sendResponses={sendResponses} answersForBack={answersForBack} setAnswersForBack={setAnswersForBack} saveNewAnswers={saveNewAnswers} setSaveNewAnswers={setSaveNewAnswers} idQuestion={idQuestion} idMenu={idMenu} idPage={idPage} indexLot={indexLot} finishQuestionnaire={finishQuestionnaire} />  : null}
                  <AppWrapper /></div>}
                />
              <Route
                  path={`/${process.env.REACT_APP_FRONT_LINK}/:idQuestionnaire/:idUser/:menuId/:idPage/:indexLot/:idQuestion/profil`}
                  element= {<div className='mobileApp'> <MobileProfil sendResponses={sendResponses} /><AppWrapper /></div>}
                  />
              <Route 
                path={`${process.env.REACT_APP_FRONT_LINK}/rapport/:idQuestionnaire/:idUser`}
                element= {<div className='mobileApp'> <MobileRapport profilForEnd={profilForEnd} setProfilForEnd={setProfilForEnd} /></div>}
                />
                <Route 
                path={`${process.env.REACT_APP_FRONT_LINK}/menu/:idQuestionnaire/:idUser`}
                element= {<div className='mobileApp'> <AppWrapper getHomePage={getHomePage} getQuestions={getQuestions} setSubQuestionIds={setSubQuestionIds} createTableQuestion={createTableQuestion} /> <ChangeTheme /> </div>}
                />

                </Routes>
                <FooterMobile />
        </MobileView>
          
      </div>
    );}

const mapStateToProps = (state) => ({
  menu: state.menu,
  rapport: state.needRapport,
  languages: state.languages,
  languageSelected: state.languageSelected,
  allQuestions: state.allQuestions,
  menuSelect: state.menuSelect,
  languageDefault: state.languageDefault,
  answers: state.answers,
  step: state.step,
  idUser: state.idUser,
  idQuestionnaire: state.idQuestionnaire,
  modif: state.modif,
  idQuestion: state.idQuestion,
  idMenu: state.idMenu,
  idPage: state.idPage,
  indexLot: state.indexLot,
  tokenStore: state.token,
  idCorrespond: state.idCorrespond,
});
const mapDispatchToProps = {
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(App);