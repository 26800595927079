import GaugGraph from "./gaugGraph"
import './gaug.scss'
import { Slider } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";

const Gaug = ( {idQuestionnaire, idUser, menuId, dataBack, createNotification }) => {
    const params = useParams()

    const [valueObj, setValueObj] = useState()
    const [displayButton, setDisplayButton] = useState(false)
    const localToken = Cookies.get('localToken')
    const token = Cookies.get('token')

    const data = [
        {
          value: dataBack[0].value[0],
          name: dataBack[0].name,
          title: {
            offsetCenter: ['0%', '-30%']
          },
          detail: {
            valueAnimation: false,
            offsetCenter: ['0%', '-15%'],
          }
        },
        {
          value: dataBack[1].value[0],
          name: dataBack[1].name,
          title: {
            offsetCenter: ['0%', '0%']
          },
          detail: {
            valueAnimation: false,
            offsetCenter: ['0%', '15%']
          }
        },
        {
          value: valueObj ? valueObj : dataBack[2].value[0] ? dataBack[2].value[0] : 0,
          name: dataBack[2].name,
          title: {
            offsetCenter: ['0%', '30%']
          },
          detail: {
            valueAnimation: false,
            offsetCenter: ['0%', '45%']
          }
        }
      ];

      const objForBack = {
          [params.menuId]: {
            totalPoints: valueObj
          }
      }

      const sendObj = () => {
        axios.put(`${process.env.REACT_APP_BACK_URL}/questionnaire/update_editable_chart/${idQuestionnaire}/${idUser}`, objForBack
         ,{
          headers: {
            UserSession: localToken,
            Authorization: `Bearer ${token}`,
          },
      })
      .then((response) => {
          createNotification('success', '', 'Votre objectif a bien été sauvegardé', 'Enregistrement effectué')
          })
          .catch((error) => { 
            createNotification('warning', '', 'Veuillez contacter votre administrateur pour résoudre le problème', 'Une erreur s\'est produite')
        })
          .finally(() => { return true;  });
      }
      

    return (
        <div className="gaug">
          <div className="gaug__graph">
            <GaugGraph data={data} />
          </div>
            <div className="gaug__obj">
              Quels sont vos objectifs ? 
            <Slider
              valueLabelDisplay="auto"
              onChange={(event) => {setValueObj(event.target.value); setDisplayButton(true)}}
              min={0}
              className='questions__card__slide'
              defaultValue={data[2].value ? data[2].value : 0}
              max={100} 
            />
            {displayButton ? 
            <div className='questions__card__container'>
            <div className='questions__card__button questions__card__button' onClick={() => {sendObj()}}>Enregistrer l'objectif</div></div>
            :  <div className='questions__card__container'>
            <div className='questions__card__button__close'></div></div>}
            </div>

        </div>
    )
}

const mapStateToProps = (state) => ({
  idQuestionnaire: state.idQuestionnaire,
  idUser: state.idUser,
  menuId: state.idMenu,
  
  });
const mapDispatchToProps = dispatch => ({
});



export default connect(mapStateToProps, mapDispatchToProps)(Gaug);