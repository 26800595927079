import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Link, Font } from '@react-pdf/renderer';
import './pdf.scss'
import excoLogo from '../../assets/img/valliance/valliance_logo.jpg'
import flowers from '../../assets/img/valliance/feuille_coin_02.png'
import uranieLogo from '../../assets/img/valliance/logo_uranie.png'
import check from '../../assets/img/icone check.png'
import close from '../../assets/img/icone close.png'
import { useEffect } from 'react';

Font.register({
  family: 'Open Sans',
  fonts: [
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf', fontWeight: 800 }
  ]
  });

// Create styles
const styles = StyleSheet.create({
  header: {
    alignSelf: 'center',
    width: '90%',
    flexDirection: 'row',
    borderBottom: '2px solid black'
  },

  column: {
    flex: 1,
    padding: '5% 2%'
  },
  column1: {
    flex: 1,
    justifyContent: 'center',
    alignItems:'center'
  },
  column2: {
    flex: 2,
  },

  title: {
    alignSelf: 'center',
    color: 'rgb(218,91,21)',
    margin: '5 0',
  },

  headerTitleBig: {
    fontSize: '12px',
    fontWeight: 'bold',
  },

  headerTitle: {
    fontSize: '10px',
  },

  headerText: {
    fontSize: '9px',
  },

  logo: {
    alignSelf: 'center',
    width: '50%',
  },

  profilView: {
    width: '90%',
    alignSelf: 'center',
    borderRadius: '5%',
    padding: 15,
    margin: 5,
    
  },
  profil: {
    backgroundColor: 'rgb(135,136,138)',
    borderRadius: 16,
    padding: '2% 4%',
  },

  profilTitle: {
    width: '100%',
    color: 'rgb(0,129,183)'
  },
  profilTitleSmall: {
    textAlign: 'center',
    width: '100%',
    fontSize: '14px',
    color: 'white',
  },

  profilText: {
    fontSize: '10px',
    marginTop: 3,
    textAlign:'justify',
    color: 'white',
  },

  radar: {
    width: 'auto',
    height: 'auto',
    marginTop: '45',
    container: {
      width: '100%',
      alignSelf: 'center',
    },
    title: {
      width: '100%',
      marginBottom: 15,
      color: 'rgb(0,129,183)',
      width: '80%',
      alignSelf: 'center',
    },
    text: {
      fontSize: '10',
      width: '80%',
      alignSelf:'center',
      actuel: {color: 'black', fontWeight: '800',},
      preconisation: {color:'rgb(0,129,183)'},
      objectifs: {color:'rgb(218,91,21)'},
    },
  },

  page: {
    backgroundImage: `url(${flowers})`,
    backgroundSize: 'cover',
    position: 'relative',
    alignSelf: 'center',
    textAlign: 'justify',
    paddingTop: 15,
  },

  id: {
    alignSelf: 'flex-end',
    marginRight: '5%'
  },

  idText: {
    alignSelf: 'flex-end',
    fontSize: '10px',
    marginTop: '-20px'
  },

  footer: {
    position: 'absolute',
    bottom: '0',
    backgroundColor: 'rgb(0,129,183)',
    width: '100%',
    height: '4%',
    flexDirection:'row',
    text:{
      fontSize: '8px',
      color: 'white'
    },
    img: {
      width: '50%',
    },
    link: {
      color:'white',
      textDecoration: 'none'
    },
  },
  table: {
    display: 'table',
    width: '95%',
    borderStyle: "solid", 
    borderWidth: 0, 
    borderRightWidth: 0, 
    borderBottomWidth: 0,
    alignSelf: 'center',
    marginBottom: 10,
    chapitre:{
      title:{
        width:'100%',
        fontSize: '20px',
        padding: 5,
        borderWidth: 1,
        textAlign:'center',
        backgroundColor: 'rgb(0,129,183)',
        color: 'white',
      }
    },
    resum: {
      width: '95%',
      alignSelf:'center',
    },
    row: { 
      flexDirection: "row",
      borderStyle: "solid", 
        borderWidth: 1, 
        borderTopWidth: 0
    },
    column: {
      reco: {
        width: "100%", 
        borderStyle: "solid", 
        borderWidth: 0,
        borderRightWidth: 1, 
        padding: 15,
      },
      question: {
        width: "10%", 
        borderStyle: "solid",
        height: '100%',
        borderWidth: 0, 
        borderRightWidth: 1, 
        borderTopWidth: 0,
        padding: 10,
        alignItems: 'center',
      },
      engagement: {
        width: "25%", 
        borderStyle: "solid",
        height: '100%',
        borderWidth: 0, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        padding: 10,
        alignItems: 'center',
      },
      resum: {
        question: {
          width: '80%',
          borderStyle: "solid",
          height: '100%',
          borderWidth: 0, 
          borderRightWidth: 1, 
          borderTopWidth: 0,
          padding: 10,
          alignItems: 'center',
        },
        reponse: {
          width: '20%',
          borderStyle: "solid",
          height: '100%',
          borderWidth: 0, 
          borderRightWidth: 1, 
          borderTopWidth: 0,
          alignSelf: 'center',
        }
      }
    },
    img: {
      margin: 'auto',
      height: 25,
      alignSelf: 'center',
    },
    text:{
      margin: "auto",
      padding: 10,
      alignSelf: "center",
      fontSize: 9,
    },
    title:{
      textAlign: 'center',
      width: '100%',
      marginTop: 35,
      marginBottom: 50,
    }
  },
  thanks: {
    position: 'relative',
    marginBottom: '10',
    width: '80%',
    alignSelf: 'center',
    text:{
      fontSize: '10px',
      marginTop: 10,
      width: '100%',
    },
    title: {
      fontSize: '14px',
      color: 'rgb(0,129,183)',
      textAlign: 'center',
      margin: '10 0 10 0'
    }
  }
})


// Create Document Component
const PDFResum = ( {rapport, resumData}) => {
const group = rapport.group
const user = rapport.user
const message = rapport.message


    return(
<PDFViewer className='pdf__viewer'>
      <Document>
        <Page style={styles.page}>
          <View style={styles.header}>
            <View style={styles.column}>
              <Text style={styles.headerTitleBig}>{group.name}</Text>
              <Text style={styles.headerTitle}>{group.address.title}  </Text>
              <Text style={styles.headerText}>{group.address.value} {group.zipcode} {group.city}</Text>
              <Text style={styles.headerTitle}>{group.phone.title}</Text> 
              <Text style={styles.headerText}>{group.phone.value}</Text>
            </View>
            <View style={styles.column}>
              <Image style={styles.logo} src={excoLogo} />
            </View>
            <View style={styles.column}>
              <Text style={styles.headerText}>{user.firstname} {user.lastname}</Text>
              <Text style={styles.headerText}>{user.email}</Text>
              <Text style={styles.headerText}>{user.role}</Text>
            </View>
          </View>
          <View>
            <Text style={styles.title}>{message.title ? message.title : null}</Text>
          </View>
          <View>
            <Text style={styles.title}>{message.entreprise} : {user.company === '' ? 'Non renseigné' : user.company}</Text>
          </View>
          <View style={styles.thanks}>
            <Text style= {styles.thanks.title}>{message.diag} {rapport.date}</Text>
            <Text style= {styles.thanks.title}>{message.by} : {user.firstname} {user.lastname} - {user.role}</Text>
          </View>          
          <View>
          </View>
        </Page>

        {resumData.map((r) => {
            return(
                <Page style={styles.page} orientation={'landscape'} fixed >
                  <View style={styles.table.resum}>
                      <Text style={styles.table.chapitre.title}>{r.title}</Text>
                      {r.questions.map((q) => {
                        return (
                        <View style={styles.table.row} wrap={false} >
                      <View style={styles.table.column.resum.question}> 
                        <Text style={styles.table.text}>{q.question}</Text>
                      </View>
                      <View style={styles.table.column.resum.response}>
                      {q.response.map((r) => (
                          <Text style={styles.table.text}>{r}</Text>
                        ))} 
                      </View>
                    </View>
                      )})}
                    
                    </View>
                </Page>
            )
        })}
            
            <Page style={styles.page}>
          <View>
            <Text style={styles.radar.text}>{message.outroText}</Text>
          </View>
          <View style={styles.footer}>
            <View style={styles.column1}>
              <Text style={styles.footer.text}>Conception et réalisation par Uranie</Text>
              <Text style={styles.footer.text}>Spécialiste des solutions comptables</Text>
              <Text style={styles.footer.text}>logiciels et web</Text>
            </View>
            <View style={styles.column1}>
              <Image src={uranieLogo} style={styles.footer.img} />
            </View>
            <View style={styles.column1}>
              <Text style={styles.footer.text}>Site web : 
              <Link style={styles.footer.link} src='http://www.uranie-conseil.fr' target='_blank'> uranie-conseil.fr</Link></Text>
              <Text style={styles.footer.text}>Téléphone: 
              <Link style={styles.footer.link} src='tel:+33556397908' target='_blank'>05 56 39 79 08</Link></Text>
              <Text style={styles.footer.text}>Adresse : 
              <Link style={styles.footer.link} 
              src='https://www.google.com/maps/place/Uranie/@44.8854985,-0.5318947,15z/data=!4m6!3m5!1s0xd5528d5d1496259:0x3df8360cfe1a8f14!8m2!3d44.8854985!4d-0.5318947!16s%2Fg%2F1tzzwvgx' target='_blank'>Quai Carriet 33310 Lormont</Link></Text>
            </View>
            </View>
        </Page>
      </Document>
    </PDFViewer>
)};

  export default PDFResum;