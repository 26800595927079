import './modal.scss'

const Modal = ({text, boutonOK, boutonStop, setDisplayModal, action}) => {
    return (
        <div className='modal__background'>
            <div className='modal__container'>
                <div className='modal__text'>
                    {text}
                </div>
                <div className='modal__button__container'>
                    <div className='modal__button' onClick={() => {action(true)}}>{boutonOK}</div>
                    <div className='modal__button' onClick={() => {setDisplayModal(false)}}>{boutonStop}</div>
                </div>
            </div>
        </div>
    )
}

export default Modal