import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { setDirigeantFirstname, setDirigeantLastname, setFirstname, setLastname, setDirigeantMail, setModif } from "../../../actions/action";

const ReponseTextarea = ({sousquestion, setSaveNewAnswers, saveNewAnswers, lot, question, updateAnswers, fonction, dirigeantlastname, dirigeantfirstname, firstname, setAnswerRequired, lastname}) => {
    let questionValue = null;
    const sousquestionLabel = sousquestion.label
    const dispatch = useDispatch()
    const [defaultValue, setDefaultValue] = useState('')


    const changeDefaultValue = () => {
         if(fonction) {
            if(saveNewAnswers && saveNewAnswers[question.category.id] && saveNewAnswers[question.category.id][sousquestion.id] && saveNewAnswers[question.category.id][sousquestion.id].textAnswer){
                setDefaultValue(saveNewAnswers[question.category.id][sousquestion.id].textAnswer)
            }
            if(firstname && sousquestion.identityType === 'dirigeantfirstname') {
                setDefaultValue(firstname)
                setSaveNewAnswers(prevState => {
                    const updatedQuestion  = {...prevState[question.category.id][sousquestion.id],
                        textAnswer: firstname,
                        answerValue: questionValue,
                    };
                    const updatedCategory = {...prevState[question.category.id], [sousquestion.id]: updatedQuestion}
                    const updatedState = {...prevState, [question.category.id]: updatedCategory}
                    return updatedState;
                });
                updateAnswers(null, questionValue, firstname, sousquestion.id)

            }
            if(lastname && sousquestion.identityType === 'dirigeantlastname') {
                setDefaultValue(lastname)
                setSaveNewAnswers(prevState => {
                    const updatedQuestion  = {...prevState[question.category.id][sousquestion.id],
                        textAnswer: lastname,
                        answerValue: questionValue,
                    };
                    const updatedCategory = {...prevState[question.category.id], [sousquestion.id]: updatedQuestion}
                    const updatedState = {...prevState, [question.category.id]: updatedCategory}
                    return updatedState;
                });
                updateAnswers(null, questionValue, lastname, sousquestion.id)
            }
        } else {
            if(saveNewAnswers && saveNewAnswers[question.category.id] && saveNewAnswers[question.category.id][sousquestion.id] && saveNewAnswers[question.category.id][sousquestion.id].textAnswer){
                setDefaultValue(saveNewAnswers[question.category.id][sousquestion.id].textAnswer)
            }
    
    }

        /* if(saveNewAnswers && saveNewAnswers[question.category.id] && saveNewAnswers[question.category.id][sousquestion.id] && saveNewAnswers[question.category.id][sousquestion.id].textAnswer){
            setDefaultValue(saveNewAnswers[question.category.id][sousquestion.id].textAnswer)
        } else if (sousquestion.identityType === 'dirigeantfirstname' && dirigeantfirstname) {
            setDefaultValue(dirigeantfirstname)
        } else if (sousquestion.identityType === 'dirigeantlastname' && dirigeantlastname) {
            setDefaultValue(dirigeantlastname)
        } */
    }
    
    useEffect(() => {
        changeDefaultValue()
    }, [fonction, firstname, lastname])

    
    /* useEffect(() => {
        if(saveNewAnswers && saveNewAnswers[question.category.id]){
            const findFirstNameObj = lot.sousquestions.find(obj => obj.identityType === 'firstname')
            const findFirstNameValue = findFirstNameObj && saveNewAnswers[question.category.id][findFirstNameObj.id] ? saveNewAnswers[question.category.id][findFirstNameObj.id].textAnswer : null
            const findLastNameObj = lot.sousquestions.find(obj => obj.identityType === 'lastname')
            const findLastNameValue = findLastNameObj && saveNewAnswers[question.category.id][findLastNameObj.id] ? saveNewAnswers[question.category.id][findLastNameObj.id].textAnswer : null
            
            if(fonction && findFirstNameObj && findLastNameObj){
                dispatch(setDirigeantFirstname(findFirstNameValue))
                dispatch(setDirigeantLastname(findLastNameValue))
            } else if (!fonction) {
                dispatch(setDirigeantFirstname(null))
                dispatch(setDirigeantLastname(null))
            }
            
            if(sousquestion.identityType === 'dirigeantfirstname' && dirigeantfirstname){
                setSaveNewAnswers(prevState => {
                    const updatedQuestion  = {...prevState[question.category.id][sousquestion.id],
                        textAnswer: dirigeantfirstname,
                        answerValue: questionValue,
                    };
                    const updatedCategory = {...prevState[question.category.id], [sousquestion.id]: updatedQuestion}
                    const updatedState = {...prevState, [question.category.id]: updatedCategory}
                    return updatedState;
                });
                updateAnswers(null, questionValue, dirigeantfirstname, sousquestion.id)
            } else if (sousquestion.identityType === 'dirigeantlastname' && dirigeantlastname){
                setSaveNewAnswers(prevState => {
                    const updatedQuestion  = {...prevState[question.category.id][sousquestion.id],
                        textAnswer: dirigeantlastname,
                        answerValue: questionValue,
                    };
                    const updatedCategory = {...prevState[question.category.id], [sousquestion.id]: updatedQuestion}
                    const updatedState = {...prevState, [question.category.id]: updatedCategory}
                    return updatedState;
                });
                updateAnswers(null, questionValue, dirigeantlastname, sousquestion.id)
            }
        }
    }, [fonction]) */

    const takePlaceholder = () => {
    if (sousquestion.pointsValues) {
       questionValue = sousquestion.values[sousquestion.pointsValue]
    }
    }

    const updateSaveNewAnswers = (event) => {
        if(sousquestion.lastname) {
            dispatch(setLastname(event.target.value))
        }
        if(sousquestion.firstname){
            dispatch(setFirstname(event.target.value))
        }
        setSaveNewAnswers(prevState => {
            const updatedQuestion  = {...prevState[question.category.id][sousquestion.id],
                textAnswer: event.target.value,
                answerValue: questionValue,
            };
            const updatedCategory = {...prevState[question.category.id], [sousquestion.id]: updatedQuestion}
            const updatedState = {...prevState, [question.category.id]: updatedCategory}
            return updatedState;
        });

        updateAnswers(null, questionValue, event.target.value, sousquestion.id)
    };

    const updateData = (event) => {
        updateSaveNewAnswers(event)
        setAnswerRequired(true)
        dispatch(setModif(true))
    }

    useEffect(() => {
        takePlaceholder()
    }, [saveNewAnswers])

    return (
        !sousquestion.isWide ? 
        <input className="questions__card__input" 
        placeholder={sousquestionLabel ? sousquestionLabel : null} 
        defaultValue= {defaultValue ? defaultValue : null} 
        onKeyUp={(event) => {updateData(event); setAnswerRequired(true)}} />
        : 
        <textarea className="questions__card__textarea"
        placeholder={sousquestionLabel ? sousquestionLabel : null} 
        defaultValue= {sousquestion.asks ? sousquestion.asks : null} 
        onKeyUp={(event) => {updateData(event); setAnswerRequired(true)}} />
    )
}

const mapStateToProps = (state) => ({
    languageSelected: state.languageSelected,
    answers: state.answers,
    fonction: state.fonction,
    dirigeantlastname: state.dirigeantlastname,
    dirigeantfirstname: state.dirigeantfirstname,
    dirigeantmail: state.dirigeantmail,
    firstname : state.firstname,
    lastname: state.lastname,
 });
  const mapDispatchToProps = {
    };
  
  export default connect(mapStateToProps, mapDispatchToProps)(ReponseTextarea);