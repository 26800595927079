import { Card } from 'react-bootstrap'
import ReponseButton from '../Réponse/button';
import { useState, useEffect } from 'react';
import ReponseSelect from '../Réponse/select';
import ReponseSlide from '../Réponse/slide';
import ReponseTextarea from '../Réponse/textarea';
import { connect, useDispatch } from 'react-redux';
import uploadIco from '../../../assets/img/uIco.png'
import {setFirstname, setFonction, setLastname, setModif} from '../../../actions/action';
import MultipleChoice from '../Réponse/multipleChoice';

const Question = ( {recursiveUpdateSaveNewAnswers, sousquestion, answersForBack, indexSousQuestion, setSaveNewAnswers, saveNewAnswers, answers, question, setAnswersForBack, docForBack, setDocForBack, setAnswerRequired, idMenu, lot, recursiveUpdate,} ) => {
    const dispatch = useDispatch()
    
    const [infoBulle, setInfoBulle] = useState(false);
    const [buttonSelect, setButtonSelect] = useState([null, null])
    const updateAnswers = (responseId, responseValue, responseText) => {
        recursiveUpdateSaveNewAnswers(sousquestion, responseId)
        setAnswersForBack(prevState => {
            const updateAnswers = 
                        {
                            response: responseId,
                            value: responseValue,
                            textResponse: responseText,
                        }
                    ;
                    const updateCategory = {...prevState[question.category.id], [sousquestion.id]: updateAnswers}
                    const updateState = {...prevState, [question.category.id]: updateCategory, modif: true}
                    return updateState;
                  })}
        

        const createSaveNewAnswers = (sousquestion) => {
            if (sousquestion.identityType === 'role') {
                if(answers && answers[idMenu] && answers[idMenu][sousquestion.id] && answers[idMenu][sousquestion.id].response === 3){
                    dispatch(setFonction(true))
                }
            }
            if(sousquestion.identityType === 'firstname') {
                if(answers && answers[idMenu] && answers[idMenu][sousquestion.id] && answers[idMenu][sousquestion.id].textResponse){
                    dispatch(setFirstname(answers[idMenu][sousquestion.id].textResponse))
                }
            }
            if(sousquestion.identityType === 'lastname') {
                if(answers && answers[idMenu] && answers[idMenu][sousquestion.id] && answers[idMenu][sousquestion.id].textResponse){
                    dispatch(setLastname(answers[idMenu][sousquestion.id].textResponse))
                }
            }
            if(idMenu){
                setSaveNewAnswers(prevState => {
            const updatedQuestion = {
                childId: sousquestion.othersQuestionsId ? sousquestion.othersQuestionsId : null ,
                waitingAnswer: sousquestion.indexAnswerForQuestion, 
                selectedAnswer: answers && answers[idMenu] && answers[idMenu][sousquestion.id] ? answers[idMenu][sousquestion.id].response : '',
                parentId: sousquestion.idForOtherQuestion,
                answerValue: answers && answers[idMenu] && answers[idMenu][sousquestion.id] ? answers[idMenu][sousquestion.id].value : null,
                textQuestion: sousquestion.asks,
                textAnswer: answers && answers[idMenu] && answers[idMenu][sousquestion.id] ? answers[idMenu][sousquestion.id].textResponse : null, 
                textRapport: answers && answers[idMenu] && answers[idMenu][sousquestion.id] && answers[idMenu][sousquestion.id].response ? sousquestion.options[answers[idMenu][sousquestion.id].response].rapportResponse : null,
                identityType: sousquestion.identityType ? sousquestion.identityType : null,
                required: sousquestion.required,
            };

            const updatedState = {...prevState, [idMenu]: {...prevState[idMenu], [sousquestion.id]: updatedQuestion}};
            return updatedState;
        })
            }
    }


    const displayInfoBulle = () => {
        setInfoBulle(true)
    }

    const closeInfoBulle = () => {
        setInfoBulle(false)
    }

    const sendToAnswersForBack = (event) => {
        setDocForBack(prevState => {        
            const updatedState = {
                  doc: event.target.files[0], 
                }
                if (prevState === null || typeof prevState === 'undefined') {
                    // Créer un nouvel objet s'il n'existe pas encore
                    return {
                        [sousquestion.id]: updatedState,
                      }
                  } else {
                    // Remplacer l'objet existant par le nouvel objet
                    return {
                      ...prevState,
                        [sousquestion.id]: updatedState,
                      }
                    };
                  })
                };

    useEffect(() => {
        lot.sousquestions.forEach((obj) => {
            if(saveNewAnswers && saveNewAnswers[question.category.id] && !saveNewAnswers[question.category.id][obj.id]){
                createSaveNewAnswers(obj)
            } 
        })
    }, [lot, idMenu, answers])

useEffect(() => {
    recursiveUpdate(sousquestion)
}, [saveNewAnswers])

const optionsValues = sousquestion.options ? Object.values(sousquestion.options) : []
const optionsKeys = sousquestion.options ? Object.keys(sousquestion.options) : []
const options = [];
useEffect(() => {
    if(optionsValues && sousquestion.askTypes === 'select'){
        const items = optionsValues.map((option, optionIndex) => ({
            label: option.text, value: option.value, index: optionIndex,
        }))
        options.push(...items)
    }
}, [optionsValues])
    
    return (
        <div className={sousquestion.isWide ? 'questions__card__wide' : 'questions__card__part'}>
            <div className='questions__card__response' onMouseLeave={closeInfoBulle}>
                {!sousquestion.isWide ? <Card.Text className='questions__card__text'>{sousquestion.required ? '*' : null} {sousquestion.asks} 
                {sousquestion.infobulle != null ? 
                    <span className='questions__card__text__informations' onMouseEnter={displayInfoBulle} >?</span>
                : null }
                </Card.Text>: null }
                {infoBulle ?
                <div className='infobulle'>
                    {sousquestion.infobulle.title}
                </div> : null}
            </div>
            <div className='questions__card__response'>
            {sousquestion.askTypes === 'button' ? 
                <div className='questions__card__button__container' >
                    {optionsValues.map((option, optionIndex) => {
                    return (
                        <div key={optionIndex}>
                            <ReponseButton createSaveNewAnswers={createSaveNewAnswers} recursiveUpdate={recursiveUpdate} lot={lot} setAnswerRequired={setAnswerRequired} updateAnswers={updateAnswers} optionsKeys={optionsKeys} saveNewAnswers={saveNewAnswers} setSaveNewAnswers={setSaveNewAnswers} sousquestion={sousquestion} indexSousQuestion={indexSousQuestion} option={option} buttonSelect={buttonSelect}  setButtonSelect={setButtonSelect} optionIndex={optionIndex} question={question} answersForBack={answersForBack} /> 
                        </div>
                    )
                    })}
                </div>
            : sousquestion.askTypes === 'select' ? 
            <ReponseSelect createSaveNewAnswers={createSaveNewAnswers} saveNewAnswers={saveNewAnswers} options={options} recursiveUpdate={recursiveUpdate} setAnswerRequired={setAnswerRequired} updateAnswers={updateAnswers} optionsKeys={optionsKeys} sousquestion={sousquestion} setSaveNewAnswers={setSaveNewAnswers} question={question} answersForBack={answersForBack} />
            : sousquestion.askTypes === 'slide' ?
                <ReponseSlide createSaveNewAnswers={createSaveNewAnswers} recursiveUpdate={recursiveUpdate} lot={lot} setAnswerRequired={setAnswerRequired} updateAnswers={updateAnswers} sousquestion={sousquestion} setSaveNewAnswers={setSaveNewAnswers} saveNewAnswers={saveNewAnswers} answers={answers} question={question} /> 
            : sousquestion.askTypes === 'textarea' ?
                <ReponseTextarea lot={lot} createSaveNewAnswers={createSaveNewAnswers} answersForBack={answersForBack} recursiveUpdate={recursiveUpdate} setAnswerRequired={setAnswerRequired} updateAnswers={updateAnswers} sousquestion={sousquestion} setSaveNewAnswers={setSaveNewAnswers} question={question} saveNewAnswers={saveNewAnswers}  />
            : sousquestion.askTypes === 'multiple-choice' ?
                <MultipleChoice createSaveNewAnswers={createSaveNewAnswers} setAnswersForBack={setAnswersForBack} answersForBack={answersForBack} recursiveUpdate={recursiveUpdate} setAnswerRequired={setAnswerRequired} updateAnswers={updateAnswers} sousquestion={sousquestion} 
                setSaveNewAnswers={setSaveNewAnswers} question={question} saveNewAnswers={saveNewAnswers} answers={answers} />
            : null 
            }
            {sousquestion.acceptFiles ?
            <form method="post" enctype="multipart/form-data" className='file'>
                <label><img src={uploadIco} className='icone'/>
                    <input type='file' id='file_upload' name='file_upload' style={{ display: 'none' }} onChange={(event) => {sendToAnswersForBack(event); dispatch(setModif(true))}} accept= '.jpg, .jpeg, .png, .pdf, .bmp'/> 
                </label>
                <div className='file__name'>{docForBack && docForBack[sousquestion.id] && docForBack[sousquestion.id].doc.name ? docForBack[sousquestion.id].doc.name : null} </div>
            </form>
            
            : null}
        </div>
        </div>
    )
}
                
 const mapStateToProps = (state) => ({
    languageSelected: state.languageSelected,
    questions: state.questions,
    answers: state.answers,
    modif: state.modif,
    idMenu: state.idMenu });
  const mapDispatchToProps = dispatch => ({
  });
  


export default connect(mapStateToProps, mapDispatchToProps)(Question);