import React, { useRef, useEffect, useState} from 'react';
import * as echarts from 'echarts';
 
const RadarMobile = ({rapport, slidesValues, onCanvasDataChange}) => {
  const chartRef = useRef(null);
  let indicators = []
  const [serieTable, setSerieTable] = useState([])
  let valuesNull = []
  const [colors, setColors] = useState([])
  const [firstLoad, setFirstLoad] = useState(true)

  const indic = () => {
    if(rapport && rapport.indicator && !indicators[0]){
      rapport.indicator.forEach(indic => {
        indicators.push({
          text: indic.nom,
          max: indic.max,
        })
        valuesNull.push(0)
      });
    }
      const updatedSerieTable = []
      const updatedColors = []

      if(rapport && rapport.series && !serieTable[0]){
        rapport.series.forEach((serie) => {
          
          const dataItems = serie.data.map((d) => ({
            
            value: d.value[0] ? d.value : valuesNull,
            name: d.name,
            areaStyle: `rgba(${d.areaStyle},1)`,
            editable: d.editable ? d.editable : false,
          }))
          serie.data.map((d) => {
            updatedColors.push(`rgba(${d.areaStyle},0.8)`)
          })
          
          updatedSerieTable.push({
            name: serie.name,
            type: serie.type,
            data: dataItems,
          })
        })
      setSerieTable(updatedSerieTable)
      setColors(updatedColors)
      }
     setFirstLoad(true)
  }

  useEffect(() => {
    indic()
    
    if(slidesValues && slidesValues[0]){
      const objetEditable = serieTable[0].data.filter(edit => edit.editable)
      if (objetEditable.length > 0) {
        Object.entries(slidesValues).map((slide) => {
          objetEditable[0].value[slide[0]] = slide[1].value
        })
      }
  }
    if(serieTable[0]){
      const chartInstance = echarts.init(chartRef.current);
    const options = {
      animation: false,
      color: colors,
      title: { text: rapport && rapport.indicator && rapport.indicator.categorys ? rapport.indicator.categorys : null},
      legend: {},
      radar: {
        indicator: indicators,
        center: ['50%', '50%'],
        radius: '50%',
        axisLine: {
          lineStyle: {
            color: 'rgba(211, 253, 250, 0.8)'
          }
        },
        splitArea: {
          areaStyle: {
            color: ['rgba(88,88,90,0.5)', 'rgba(135,136,138,0.5)', 'rgba(88,88,90,0.5)', 'rgba(135,136,138,0.5)']
          }
        },
        name: {
          textStyle: {
            color: 'rgb(0,174,217)'
          }
        }
      },
      series: {
        name: serieTable[0].name,
        type: serieTable[0].type,
        data: serieTable[0].data,
      },
    };

    chartInstance.setOption(options);
    const canvasDataUrl = chartInstance?.getDataURL()
    onCanvasDataChange(canvasDataUrl)

    return () => {
      chartInstance.dispose();
    };
  } 
  
  }, [serieTable, slidesValues]);


  return <div ref={chartRef} style={{ width: '700px', height: '700px' }} />
};

export default RadarMobile;