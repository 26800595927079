const ConfirmationModal = ( {text, sendChoicesToBack}) => {
    return (
        <div className='modalPin__background'>
            <div className='modalPin__container'>
                {text}
                <div className='mobilePin__button' onClick={() => {sendChoicesToBack(true)}}>
                    Valider
                </div>
            </div>
        </div>
    )
}

export default ConfirmationModal