import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { setIdUser, setIdQuestionnaire, setIdQuestion, setIdMenu, setIdPage, setIndexLot, setToken,setIdChapitre, setIdCorrespond} from '../../actions/action.js';
import { useDispatch, connect } from 'react-redux';
import { useEffect } from 'react';
import Cookies from 'js-cookie';

const AppWrapper = ({idCorrespond, allQuestions, getQuestions}) => {
  const { idQuestionnaire, idUser, menuId, idPage, indexLot, idQuestion, idChapitre } = useParams();
  const dispatch = useDispatch()
  const isStringParam = isNaN(idQuestionnaire);
  const userSessionToken = Cookies.get('localToken')
  const currentPath = window.location.pathname;
  const indexOfTerm = currentPath.indexOf('/rapport/')

  useEffect(() => {
    dispatch(setIdUser(idUser))
      axios.get(`${process.env.REACT_APP_BACK_URL}/auth/get_token`, 
      {headers: {
        appname: process.env.REACT_APP_NAME,
        apppass: process.env.REACT_APP_PASS,
      },})
      .then ((response) => {
        Cookies.set('token', response.data.token);
        axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get_survey_links`, {
          headers: {
            Authorization: `Bearer ${response.data.token}`,
          }})
        .then((responseId) => {
          dispatch(setIdCorrespond(responseId.data))
          if(isStringParam){
        const findIdQuestionnaire = responseId.data.find((obj => obj.text === idQuestionnaire || obj.id === idQuestionnaire))
        console.log(idQuestionnaire)
        dispatch(setIdQuestionnaire(findIdQuestionnaire.id))
      } else {
          dispatch(setIdQuestionnaire(idQuestionnaire))
        }

        if(userSessionToken){
          if(!allQuestions || (allQuestions && !allQuestions.questions) && idQuestionnaire){
            if(isStringParam){
              const findIdQuestionnaire = responseId.data.find((obj => obj.text === idQuestionnaire))
              getQuestions(findIdQuestionnaire.id, userSessionToken)
            } else {
              getQuestions(idQuestionnaire, userSessionToken)
            }
          }
        } else if (indexOfTerm === -1) {
          // navigate(`/${idQuestionnaire}`)
        }

        })
        })
      .catch((error) => {console.log(error)})
      .finally(() => {
        dispatch(setToken(true))
      })
      
      
},[])

  useEffect(() => {
    dispatch(setIdQuestion(idQuestion))
    dispatch(setIdMenu(menuId))
    dispatch(setIdPage(idPage))
    dispatch(setIndexLot(indexLot))
    dispatch(setIdUser(idUser))
    dispatch(setIdChapitre(idChapitre))
  }, [idQuestion, idUser])
}
const mapStateToProps = (state) => ({
  idCorrespond: state.idCorrespond,
  allQuestions: state.allQuestions,
  languageDefault: state.languageDefault,
  languageSelected: state.languageSelected,
});
const mapDispatchToProps = {
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);