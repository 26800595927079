import { useNavigate, useParams } from "react-router-dom"
import { connect } from "react-redux"
import { useState } from "react"
import axios from "axios"
import Cookies from "js-cookie"

const AlertModalMobile = ( {data, idUser, setDisplayChangeAnswers, createNotification} ) => {
    const navigate = useNavigate()
    const [displayAlert, setDisplayAlert] = useState(false)
    const { idQuestionnaire } = useParams()
    const token = Cookies.get('token')

    const validChange = () => {
        axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/unlock_survey/${idQuestionnaire}/${idUser}`, {forceUnlock: true}, {
            headers: {
                Authorization: `Bearer ${token}`,
              },
        })
        .then((response) => {
            setDisplayChangeAnswers(false)
            setDisplayAlert(false)
            createNotification('success', '', 'Vous pouvez à nouveau modifier vos réponses', 'Veuillez vous reconnecter')
        })}
        
    return (
        <div className="modalPin__background">
            {!displayAlert ? <div className='modalPin__container'>
                <div className='modalPin__title'>
                    {data.titleIntro}
                </div>
                <div className='modalPin__text'>
                    {data.textIntro}
                </div>
                <div>
                    <div className='mobilePin__button' onClick={() => {setDisplayAlert(true)}}>
                        {data.buttonOk}
                    </div>
                    <div className='mobilePin__button' onClick={() => setDisplayChangeAnswers(false)}>
                        {data.buttonKo}
                    </div>
                    <div className='mobilePin__button' onClick={() => {navigate(`${process.env.REACT_APP_FRONT_LINK}/rapport/${idQuestionnaire}/${idUser}`)}}>
                        {data.buttonAccessReport}
                    </div>
                </div>
            </div> : null }
            {displayAlert ? 
                <div className='modalPin__container'>
                    <div className="modalPin__title">
                        {data.modal.title}
                    </div>
                    <div className="modalPin__text">
                        {data.modal.text}
                    </div>
                    <div>
                    <div className='mobilePin__button' onClick={() => {validChange()}}>
                        {data.buttonOk}
                    </div>
                    <div className='mobilePin__button' onClick={() => {setDisplayChangeAnswers(false); setDisplayAlert(false)}}>
                        {data.buttonKo}
                    </div>
                </div>

                </div> : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    idUser: state.idUser,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertModalMobile);