import React from 'react';
import { Remarkable } from 'remarkable';

const TextComponent = ({ text }) => {
  const md = new Remarkable('full', { 'linkTarget': '_blank' });

  const lines = text.split('\n');

  const renderMarkdown = (text) => {
    return { __html: md.render(text) };
  };

  return (
    <div>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          <div dangerouslySetInnerHTML={renderMarkdown(line)} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default TextComponent;
