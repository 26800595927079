import { connect, useDispatch } from "react-redux";
import { useEffect } from "react";
import { setModif } from "../../../actions/action";

const ReponseButton = ({optionsKeys, sousquestion,option, optionIndex, setSaveNewAnswers, answersForBack, saveNewAnswers, question, updateAnswers, setAnswerRequired, lot, recursiveUpdate}) => {
    const dispatch = useDispatch();
    let questionValue = null;
    if (option.value) {
       questionValue = option.value
    }

    const updateSaveNewAnswers = () => {
        setSaveNewAnswers(prevState => {
          if(!prevState[question.category.id]){
            prevState[question.category.id] = {}
          }
          if (!prevState[question.category.id][sousquestion.id]) {
            prevState[question.category.id][sousquestion.id] = {};
          }
            const updatedQuestion = { ...prevState[question.category.id][sousquestion.id],
              selectedAnswer: optionsKeys[optionIndex],
              answerValue: questionValue,
              textAnswer: option.text,
              textRapport: option.rapportResponse == '' ? null : option.rapportResponse,
            }
            const updateCategory = {...prevState[question.category.id], [sousquestion.id]: updatedQuestion}
            const updatedState = {...prevState, [question.category.id]: updateCategory}
            return updatedState;
          });

      updateAnswers(optionsKeys[optionIndex], questionValue, option.text)
    }
  
    const changeButtonSelect = () => {
        updateSaveNewAnswers()
        updateAnswers(optionsKeys[optionIndex], option.value, option.text)
        setAnswerRequired(true)
        dispatch(setModif(true))
        recursiveUpdate(sousquestion)
    }
    
    return (
        <button key={optionIndex} className={answersForBack && answersForBack[question.category.id] && answersForBack[question.category.id][sousquestion.id] && answersForBack[question.category.id][sousquestion.id].response && answersForBack[question.category.id][sousquestion.id].response == optionsKeys[optionIndex] ? 'questions__card__button other__choice' : 'questions__card__button'} onClick={() => {changeButtonSelect()}}>{option.text}</button>
    )
}

const mapStateToProps = (state) => ({
    answers: state.answers,
    idUser: state.idUser,
     });
  const mapDispatchToProps = dispatch => ({
  });
  


export default connect(mapStateToProps, mapDispatchToProps)(ReponseButton); 