import './footer.scss'
import LogoUranie from '../../assets/img/valliance/logo_uranie_refresh.png'

const FooterMobile = () => {
    return (
        <div className='footer__mobile'>
            <p className='footer__mobile__text'>Conçu et <br />réalisé par </p>
            <img className='footer__mobile__uranie' src={LogoUranie} />
            <p className='footer__mobile__text'>Groupe <br/>Exco Valliance</p>
        </div>
    )
}

export default FooterMobile